import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UpdateBranchContent from "../../components/update-branch-content/update-branch-content.component";
import SettingsHead from "../../components/settings-head/settings-head.component";
import { CommonService } from "../../services/common/common.service";
import AddBranchContent from "../../components/add-branch-content/add-branch-content.component";
import { SettingsService } from "../../services/settings/settings.service";
import { GlobalContext } from "../../store";

export default function BranchManagementPage() {
    const [t] = useTranslation();
    const commonService: CommonService = new CommonService();
    const settingsService: SettingsService = new SettingsService();
    commonService.setTitle(t("branch management"));

    const { setBranches } = useContext(GlobalContext);

    const [branchesUpdated, setBranchesUpdated] = useState(false);

    const setBranchAdded = () => {
        setBranchesUpdated(true);
        setTimeout(() => {
            setBranchesUpdated(false);
        }, 100);
    }

    async function getBranches() {
        const response = await settingsService.listAllBranches();
        setBranches(response);
    }

    useEffect(() => {
        getBranches();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [branchesUpdated]);


    return (
        <div className="role-contain">
            <div className="content">
                <SettingsHead title={t('branch management')} subTitle={t('manage your departments or locations here')} />
                <AddBranchContent
                    onBranchesChange={() => setBranchAdded()} />
                <UpdateBranchContent />
            </div>
        </div>
    );
}