import React from "react";
import "./notification-component.scoped.scss";
import { history } from "react-router-guard";
import { formatDate } from "../../helpers";
import { NotificationsService } from "../../services/notifications/notifications.service";

const NotificationComponent = ({ notification }: any) => {
  const { isOpened, createdAt, id, iconSrc, text, tab, caseId } = notification;
  const notificationsService: NotificationsService = new NotificationsService();

  const onNotificationClick = async () => {
    if (isOpened === 0) {
      await notificationsService.makeNotificationOpened(id);
    }
    const routeName = `/case/${caseId}`;
    history.replace("/temp");
    setTimeout(() => {
      history.replace(routeName, {
        tab,
      });
    });
  };
  return (
    <div
      // style={{
      //   backgroundColor: isOpened
      //     ? "rgb(255, 255, 255)"
      //     : "rgba(242, 175, 29, 0.2)",
      // }}
      className={`notification-item px-3 ${
        isOpened ? "item-opened" : "item-not-opened"
      }`}
      onClick={onNotificationClick}
    >
      <div className="notification-icon">
        <img src={iconSrc} alt="notification-icon" />
      </div>
      <div className="notification-text">
        <div className="notification-title">{text}</div>
        <div className="notification-date">
          {formatDate(createdAt, "dddd, DD.MM.YYYY, HH:mm")}
        </div>
      </div>
    </div>
  );
};

export default NotificationComponent;
