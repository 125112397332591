export class LocalStorageService {
  public getData(key: string) {
    const data = localStorage.getItem('paladin-' + key);
    return data ? JSON.parse(data) : '';
  }
  public setData(key: string, data: any) {
    localStorage.setItem('paladin-' + key, JSON.stringify(data));
  }
  public removeData(key: string) {
    localStorage.removeItem('paladin-' + key);
  }
  public clearAll() {
    localStorage.clear();
  }
}
