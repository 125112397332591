export const dummyQuestions = [
  {
    status: "EINGELANGT",
    questions:
      [
        {
          question: "",
        },
        {
          question: "",
        },
        {
          question: "",
        },
      ]
  },
  {
    status: "IN BEARBEITUNG",
    questions:
    [
      {
        question: "",
      },
      {
        question: "",
      },
      {
        question: "",
      },
    ]
  },
  {
    status: "REVIEW",
    questions:
    [
      {
        question: "",
      },
      {
        question: "",
      },
      {
        question: "",
      },
    ]
  },
  {
    status: "ABGESCHLOSSEN",
    questions: []
  },
];