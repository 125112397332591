import React from "react";
import WorkflowTableRow from "../workflow-table-row/workflow-table-row.component";
import "./wokflow-table.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { Question } from "../../types/question";
import { Skeleton } from "@material-ui/lab";

const WorkflowTable = (props: any) => {
  const [t] = useTranslation();
  return (
    <div className="workflow-table-card">
      {/* Table Columns */}
      <div className="workflow-table-headlines">
        <div className="d-flex align-items-center justify-content-between p-3">
          <div className="task-section">
            {props.loading ? (
              <Skeleton variant="text" height={20} />
            ) : (
              t("task")
            )}
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="frist-section">
              {props.loading ? (
                <Skeleton variant="text" height={20} />
              ) : (
                t("dueDate")
              )}
            </div>
            <div className="yes-no-section">
              {props.loading ? (
                <Skeleton variant="text" height={20} />
              ) : (
                t("Yes/No/(N/A)")
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Table Rows */}
      <div className="questions">
        {props.questions.map((question: Question, index: any) => {
          return (
            <WorkflowTableRow
              key={index}
              id={question.id}
              index={index + 1}
              question={question.question}
              dueDate={question.dueDate}
              answer={question.answer}
              comments={question.comments}
              loading={props.loading}
              caseQuestionId={props.caseQuestionId}
            />
          );
        })}
      </div>
    </div>

  );
};

export default WorkflowTable;
