import "./edit-brief.scoped.scss";
import React, { useState, useContext, useRef } from "react";
import { useTranslation, Trans } from "react-i18next";
import RichTextEditor from "react-rte";
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
import { CasesService } from "../../services/cases/cases.service";
import { CaseContext, GlobalContext } from "../../store";
import CheckIcon from "@material-ui/icons/Check";
import { toolbarConfig } from "../../statics/rich-text-config";
import { sanitize, replaceTags } from "../../helpers";
import CircularProgress from "@material-ui/core/CircularProgress";

export const EditBriefDialog = (props: any) => {
  const maxChar: number = 250;
  const casesService = new CasesService();
  const { caseDetails, setCase } = useContext(CaseContext);
  const { user } = useContext(GlobalContext);
  const [briefDesc, setBriefDesc] = useState(
    RichTextEditor.createValueFromString(caseDetails.brief, "html")
  );
  const [charCount, setCharCount] = useState(
    replaceTags(briefDesc.toString("html")).length
  );
  const [briefError, setBriefError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const DescRichTextEl = useRef(null);
  const [t] = useTranslation();

  const onBriefChange = (value: any) => {
    setFormChanged(true);
    setBriefDesc(value);
    const count = replaceTags(value.toString("html")).length;
    setCharCount(count);
    count > maxChar ? setBriefError(true) : setBriefError(false);
  };

  const handleClose = (value: any) => {
    props.onClose(value);
    // reset initial state if not saved.
    if (!value) {
      const briefHtml = RichTextEditor.createValueFromString(
        caseDetails.brief,
        "html"
      );
      setBriefDesc(briefHtml);
      setCharCount(replaceTags(briefHtml.toString("html")).length);
      setBriefError(false);
    }
  };

  const handleUpdate = async () => {
    setSaving(true);
    const brief = briefDesc.toString("html");
    await casesService.updateBrief(caseDetails.id, brief);
    setSaving(false);
    const briefUpdatedAt = new Date();
    const briefUpdateAdmin = user.name;
    setCase({ ...caseDetails, brief, briefUpdateAdmin, briefUpdatedAt });
    handleClose(brief);
  };

  const handleRichTextFocus = (event: any) => {
    const element: any = DescRichTextEl.current;
    element.classList.add("active");
  };
  const handleRichTextBlur = (event: any) => {
    const element: any = DescRichTextEl.current;
    element.classList.remove("active");
  };

  return (
    <Modal
      show={props.show}
      onHide={handleClose.bind(null, false)}
      centered
      dialogClassName="modal-xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans
            i18nKey="edit_brief_desc_of_case"
            values={{ internalId: caseDetails.internalId }}
          ></Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-6">
            <div className="input-label">
              {t("original input")}
            </div>
            <div className="input-original">
              <div className="input-placeholder">
                {t("Original note description")}
              </div>
              <div
                className="input-body"
                dangerouslySetInnerHTML={sanitize(
                  caseDetails.originalDescription
                )}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="input-label">{t("brief description")}</div>
            <div className="input-editable" ref={DescRichTextEl}>
              <div className="input-placeholder">{t("brief description")}</div>
              <div
                className="input-body"
                onFocus={handleRichTextFocus}
                onBlur={handleRichTextBlur}
              >
                <RichTextEditor
                  toolbarConfig={toolbarConfig}
                  value={briefDesc}
                  onChange={(value) => onBriefChange(value)}
                />
              </div>
            </div>
            <div
              className={"input-char-count " + (briefError ? "invalid" : "")}
            >
              {charCount}/250 {t("character")}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outlined"
          color="primary"
          className="btn-secondary-theme"
          onClick={handleClose.bind(null, false)}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="btn-primary-theme"
          onClick={handleUpdate}
          disabled={briefError || !formChanged}
        >
          {saving ? (
            <CircularProgress color="inherit" />
          ) : (
            <>
              <CheckIcon /> {t("save")}{" "}
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
