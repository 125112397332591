import React, { useContext, useState } from "react"
import "./communication-timeline.component.scoped.scss"
import { useTranslation } from "react-i18next"
import { TimelineStatusesCenteredComponent } from "../timeline-statuses-centered/timeline-statuses-centered.component"
import { TimelineCommentComponent } from "../timeline-comment/timeline-comment.component"
import { CaseContext, GlobalContext } from "../../store"
import { CaseNotes } from "../../types/case-notes"
import { Comment } from "../../types/comment"
import { icons } from "../../statics/icons"

export const CommunicationTimelineComponent = (props: any) => {
  const {
    notesLoading,
    setNotesLoading,
    caseNotes,
    caseDetails,
    caseStatuses,
    completedStatusId,
  } = useContext(CaseContext)
  const { user } = useContext(GlobalContext)
  const [t] = useTranslation()
  const [timelineStatuses, setTimelineStatuses] = useState(caseStatuses)
  const maxComments = 5

  const isActiveStatus = (status: string): boolean => {
    return caseDetails.status === status
  }

  const statusPosition = (status: string): string => {
    return isActiveStatus(status) ? "right" : "left"
  }

  const expandStatusComments = (expand: boolean, index: number): void => {
    const timelineStatusesCopy = [...timelineStatuses]
    timelineStatusesCopy[index].expand = expand
    setTimelineStatuses(timelineStatusesCopy)
  }

  const showAllComments = (showAll: boolean, index: number): void => {
    setNotesLoading(true)
    const timelineStatusesCopy = [...timelineStatuses]
    timelineStatusesCopy[index].showAll = showAll
    setTimelineStatuses(timelineStatusesCopy)
    setTimeout(() => {
      setNotesLoading(false)
    }, 300)
  }

  return (
    <div className="timeline">
      <div className="timeline-items">
        {caseNotes.map((caseNote: CaseNotes, index: any) => {
          const notes: any[] =
            !timelineStatuses[index]?.expand && !notesLoading
              ? []
              : timelineStatuses[index]?.showAll
                ? caseNote.comments
                : caseNote.comments.slice(
                  Math.max(caseNote.comments.length - maxComments, 0)
                )
          return (
            <>
              <TimelineStatusesCenteredComponent
                key={index}
                position={statusPosition(caseNote.status)}
                activeStatus={isActiveStatus(caseNote.status)}
                icon={timelineStatuses[index]?.icon}
                status={caseNote.status}
                lastStatus={index === timelineStatuses.length - 1}
                total={caseNote.total}
                statusCount={`(${caseNote.total} ${caseNote.total === 1 ? t("note") : t("notes")
                  })`}
                date={caseNote.date}
                loading={notesLoading}
                expand={
                  timelineStatuses[index]?.expand &&
                  caseNote.comments.length > 0
                }
                handleExpand={(value: boolean) =>
                  expandStatusComments(value, index)
                }
                showAll={timelineStatuses[index]?.showAll}
                handleShowAll={(value: boolean) =>
                  showAllComments(value, index)
                }
                maxComments={maxComments}
                dimmed={timelineStatuses[index]?.dimmed}
              />
              {notes.map((comment: Comment, commentIndex: any) => {
                return (
                  <TimelineCommentComponent
                    key={comment.id}
                    id={comment.id}
                    files={comment.files || []}
                    position={
                      comment.userGroup === "external" ? "left" : "right"}
                    icon={icons.userImgLight}
                    comment={comment.content}
                    date={comment.createdAt}
                    user={comment.user}
                    loading={notesLoading}
                    isAuthorizedUser={
                      comment.user &&
                      (user.keycloakUserId === caseDetails.mainProcessorId ||
                        user.keycloakUserId === comment.userId ||
                        user.type === "superAdmin" || user.role === 'coordinator')
                    }
                    isCompletedCase={caseDetails.statusId === completedStatusId}
                    tab="communication"
                  />
                )
              })}
            </>
          )
        })}
      </div>
    </div>
  )
}
