import { emailRegex } from "../../statics/validators";

export default (state: any, action: any) => {
  const { input, value, loginError } = action.payload;
  switch (action.type) {
    case "VALIDATE":
      let errors = { ...state.errors };
      if (input === "email") {
        const valid = emailRegex.test(value);
        if (!valid) {
          errors = { ...errors, email: "invalid-email" };
        } else {
          delete errors.email;
        }
      }
      if(!value) {
        errors = { ...errors, [input]: "field-required" };
      } else {
        if(errors[input] === 'field-required') {
          delete errors[input];
        }
      }
      return {
        ...state,
        [input]: value,
        errors,
      };
    case "SET_INPUT":
      return {
        ...state,
        [input]: value,
      };
    case "SET_LOGIN_ERROR":
      return {
        ...state,
        loginError,
      };
    default:
      return state;
  }
};
