import { Button, Checkbox, FormControlLabel, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Visibility, VisibilityOff, FileCopyOutlined, CheckCircle, ReportProblemOutlined } from "@material-ui/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { showToastMsg, turncate } from "../../helpers";
import { SettingsService } from '../../services/settings/settings.service'
import { TenantForm } from "../../types/tenant";
import ContactInfo from "../contact-info/contact-info.component";
import { InputViewComponent } from "../input-view/input-view.component";
import { passwordRegex, specialChar, capitalChar, digitChar, stringRegex } from '../../statics/validators'
import './workspace-management.component.scoped.scss';
import { config } from "../../config";
import CompanyInfo from "../company-info/company-info.component";
import { FileSizes } from "../../statics/file-sizes";
import { fileTypes } from "../../statics/file-types";
import { WorkspacesService } from "../../services/workspaces/workspaces.service";
import withReactContent from "sweetalert2-react-content";
import { alertToastConfig } from "../../statics/alert-config";
import Swal from "sweetalert2";
import { GlobalContext } from "../../store";
import { UserService } from "../../services/user/user.service";
import { MapperService } from "../../services/mapper/mapper.service";
import { LocalStorageService } from "../../services/local-storage/local-storage";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tooltip: {
            backgroundColor: "#2a3638",
            fontSize: "13px",
        },
        arrow: {
            color: "#2a3638",
        },
    })
);

export default function WorkspaceManagement() {
    const [t] = useTranslation();
    const settingsService: SettingsService = new SettingsService();
    const workspaceService: WorkspacesService = new WorkspacesService();
    const userService: UserService = new UserService();
    const mapperService: MapperService = new MapperService();
    const localStorageService = new LocalStorageService();
    const classes = useStyles();
    const { workspaceImage, setWorkspaceImage, setUserTenants } = useContext(GlobalContext);
    const emptyArr: any[] = [];

    const [workspaceForm, setWorkspaceForm] = useState(new TenantForm());
    const [languages, setLanguages] = useState(emptyArr);
    const [updatedVisibility, setUpdatedVisibility] = useState(false);
    const [languagesError, setLanguagesError] = useState(false);

    const [formChanged, setFormChanged] = useState(false);
    const [nameError, setNameError] = useState(false);
    const inputFileRef = useRef(null);

    const [password, setPassword] = useState('');
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [saving, setSaving] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const [capsLock, setCapsLock] = useState(false);
    const [copySuccess, setCopySuccess] = useState(false);
    const [textCopy, setTextCopy] = useState(t('Copy URL'));

    useEffect(() => {
        getWorkspace();
        getPassword();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setLanguages(languages);
        setUpdatedVisibility(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedVisibility]);

    const getPassword = async () => {
        const defaultPassword = await settingsService.getDefaultPassword();
        setPassword(defaultPassword.value || "");
    }

    const changePassword = async () => {
        setPasswordChanged(false)
        setSaving(true);
        const res = await settingsService.setDefaultPassword(password);
        setSaving(false);
        if (res && res.msg === "updated") {
            showToastMsg("success", t("standard password updated successfully"));
        }
    };

    const getWorkspace = async () => {
        const workspaceInfo = await settingsService.getWorkspaceInfo();
        setLanguages(workspaceInfo.languages);
        setWorkspaceForm(workspaceInfo.tenant);
    }

    const clickUpload = () => {
        const element: any = inputFileRef.current;
        element.click();
    };

    const onFileChange = async (e: any) => {
        await validateFiles(e.target.files);
        const element: any = inputFileRef.current;
        element.value = '';
    };

    const validateFiles = async (files: any[]) => {
        const errorsMsg: any[] = [];
        for (const file of files) {
            const { fileType, maxSize } = getFileInfo(file.type);
            const fileName = file.name.split('.' + fileType)[0];
            if (file.size > maxSize) {
                errorsMsg.push(
                    `<div class='alert-file-name'>${turncate(fileName, 20)}</div> 
                    is bigger than max ${fileType.toUpperCase()} file size (${maxSize / 1024 / 1000} MB)`
                );
            } else {
                if (fileType.includes('jpg') || fileType.includes('png')) {
                    setFormChanged(true);
                    file.src = await getImagePreview(file);
                    setWorkspaceImage(file);
                } else {
                    errorsMsg.push('Only jpg/jpeg/png file types are supported');
                }
            }
        }
        showAlertMsg(errorsMsg);
    };

    async function getImagePreview(file: File) {
        return new Promise<any>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                const src = reader.result;
                resolve(src);
            };
            reader.readAsDataURL(file);
        });
    }

    const getFileInfo = (type: string): any => {
        switch (type) {
            case 'image/jpeg':
                return { fileType: 'jpg', maxSize: FileSizes.jpg.maxSize };
            case 'image/png':
                return { fileType: 'png', maxSize: FileSizes.png.maxSize };
            case 'video/mp4':
                return { fileType: 'mp4', maxSize: FileSizes.png.maxSize };
            case 'application/pdf':
                return { fileType: 'pdf', maxSize: FileSizes.pdf.maxSize };
            default:
                break;
        }
    };

    async function showAlertMsg(queues: any[]) {
        const MySwal = withReactContent(Swal);
        for (const message of queues) {
            await MySwal.fire({
                icon: 'error',
                title: message,
                ...alertToastConfig,
            });
        }
    }

    const submit = async () => {
        setFormChanged(false);
        setLanguagesError(false);
        let data = { logoUrl: workspaceForm.logoUrl, name: workspaceForm.name };
        if (workspaceImage.src) {
            const workspacePicForm = new FormData();
            workspacePicForm.append('image', workspaceImage, workspaceImage.name);
            workspacePicForm.append('clientName', workspaceForm.configurationName);
            workspacePicForm.append('type', 'logo');
            const workspaceImageRes = await workspaceService.uploadImage(workspacePicForm);
            if (workspaceImageRes.url) {
                data.logoUrl = workspaceImageRes.url;
            } else {
                showToastMsg('error', 'Something went wrong');
                return;
            }
        }
        const resLanguages = await workspaceService.updateLanguages(languages);
        const resWorkdpace = await settingsService.updateWorkspaceInfo(data);
        if (resWorkdpace.message === 'success' && resLanguages === 'success') {
            const tenants = await userService.getUserTenants();
            const mappedTenants = mapperService.mapTenants(tenants);
            setUserTenants(mappedTenants);
            setWorkspaceForm({ ...workspaceForm, name: data.name, logoUrl: data.logoUrl });
            localStorageService.setData('tenantName', data.name);
            localStorageService.setData('tenantIcon', data.logoUrl);
            setWorkspaceImage({});
            showToastMsg('success', t('workspace information updated successfully'));
        } else {
            showToastMsg('error', 'something went wrong')
        }
    }

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const onKeyDown = (e: any) => {
        if (e.getModifierState("CapsLock")) {
            setCapsLock(true);
        } else {
            setCapsLock(false);
        }
    };

    const copyToClipBoard = async (copyMe: any) => {
        try {
            await navigator.clipboard.writeText(copyMe);
            setCopySuccess(true);
            setTextCopy(t('URL Copped'));
            setTimeout(() => {
                setCopySuccess(false);
                setTextCopy(t('Copy URL'));
            }, 2000);
        } catch (err) {
            setCopySuccess(false);
        }
    };

    const onChangeLanguage = async (index: number) => {
        setFormChanged(true);
        const updatedLanguages = languages;
        updatedLanguages[index].visibility = !updatedLanguages[index].visibility;
        setLanguages(updatedLanguages);
        setUpdatedVisibility(true);
        const checkVisibility = languages.find(language => language.visibility);
        if (checkVisibility) {
            setLanguagesError(false);
        } else {
            setLanguagesError(true);
        }
    };

    return (
        <>
            <section className="workspace-management">
                <div className="workspace-content">
                    <div className="section-info">
                        <h3>{t("workspace specifications")}</h3>
                        <p>{t("Workspace specifications desc")}</p>
                    </div>
                    <div className="workspace-info">
                        <div className="info">
                            <div className='logo'>
                                <label>{t('logo')}</label>
                                <div className="logo-update">
                                    <img src={
                                        workspaceImage.src ? workspaceImage.src : workspaceForm.logoUrl ? workspaceForm.logoUrl : '/icons/onboarding/cameraIcon.svg'}
                                        alt='camera'
                                        className={workspaceForm.logoUrl ? 'image-full' : 'camera-icon'}
                                        onClick={clickUpload} />
                                    <input
                                        type='file'
                                        ref={inputFileRef}
                                        onChange={onFileChange}
                                        style={{ display: 'none' }}
                                        multiple
                                        accept={fileTypes}
                                    />

                                </div>
                            </div>
                            <div className="name">
                                <label>{t('workspace name')}</label>
                                <TextField
                                    label={t('workspace name')}
                                    variant='filled'
                                    name='workspace'
                                    value={workspaceForm.name}
                                    error={nameError}
                                    onChange={(e) => {
                                        setFormChanged(true);
                                        e.target.value ? setNameError(!stringRegex.test(e.target.value)) : setNameError(true);
                                        setWorkspaceForm({ ...workspaceForm, name: e.target.value });
                                    }}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div className="configurationName">
                                <label>{t('configuration name')}</label>
                                <TextField
                                    label={t('configuration name')}
                                    variant='filled'
                                    name='configurationName'
                                    value={workspaceForm.configurationName}
                                    disabled
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </div>

                        <div className="reporter-url-content">
                            <label>{t('reporter url')}</label>
                            <div className='reporter-url'>
                                <InputViewComponent
                                    value=''
                                    label='https://'
                                    color='white'
                                />
                                <TextField
                                    style={{ width: '100%' }}
                                    label={t('Personal Part of URL')}
                                    variant='filled'
                                    name='reporterUrl'
                                    disabled={true}
                                    value={workspaceForm.reporterUrl}
                                />
                                <InputViewComponent
                                    value=''
                                    label={`-${config.subDomain}.${config.domain}`}
                                    color='white'
                                />
                                <Tooltip
                                    title={textCopy}
                                    classes={classes}
                                    placement="right"
                                    background-color="primary"
                                    arrow>
                                    {copySuccess ? <CheckCircle style={{ color: 'green' }} /> :
                                        <FileCopyOutlined style={{ cursor: 'pointer', color: '#5A5C4F' }}
                                            onClick={() => copyToClipBoard(`https://${workspaceForm.reporterUrl}-${config.subDomain}.${config.domain}`)} />}
                                </Tooltip>
                            </div>
                            {!workspaceForm.has_url && <div className="error-hint ">
                                <ReportProblemOutlined style={{ color: '#f2af1d' }} />
                                <div className="error-msg">
                                    <h5>{t('NOTICE')}</h5>
                                    <p>{t('error message text')}</p>
                                </div>
                            </div>}
                        </div>

                        <div className="language">
                            <label> {t('languages')}</label>
                            <div className='d-flex flex-column mb-4'>
                                {languages.map((language: any, index: number) => {
                                    return (
                                        <>
                                            <FormControlLabel
                                                style={{ marginBottom: '0px' }}
                                                control={
                                                    <Checkbox
                                                        checked={language.visibility}
                                                        onChange={(e) => { onChangeLanguage(index) }}
                                                        size="small"
                                                        name={language.name} />
                                                }
                                                label={language.name === 'de' ? 'German' : 'English'}
                                            />
                                        </>
                                    )
                                })
                                }
                            </div>
                        </div>
                        <div className="action">
                            <Button
                                variant="contained"
                                color="primary"
                                className="btn-primary-theme btn-save"
                                onClick={submit}
                                disabled={!formChanged || workspaceForm.name.length === 0 || nameError || languagesError}>
                                {t("update workspace")}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="standard-password">
                    <div className="section-info">
                        <h3>{t("standard passwort")}</h3>
                        <p>{t("standard passwort desc")}</p>
                    </div>
                    <div className="password-form">
                        <div className="password-content">
                            <TextField
                                className="w-100"
                                variant="filled"
                                type={passwordVisibility ? "text" : "password"}
                                label={t("default password")}
                                value={password}
                                autoComplete="off"
                                name="newPassword"
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                    e.target.value ? setPasswordError(!passwordRegex.test(e.target.value)) : setPasswordError(false)
                                }}
                                onKeyDown={onKeyDown}
                                onInput={setPasswordChanged.bind(null, true)}
                                autoFocus={false}
                                error={passwordError ? true : false}
                                inputProps={{
                                    minLength: 8,
                                }}
                                InputProps={{
                                    autoComplete: "one-time-code",
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                className="toggle-password"
                                                aria-label="toggle password visibility"
                                                onClick={setPasswordVisibility.bind(
                                                    null,
                                                    !passwordVisibility
                                                )}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {passwordVisibility ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {capsLock && <div className="error-msg capsLock">{t('caps lock is turned on')}</div>}

                            <div className="error-msg pt-1">
                                <p style={{ color: (password.length < 8 || password.length > 15) ? "" : "#aacd34" }}>
                                    {t("length 8 to 15 characters")}
                                </p>
                                <p style={{ color: specialChar.test(password) ? "#aacd34" : "" }}>
                                    {t("at least 1 special character")}
                                </p>
                                <p style={{ color: capitalChar.test(password) ? "#aacd34" : "" }}>
                                    {t("at least 1 capital letter")}
                                </p>
                                <p style={{ color: digitChar.test(password) ? "#aacd34" : "" }}>
                                    {t("at least 1 digit")}
                                </p>
                            </div>
                        </div>
                        <Button
                            variant="contained"
                            color="primary"
                            className="btn-primary-theme btn-save"
                            onClick={changePassword}
                            disabled={!passwordChanged || passwordError || saving}>
                            {t("save password")}
                        </Button>
                    </div>
                </div>
                <div className="company-info">
                    <div className="section-info">
                        <h3>{t("company details")}</h3>
                        <p>{t("company details desc")}</p>
                    </div>
                    <CompanyInfo />
                </div>
                <div className="contacts-section">
                    <div className="section-info">
                        <h3>{t("Contact us")}</h3>
                    </div>
                    <ContactInfo />
                </div>
            </section>
        </>
    )
}
