import { AuthService } from "../auth/auth.service";
import { LocalStorageService } from "../local-storage/local-storage";
import { history } from "react-router-guard";

export class GuardsService {
  private authService: AuthService = new AuthService();
  private localStorageService: LocalStorageService = new LocalStorageService();

  public isAuthorized = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = this.localStorageService.getData("token");
        const tenantId = this.localStorageService.getData("tenantId");

        if (token && tenantId) {
          return resolve(token);
        } else if (token && !tenantId) {
          history.push({
            pathname: `/workspaces`,
          });
          return resolve(token);
        } else {
          history.push({
            pathname: `/login`,
          });
          return reject();
        }
      } catch (error) {
        history.push({
          pathname: `/login`,
        });
        return reject();
      }
    });
  };

  public isNotAuthorized = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = this.localStorageService.getData("token");

        if (token) {
          history.push({
            pathname: `/workspaces`,
          });
          return reject(token);
        } else {
          return resolve(true);
        }
      } catch {
        return resolve(true);
      }
    });
  };

  public isSuperAdmin = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const user = this.localStorageService.getData("user");
        if (user && (user.type === "superAdmin")) {
          return resolve(true);
        } else {
          history.push({
            pathname: `/dashboard`,
          });
          return reject();
        }
      } catch (error) {
        history.push({
          pathname: `/dashboard`,
        });
        return reject();
      }
    });
  };

  public isSuperUser = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const user = this.localStorageService.getData("user");
        if (user && (user.type === "superAdmin" || (user.role === 'coordinator' && user.type === 'superUser'))) {
          return resolve(true);
        } else {
          history.push({
            pathname: `/dashboard`,
          });
          return reject();
        }
      } catch (error) {
        history.push({
          pathname: `/dashboard`,
        });
        return reject();
      }
    });
  };


  public isSystemAdmin = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const user = this.localStorageService.getData("user");
        if (user && (user.type === "admin" || user.type === "superAdmin" || user.role === "coordinator")) {
          return resolve(true);
        } else {
          history.push({
            pathname: `/dashboard`,
          });
          return reject();
        }
      } catch (error) {
        history.push({
          pathname: `/dashboard`,
        });
        return reject();
      }
    });
  };

  public isCaseAdmin = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const user = this.localStorageService.getData("user");
        if (user && (user.type === "user" || user.type === "superAdmin" || user.type === "superUser" || user.type === "auditor")) {
          return resolve(true);
        } else {
          history.push({
            pathname: `/users`,
          });
          return reject();
        }
      } catch (error) {
        history.push({
          pathname: `/users`,
        });
        return reject();
      }
    });
  };
}
