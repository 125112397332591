import { TableColumn } from "../types/table-column";

export const usersColumns: TableColumn[] = [
  {
    type: "client",
    // value => name in translation
    value: "Name",
    fieldName: "name",
    secondaryValue: "group",
    width: 18,
  },
  {
    type: "email",
    value: "Email",
    fieldName: "email",
    width: 20,
  },
  {
    type: "categories",
    value: "responsibilities",
    fieldName: "categories",
    width: 32,
  },
  // {
  //   type: "type",
  //   value: "role",
  //   fieldName: "type",
  //   width: 5,
  // },
  {
    type: "status",
    value: "Status",
    fieldName: "status",
    width: 12,
  },
  {
    type: "actions",
    value: "",
    fieldName: "users",
    width: 5,
  },
];
