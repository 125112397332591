import React, { createContext, useReducer } from "react";
import CasesReducer from "../reducers/cases.reducer";
import { casesColumns } from "../../statics/casesColumns";

const initialState: any = {
  cases: [],
  selectedCases: [],
  filter: {
    categoryId: '',
    branchId: '',
    statusId: '',
    fromDate: '',
    toDate: '',
    assigneeId: ''
  },
  columns: casesColumns,
  options: {
    withSort: true,
    sortDirection: 'asc',
    sortColumn: '',
  },
  pagination: {
    currentPage: 1,
    items: 15,
    totalPages: 1,
    total: 0,
  }
};

export const CasesContext = createContext(initialState);
export const CasesProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(CasesReducer, initialState);

  function setCases(cases: any[]) {
    dispatch({
      type: "SET_CASES",
      payload: { cases }
    });
  }

  function setSelectedCases(selectedCases: any[]) {
    dispatch({
      type: "SET_SELECTED_CASES",
      payload: { selectedCases }
    });
  }

  function sort(params: any) {
    dispatch({
      type: "SORT_CASES",
      payload: { params }
    });
  }

  function setFilter(filter: string) {
    dispatch({
      type: "SET_FILTER",
      payload: { filter }
    });
  }

  function setPagination(params: any) {
    dispatch({
      type: "SET_PAGINATION",
      payload: { params }
    });
  }

  return (
    <CasesContext.Provider
      value={{
        ...state,
        setCases,
        setSelectedCases,
        setFilter,
        setPagination,
        sort,
      }}
    >
      {children}
    </CasesContext.Provider>
  );
};