import React, { useContext, useState } from "react";
import "./files-timeline.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { TimelineStatusesComponent } from "../timeline-statuses/timeline-statuses.component";
import { CaseContext } from "../../store";
import FilesTable from "../files-table/files-table.component";
import { CaseFile } from "../../types/case-files";

export const FilesTimelineComponent = () => {
  const [t] = useTranslation();
  const { filesLoading, caseDetails, caseStatuses, caseFiles } = useContext(CaseContext);
  const [timelineStatuses, setTimelineStatuses] = useState(caseStatuses);

  const isActiveStatus = (status: string): boolean => {
    return caseDetails.status === status;
  };

  const expandStatusComments = (expand: boolean, index: number): void => {
    const timelineStatusesCopy = [...timelineStatuses];
    timelineStatusesCopy[index].expand = expand;
    setTimelineStatuses(timelineStatusesCopy);
  }

  return (
    <div className="timeline">
      <div className="timeline-items">
        {caseFiles.map((caseFile: CaseFile, index: any) => {
          const files: any[] = (!timelineStatuses[index]?.expand && !filesLoading) ? [] : caseFile.files;
          return (
            <TimelineStatusesComponent
              key={index}
              activeStatus={isActiveStatus(caseFile.status)}
              lastStatus={index === timelineStatuses.length - 1}
              icon={timelineStatuses[index]?.icon}
              status={caseFile.status}
              date={caseFile.date}
              loading={filesLoading}
              expand={timelineStatuses[index]?.expand && caseFile.total > 0}
              handleExpand={(value: boolean) => expandStatusComments(value, index)}
              dimmed={timelineStatuses[index]?.dimmed}
              statusCount={`(${caseFile.total} ${t('files')})`}
              total={caseFile.total}
              tab={"files"}
            >
              {(files && files.length) ?
                <FilesTable 
                  files={files}
                  loading={filesLoading}
                  mainProcessorId={caseDetails.mainProcessorId}
                />
                : null
              }
            </TimelineStatusesComponent>
          );
        })}
      </div>
    </div>
  );
};
