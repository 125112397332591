import { icons } from "./icons";

export const reporterDetails: any[] = [
  {
    icon: icons.userImg,
    value: "reporterName",
    title: "reporter name",
    classes: "col-6",
  },
  {
    icon: icons.validated,
    value: "reporterEmail",
    title: "reporter email",
    classes: "col-6",
  },
  {
    icon: icons.labels,
    value: "phoneNumber",
    title: "reporter phone number",
    classes: "col-6",
  },
];
