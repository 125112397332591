export const defaultQuestions: any[] = [
    {
        "STATUS": "RECEIVED",
        "TEXT": "1.1 Ist die beschriebene Meldung plausibel?",
        "ANSWER": ""
    },
    {
        "STATUS": "RECEIVED",
        "TEXT": "1.2 Wurden Videomaterial und andere digitale Aufzeichnungen, z.B. Protokolldaten der Zugangsmedien, gesichert?",
        "ANSWER": ""
    },
    {
        "STATUS": "RECEIVED",
        "TEXT": "1.3 Liegen laut der ersten Beurteilung ausreichend Informationen für eine angemessene Bearbeitung vor?",
        "ANSWER": ""
    },
    {
        "STATUS": "RECEIVED",
        "TEXT": "1.4 Gab es in der Vergangenheit bereits Hinweise, die im Zusammenhang mit dieser Meldung stehen könnten?",
        "ANSWER": ""
    },
    {
        "STATUS": "INPROGRESS",
        "TEXT": "2.1 Hat sich ein Verdachtsfall durch die Sichtung weiterer und für den Bearbeiter direkt verfügbaren Informationen und Dokumente erhärtet?",
        "ANSWER": ""
    },
    {
        "STATUS": "INPROGRESS",
        "TEXT": "2.2 Wurden Sofortmaßnahmen gesetzt (z.B. Benutzerkennung sperren, Endbenutzergerät abholen, Zahllauf stoppen, Zutrittsmedien sperren, etc.), damit die mögliche dolose Handlung nicht fortgesetzt werden kann?",
        "ANSWER": ""
    },
    {
        "STATUS": "INPROGRESS",
        "TEXT": "2.3 Wurde ein Rechtsanwalt in die Meldungsbearbeitung involviert?",
        "ANSWER": ""
    },
    {
        "STATUS": "INPROGRESS",
        "TEXT": "2.4 Wurde der Vorgang ggf. der Versicherung gemeldet bzw. wurde sie in die Meldungsbearbeitung involviert?",
        "ANSWER": ""
    },
    {
        "STATUS": "INPROGRESS",
        "TEXT": "2.5 Ist die Anforderung weiterer Informationen zur Analyse aus den betroffenen Abteilungen notwendig?",
        "ANSWER": ""
    },
    {
        "STATUS": "INPROGRESS",
        "TEXT": "2.6 Ist die Anforderung weiterer Informationen zur Analyse vom Hinweisgeber notwendig?",
        "ANSWER": ""
    },
    {
        "STATUS": "INPROGRESS",
        "TEXT": "2.7 Ist die Anforderung weiterer Informationen zur Analyse von Dienstleistern oder unternehmensexternen Personen notwendig?",
        "ANSWER": ""
    },
    {
        "STATUS": "INPROGRESS",
        "TEXT": "2.8  Wurden alle Untersuchungsergebnisse bezüglich der Analysen der erhaltenen Informationen und Unterlagen dokumentiert? Dazu gehören auch folgende digitale Informationen: Protokolldateien, emails, Login-Informationen, Druckerdaten etc.",
        "ANSWER": ""
    },
    {
        "STATUS": "INPROGRESS",
        "TEXT": "2.9 Wurden alle Interviews mit den relevanten Personen inkl. dem Hinweisgeber geführt und angemessen dokumentiert?",
        "ANSWER": ""
    },
    {
        "STATUS": "INPROGRESS",
        "TEXT": "2.10 Konnte der Vorgang bzw. die Ursache des Vorfalls aufgeklärt werden?",
        "ANSWER": ""
    },
    {
        "STATUS": "INPROGRESS",
        "TEXT": "2.11 Konnten mitigierende Maßnahmen identifiziert werden?",
        "ANSWER": ""
    },
    {
        "STATUS": "REVIEW",
        "TEXT": "3.1 Wurden die Stakeholder über den Vorgang angemessen unterrichtet?",
        "ANSWER": ""
    },
    {
        "STATUS": "REVIEW",
        "TEXT": "3.2 Wurde die Geschäftsführung über den Vorgang angemessen unterrichtet?",
        "ANSWER": ""
    },
    {
        "STATUS": "REVIEW",
        "TEXT": "3.3 Wurde eine Behörde bezüglich des Vorgangs unterrichtet bzw. involviert?",
        "ANSWER": ""
    },
    {
        "STATUS": "REVIEW",
        "TEXT": "3.4 Wird eine Versicherungsleistung erbracht?",
        "ANSWER": ""
    },
    {
        "STATUS": "REVIEW",
        "TEXT": "3.5 Wird eine Klage eingereicht?",
        "ANSWER": ""
    },
    {
        "STATUS": "REVIEW",
        "TEXT": "3.6 Werden mitigierende Maßnahmen im Unternehmen umgesetzt?",
        "ANSWER": ""
    },
    {
        "STATUS": "REVIEW",
        "TEXT": "3.7 Wurden alle erhaltenen Informationen berücksichtigt und strukturiert im digitalen Akt abgelegt?",
        "ANSWER": ""
    },
    {
        "STATUS": "REVIEW",
        "TEXT": "3.8 Wurde der Vorgang ausreichend beschrieben und kann nun revisionssicher dokumentiert werden?",
        "ANSWER": ""
    },
    {
        "STATUS": "REVIEW",
        "TEXT": "3.9 Wurden die Abschlussinformation an den Hinweisgeber formuliert und mit den Stakeholdern abgestimmt, sodass sie übersendet werden können?",
        "ANSWER": ""
    },
    {
        "STATUS": "REVIEW",
        "TEXT": "3.10 Wurden die vergebenen Berechtigungen in der Vorgangsbearbeitung einschränkt?",
        "ANSWER": ""
    }
]