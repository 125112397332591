import React from "react";
import HeaderComponent from "../../components/header/header.component";
import CaseContent from "../../components/case-content/case-content.component";
import { CaseProvider } from "../../store";
import { CommonService } from "../../services/common/common.service";
import { useTranslation } from "react-i18next";

export default function CasePage(props: any) {
  const [t] = useTranslation();
  const commonService: CommonService = new CommonService();
  const caseId = props.match.params.id;
  commonService.setTitle(t("Case Manager"));
  return (
    <CaseProvider>
      <HeaderComponent />
      <CaseContent caseId={caseId} />
    </CaseProvider>
  );
}
