import moment from "moment";
import "moment/locale/de";
const fromDate = moment().format("YYYY-MM-DD");
export const periodsRange: any[] = [
  {
    name: "all",
    value: {
      fromDate: "all",
      toDate: "all",
    },
  },
  {
    name: "this week",
    value: {
      fromDate,
      toDate: moment().add(1, "week").format("YYYY-MM-DD"),
    },
  },
  {
    name: "this month",
    value: {
      fromDate,
      toDate: moment().add(1, "M").format("YYYY-MM-DD"),
    },
  },
  {
    name: "last 6 months",
    value: {
      fromDate,
      toDate: moment().add(6, "M").format("YYYY-MM-DD"),
    },
  },
];
