import React from "react";
import "./case-tab-content.component.scoped.scss";
import { CaseContext } from "../../store";
import { DescriptionTabComponent } from "../description-tab/description-tab.component";
import { ReporterCommunicationTabComponent } from "../reporter-communication-tab/reporter-communication-tab.component";
import { WorkflowTabComponent } from "../workflow-tab/workflow-tab.component";
import { FilesTabComponent } from "../files-tab/files-tab.component";
import { LocalStorageService } from "../../services/local-storage/local-storage";
import { NotesTabComponent } from "../communication-tab/communication-tab.component";

export default function CaseTabContentComponent() {
  const { activeTab, caseDetails } = React.useContext(CaseContext);
  const localStorageService = new LocalStorageService();
  const user = localStorageService.getData("user");

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tab-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  }

  return (
    <div className="w-100">
      <TabPanel value={activeTab} index={0}>
        <DescriptionTabComponent />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <ReporterCommunicationTabComponent />
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        {(user.group === "internal" || user.keycloakUserId === caseDetails.mainProcessorId ||
          user.type === "superAdmin" || user.role === 'coordinator') && <FilesTabComponent />}
      </TabPanel>
      <TabPanel value={activeTab} index={3}>
        <WorkflowTabComponent />
      </TabPanel>
      <TabPanel value={activeTab} index={4}>
        <NotesTabComponent />
      </TabPanel>
    </div>
  );
}
