import React, { useContext, useState } from "react";
import "./communication-tab.component.scoped.scss";
import { CommunicationTimelineComponent } from "../communication-timeline/communication-timeline.component";
import { FilesUploadSectionComponent } from "../files-upload-section/files-upload-section.component";
import FilesDropZone from "../files-drop-zone/files-drop-zone.component";
import { CaseContext } from "../../store";
import { useTranslation } from "react-i18next";

export const NotesTabComponent = (props: any) => {
  const [t] = useTranslation();

  const { caseDetails } = useContext(CaseContext);

  const [files, setFiles] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  return (
    <FilesDropZone onDropFiles={setFiles} isSaving={isSaving}>
      <div className="theme-container communication-tab">
        <CommunicationTimelineComponent />
      </div>

      {(caseDetails.status !== "ABGESCHLOSSEN" && caseDetails.status !== "Completed") && <div className="sticky-container">
        <div className="theme-container">
          <FilesUploadSectionComponent
            tab="communication"
            droppedFiles={files}
            label={t("add file and description to communicate with other editors")}
            onUploadingFiles={setIsSaving}
          />
        </div>
      </div>}
    </FilesDropZone>
  );
};
