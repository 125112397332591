import { TableColumn } from "../types/table-column";

export const logsColumns: TableColumn[] = [
    {
        type: "case",
        value: "process",
        fieldName: "caseId",
        width: 15,
        component: "logs"
    },
    {
        type: "userEmail",
        value: "Processor",
        fieldName: "userEmail",
        width: 20,
        component: "logs"
    },
    {
        type: "key",
        value: "Action",
        fieldName: "key",
        width: 30,
        component: "logs"
    },
    {
        type: "date",
        value: "Date",
        fieldName: "created_at",
        width: 10,
        component: "logs"
    },
    {
        type: "time",
        value: "Time",
        fieldName: "created_at",
        width: 10,
        component: "logs"
    },

];
