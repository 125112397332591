import React, { useState } from "react";
import "./input.component.scoped.scss";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField/TextField";
import { FormInputProps } from "../../types/form-input-props";
import { icons } from "../../statics/icons";
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

export const InputComponent = (props: FormInputProps) => {
  const { icon, label, id, validate, error, type, focused = false } = props;
  const [t] = useTranslation();
  const iconReady: any = icons[`${icon}`];
  const [active, setActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const setActiveInput = (type: boolean) => {
    setActive(type);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      <div className={"input-wrapper " + (active ? "active" : "")}>
        <img alt="icon" className="icon" src={iconReady} />
        <TextField
          autoFocus={focused}
          onFocus={setActiveInput.bind(null, true)}
          onBlur={setActiveInput.bind(null, false)}
          error={error ? true : false}
          variant="filled"
          id={id}
          type={type === "password" && showPassword ? "text" : type}
          label={t(label!)}
          onInput={validate}
          autoComplete="off"
          onKeyDown={props.onKeyDown}
          InputProps={{
            endAdornment: type === "password" && (
              <InputAdornment position="end">
                <IconButton
                  className="toggle-password"
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      {error && <div className="error-msg">{t(error!)}</div>}
    </>
  );
};
