const sizeInBytes = 1024 * 1000;

interface FileTypes {
  pdf: FileType;
  png: FileType;
  jpg: FileType;
  mp4: FileType;
}

interface FileType {
  maxSize: number;
}

export const FileSizes: FileTypes = {
  pdf: {
    maxSize: 20 * sizeInBytes
  },
  png: {
    maxSize: 8 * sizeInBytes
  },
  jpg: {
    maxSize: 8 * sizeInBytes
  },
  mp4: {
    maxSize: 200 * sizeInBytes
  }
}
