import React, { useContext, useEffect, useState } from "react";
import { CircularProgress, IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";
import './coordinators-search.component.scoped.scss';
import { SettingsService } from "../../services/settings/settings.service";
import { icons } from "../../statics/icons";
import { GlobalContext } from "../../store";

export default function CoordinatorsSearch(props: any) {
    const [t] = useTranslation();
    const settingsService: SettingsService = new SettingsService();
    const { onCoordinatorChange, onError } = props;

    const { user } = useContext(GlobalContext);

    const labelKey: any = 'name';
    const minCharTyped: number = 3;
    const emptyArr: any[] = [];
    const obj: any = {};

    const [_typeahead, setTypeahead] = useState(obj);
    const [coordinator, setCoordinator] = useState(props.coordinator);
    const [superUsers, setSuperUsers] = useState(emptyArr);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(props.error);

    useEffect(() => {
        setError(props.error);
    }, [props.error])

    const handleSearch = async (query: any) => {
        if (query && query.length >= minCharTyped) {
            setLoading(true);
            let superUsersResult: any[];
            // eslint-disable-next-line prefer-const
            superUsersResult = await settingsService.searchSuperUsers(query);
            superUsersResult.forEach((elem, i) => {
                if (elem.id === user.id) {
                    superUsersResult.splice(i, 1);
                }
            });
            setSuperUsers(superUsersResult);
            setLoading(false);
        } else {
            setSuperUsers([]);
            setError(false);
            onError(false);
        }
    };

    const selectUser = (options: any) => {
        if (options.length) {
            const user = options[0];
            setCoordinator(user);
            onCoordinatorChange(user);
            setTimeout(() => {
                _typeahead.blur();
            }, 0);
            setError(false);
        }
    };

    const deleteUser = () => {
        setCoordinator({});
        onCoordinatorChange({});
        setSuperUsers([]);
        setTimeout(() => {
            _typeahead.clear();
        }, 0);
        setError(false);
        onError(false);
    }

    return (
        <>
            <div
                className={"typeahead-search categories-assign " + (error ? "required" : "")}>
                <AsyncTypeahead
                    id="typeahead-search"
                    defaultSelected={coordinator.name ? [coordinator.name] : []}
                    isLoading={false}
                    minLength={minCharTyped}
                    labelKey={labelKey}
                    options={superUsers}
                    onSearch={handleSearch}
                    onInputChange={handleSearch}
                    onChange={selectUser}
                    onFocus={() => handleSearch(coordinator.name)}
                    placeholder={t("coordinator")}
                    promptText={(!loading && superUsers.length === 0) ? "No matches found." : "Searching..."}
                    emptyLabel={(!loading && superUsers.length === 0) ? "No matches found." : "Searching..."}
                    searchText="Searching..."
                    ref={(ref: any) => setTypeahead(ref)}
                    disabled={coordinator.id === user.id}
                    renderMenuItemChildren={(user, props) => (
                        <div className="user-search-option">
                            <div className="user-info">
                                <img alt="" src={user.image ? user.image : icons.userImg} />
                                <div className="user-name">{user.name}</div>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div
                className="d-flex align-items-center error-msg mt-1"
                style={{ visibility: error ? "visible" : "hidden" }}>
                <img src={icons.alert} alt="" className="alert-icon mr-2" />
                <div className="trim">
                    Can't be assigned, because this coordinator already selected.
                </div>
            </div>
            {coordinator.name && coordinator.id !== user.id && (
                <div className="typeahead-delete-icon">
                    <IconButton aria-label="delete" onClick={deleteUser}>
                        <ClearIcon className="user-delete" />
                    </IconButton>
                </div>
            )}
            {loading && (
                <div className="typeahead-loading-icon"
                    style={{ right: coordinator.name ? "40px" : "16px" }}>
                    <CircularProgress color="inherit" />
                </div>
            )}
        </>
    );
}
