export const createJson: any = {
    key: 'create',
    value: `{
    "choose country/company": {
        "EN": "Choose Country/Company",
        "DE": "Land/Unternehmen auswählen"
    },
    "create": {
        "EN": "Create",
        "DE": "Anlegen"
    },
    "track-case": {
        "EN": "Track case",
        "DE": "Meldung verfolgen"
    },
    "new-case": {
        "EN": "New Case", 
        "DE": "Neue Meldung"
    },
    "welcome to the reporting channel": {
        "EN": "Welcome to the reporting channel", 
        "DE": "Willkommen im Hinweisgeber:innensystem"
    },
    "welcome-title-one": {
        "EN": "With this internal reporting system, the company provides a channel for its employees, suppliers, service providers and customers to anonymously submit incidents against violations of laws and internal guidelines.", 
        "DE": "Mit diesem Hinweisgeber:innensystem bietet die Arbeiterkammer die Möglichkeit, Verstöße gegen Gesetze und interne Richtlinien einfach und auch anonym zu melden."
    },
    "welcome-title-two": {
        "EN": "All transmitted information and data are encrypted, stored on secure IT systems and only made available to explicitly authorized persons for processing.",
        "DE": "Alle übermittelten Informationen und Daten werden verschlüsselt auf sicheren IT-Systemen gespeichert und zur Bearbeitung nur explizit autorisierten Personen zugänglich gemacht."
    },
    "welcome-title-three": {
        "EN": "When using the system, we do not record personal data or your IP addresses. Nevertheless, we recommend the TOR browser to use the system. This internet browser makes it possible to browse the internet anonymously, completely encrypted and securely.",
        "DE": "Das System zeichnet weder personenbezogene Daten noch Ihre IP-Adresse auf und wird von der GRC Experts GmbH, einer Tochtergesellschaft des Instituts für Interne Revision Österreich, betrieben, um ein Höchstmaß an Anonymität und Sicherheit garantieren zu können."
    },
    "welcome-title-four": {
        "EN": "The system is operated by Compliance GmbH in order to be able to guarantee the highest possible level of anonymity and security. Please ensure that you are outside the company network when you receive the notification.",
        "DE": "Das System wird von der Compliance GmbH betrieben, um ein höchstmögliches Maß an Anonymität und Sicherheit gewährleisten zu können. Bitte stellen Sie sicher, dass Sie sich zum Zeitpunkt des Empfangs der Benachrichtigung außerhalb des Firmennetzes befinden."
    },
    "where did the incident take place?": {
        "EN": "Where did the incident take place?",
        "DE": "In welcher Arbeiterkammer ereignete sich der Vorfall?"
    },
    "affected company": {
        "EN": "Affected company",
        "DE": "Betroffenes Unternehmen"
    },
    "country": {
        "EN": "Country",
        "DE": "Land"
    },
    "enter report": {
        "EN": "Enter Report",
        "DE": "Meldung Eingeben"
    },
    "please note that your information will only be forwarded to the selected company": {
        "EN": "Please note that your information will only be forwarded to the selected company.",
        "DE": "Bitte beachten Sie, dass Ihr Hinweis nur an das ausgewählte Unternehmen weitergeleitet wird."
    }
}`
}

export const recordJson: any = {
    key: 'record',
    value: `{
    "record case": {
        "EN": "Record Case",
        "DE": "Record Case"
    },
    "record": {
        "EN": "Record",
        "DE": "Erfassen"
    },
    "description-title": {
        "EN": "Describe the incident",
        "DE": "Beschreiben Sie Ihre Meldung"
    },
    "description-text": {
        "EN": "If you have noticed something that you believe violates a law or internal policy, please describe the incident as accurately as possible.",
        "DE": "Wenn Sie etwas wahrgenommen haben, von dem Sie annehmen, dass es gegen ein Gesetz oder interne Richtlinien verstößt, beschreiben Sie den Vorfall bitte so genau wie möglich."
    },
    "description-label": {
        "EN": "Description of the incident or the improvement as precisely as possible",
        "DE": "Möglichst genaue Beschreibung des Vorfalls bzw. der Verbesserung"
    },
    "relevant information on desc field": {
        "EN": "When you record an audio track, it will not be distorted.",
        "DE": "Wenn Sie eine Tonspur aufnehmen, dann wird diese nicht verzerrt."
    },
    "category-title": {
        "EN": "Assign a category to the incident",
        "DE": "Ordnen Sie der Meldung eine Kategorie zu"
    },
    "category-text": {
        "EN": "In order to enable quick processing and to determine a suitable coordinator in our organization, we ask you to allocate the possible category.",
        "DE": "Um eine schnelle Bearbeitung zu ermöglichen, bitten wir Sie um Zuordnung Ihres Hinweises zu der möglichen Kategorie."
    },
    "anonymity-title": {
        "EN": "Choose anonymity",
        "DE": "Anonymität"
    },
    "anonymity-text": {
        "EN": "For certain reasons, you can remove your anonymity here. In this case, your name and your email address will be visible in the system.",
        "DE": "Eine Kommunikation mit anonymen Hinweisgeber:innen ist ausschließlich über diese Plattform nach Eingabe der Vorgangsnummer möglich. Wenn Sie Ihre Anonymität aufheben und Ihre Kontaktdaten eintragen, können wir Sie für Rückfragen auch per E-Mail kontaktieren."
    },
    "Share personal data": {
        "EN": "Share personal data",
        "DE": "Personenbezogene Daten freigeben"
    },
    "name-username-label": {
        "EN": "Name/Username",
        "DE": "Name/Username"
    },
    "email-label": {
        "EN": "Email address",
        "DE": "Email Adresse"
    },
    "relevant information on anonymity field": {
        "EN": "For the validation of your person we need your e-mail address.",
        "DE": "Für die Validierung Ihrer Person benötigen wir Ihre E-Mail Adresse."
    },
    "optional-info-title": {
        "EN": "Optional information",
        "DE": "Weiterführende Informationen"
    },
    "optional-info-text": {
        "EN": "You can submit the following more specific information to optimize processing.",
        "DE": "Um die Bearbeitung zu beschleunigen, können Sie uns genauere Informationen übermitteln."
    },
    "the fields are optional": {
        "EN": "The fields are optional.",
        "DE": "Diese Felder sind nicht verpflichtend auszufüllen."
    },
    "involved-company-label": {
        "EN": "Involved company",
        "DE": "Beteiligtes Unternehmen"
    },
    "relation-label": {
        "EN": "Relation to the company",
        "DE": "Beziehung zur AK"
    },
    "case is still active": {
        "EN": "Case is still active.",
        "DE": "Verhalten dauert an"
    },
    "start-date-label": {
        "EN": "Start date of the incident",
        "DE": "Startdatum"
    },
    "the start date should be less than the end date": {
        "EN": "The start date should be less than the end date",
        "DE": "Das Startdatum sollte weniger als das Enddatum sein"
    },
    "end-date-label": {
        "EN": "End date of the incident",
        "DE": "Enddatum"
    },
    "invalid date format": {
        "EN": "Invalid Date Format",
        "DE": "Ungültiges Datumsformat"
    },
    "involved-people-label": {
        "EN": "Involved people",
        "DE": "Beteiligte Person/en"
    },
    "involved-department-label": {
        "EN": "Involved department",
        "DE": "Betroffene Abteilung/en"
    },
    "amount-damage-label": {
        "EN": "Amount of damage",
        "DE": "Schadenshöhe"
    },
    "back": {
        "EN": "Back",
        "DE": "Zurück"
    },
    "review": {
        "EN": "Review",
        "DE": "Überprüfen"
    },
    "files": {
        "EN": "Files", 
        "DE": "Dateien"
    }
}`
}

export const checkJson: any = {
    key: 'check',
    value: `{
    "check case": {
        "EN": "Check Case",
        "DE": "Check Case"
    },
    "check": {
        "EN": "Check",
        "DE": "Überprüfen"
    },
    "check-title": {
        "EN": "Please check the details of your case",
        "DE": "Bitte überprüfen und ändern Sie gegenebenfalls Ihre Angaben bevor Sie die Meldung absenden"
    },
    "check-text": {
        "EN": "Make sure that the information cannot be used to identify you personally. We take your advice very seriously and will initiate investigations. If you are unsure about the details of this message, please mark this part as a guess.",
        "DE": "Stellen Sie sicher, dass bei den Angaben keine Rückschlüsse auf Ihre Person möglich sind, wenn Sie anonym bleiben wollen. Überprüfen Sie auch die Anhänge auf Ihre Anonymität. Wenn Sie sich bei manchen Details Ihrer Meldung nicht sicher sind, dann schreiben Sie das bitte dazu."
    },
    "check-information": {
        "EN": "Check information",
        "DE": "Angaben überprüfen"
    },
    "company": {
        "EN": "Company",
        "DE": "Unternehmen"
    },
    "country": {
        "EN": "Country",
        "DE": "Land"
    },
    "category": {
        "EN": "Category",
        "DE": "Kategorie"
    },
    "name-username": {
        "EN": "Name/Username",
        "DE": "Name/Username"
    },
    "email-address": {
        "EN": "Email address",
        "DE": "Email Adresse"
    },
    "description": {
        "EN": "Description",
        "DE": "Vorfall"
    },
    "start-date": {
        "EN": "Start date of the incident",
        "DE": "Startdatum"
    },
    "end-date": {
        "EN": "End date of the incident",
        "DE": "Enddatum"
    },
    "involved-people": {
        "EN": "Involved people",
        "DE": "Beteiligte Person/en"
    },
    "involved-department": {
        "EN": "Involved department",
        "DE": "Betroffene Abteilung/en"
    },
    "involved-company": {
        "EN": "Involved company",
        "DE": "Beteiligtes Unternehmen"
    },
    "amount-damage": {
        "EN": "Amount of damage",
        "DE": "Schadenshöhe"
    },
    "relation": {
        "EN": "Relation to the company",
        "DE": "Beziehung zur AK"
    },
    "back": {
        "EN": "Back",
        "DE": "Zurück"
    },
    "finish": {
        "EN": "Finish",
        "DE": "Abschließen"
    },
    "dialog-confirm-case-title": {
        "EN": "Thank you very much for your comment!",
        "DE": "Vielen Dank für Ihren Hinweis!"
    },
    "dialog-confirm-case-text": {
        "EN": "We take it seriously and will initiate investigations. Your report will only be processed by explicitly authorized persons. Please note:",
        "DE": "Wir nehmen diesen ernst und werden Untersuchungen einleiten. Ihre Meldung wird nur von explizit autorisierten Personen bearbeitet. Bitte beachten Sie:"
    },
    "dialog-confirm-case-footer": {
        "EN": "After you have completed this notification by clicking 'Understood', you will no longer be able to change your information.",
        "DE": "Nachdem Sie diese Meldung mit „Verstanden” abgeschlossen haben, können Sie Ihre Angaben nicht mehr ändern."
    },
    "dialog-confirm-case-point-one": {
        "EN": "As a whistleblower:in you are protected from disadvantages in connection with this report.",
        "DE": "Als Hinweisgeber:in sind Sie vor Nachteilen im Zusammenhang mit dieser Meldung geschützt."
    },
    "dialog-confirm-case-point-two": {
        "EN": "Obviously false or misleading information will be rejected, may give rise to claims for damages and may be subject to legal action.",
        "DE": "Offenkundig falsche oder irreführende Hinweise werden zurückgewiesen, können Schadenersatzansprüche begründen und gegebenenfalls gerichtlich verfolgt werden."
    },
    "dialog-confirm-case-approve": {
        "EN": "Approve",
        "DE": "Verstanden"
    }
}`
}

export const downloadJson: any = {
    key: 'download',
    value: `{
    "receive access data": {
        "EN": "Receive access data",
        "DE": "Zugangsdaten erhalten"
    },
    "download": {
        "EN": "Download",
        "DE": "Download"
    },
    "title": {
        "EN": "Thank you for your report!",
        "DE": "Vielen Dank für Ihre Meldung!"
    },
    "text-one": {
        "EN": "We will take care of the matter and would like to stay in contact with you in order to inform you about our findings and, if necessary, to receive further information from you.",
        "DE": "Wir werden uns um den Sachverhalt kümmern und möchten gerne mit Ihnen in Kontakt bleiben, um Sie über unsere Erkenntnisse zu informieren und gegebenenfalls weitere Informationen von Ihnen zu erhalten."
    },
    "text-two": {
        "EN": " Please write down the following access data for the reported case. You can use this to log in to the “Track case” menu and communicate with us. There is no other way you can anonymously contact us on this matter otherwise.",
        "DE": "Bitte schreiben Sie sich daher die folgenden Zugangsdaten zum gemeldeten Vorgang auf. Mit diesen können Sie sich im Menü „Meldung verfolgen“ anmelden und mit uns kommunizieren. Es gibt keine andere Möglichkeit, wie Sie mit uns ansonsten anonym zu diesem Sachverhalt in Kontakt treten können."
    },
    "text-important": {
        "EN": "IMPORTANT: Please note the access data for your case! Only with this data you will have access to your case later.",
        "DE": "WICHTIG: Notieren Sie sich die Vorgangsnummer! Nur mit der Vorgangsnummer haben Sie später Zugriff auf Ihre Meldung."
    },
    "access-data": {
        "EN": "Access Data",
        "DE": "Vorgangsnummer"
    },
    "download-access-data": {
        "EN": "Download Access Data",
        "DE": "Download Zugangsdaten"
    },
    "download-pdf-file": {
        "EN": "Download report data as a pdf",
        "DE": "Download Meldung Datei als Pdf"
    },
    "close": {
        "EN": "Close",
        "DE": "Schliessen"
    },
    "open-case": {
        "EN": "Open Case",
        "DE": "Meldung Öffnen"
    },
    "dialog-open-case-title": {
        "EN": "Did you note the access data for your case?",
        "DE": "Haben Sie sich die Vorgangsnummer notiert?"
    },
    "dialog-open-case-text": {
        "EN": "ATTENTION: Note the process number!",
        "DE": "ACHTUNG: Vorgangsnummer notieren!"
    },
    "dialog-open-case-yes": {
        "EN": "Yes",
        "DE": "Ja"
    }
}`
}

export const pdfJson: any = {
    key: 'PDF',
    value: `{
        "case-secret-key": {
            "EN": "Case Secret Key",
            "DE": "Gehäuse Geheimschlüssel"
        },
        "involved-people": {
            "EN": "Involved people",
            "DE": "Beteiligte Personen"
        },
        "involved-departments": {
            "EN": "Involved departments",
            "DE": "Beteiligte Abteilungen"
        },
        "category": {
            "EN": "Category",
            "DE": "Kategorie"
        },
        "branch": {
            "EN": "Branch",
            "DE": "Zweigstelle"
        },
        "country": {
            "EN": "Country",
            "DE": "Land"
        },
        "amount": {
            "EN": "Amount",
            "DE": "Betrag"
        },
        "ongoing-case": {
            "EN": "Ongoing case",
            "DE": "Laufender Fall"
        },
        "yes": {
            "EN": "Yes",
            "DE": "Ja"
        },
        "no": {
            "EN": "No",
            "DE": "Nein"
        },
        "involved-company": {
            "EN": "Involved company",
            "DE": "Beteiligtes Unternehmen"
        },
        "anoymous-reporter": {
            "EN": "Anoymous reporter",
            "DE": "Anoymer Reporter"
        },
        "reporter-name": {
            "EN": "Reporter name",
            "DE": "Name des Reporters"
        },
        "reporter-email": {
            "EN": "Reporter email",
            "DE": "E-Mail des Reporters"
        },
        "relation": {
            "EN": "Relation",
            "DE": "Beziehung"
        },
        "start-date": {
            "EN": "Start date",
            "DE": "Datum des Beginns"
        },
        "end-date": {
            "EN": "End date",
            "DE": "Datum des Endes"
        },
        "description": {
            "EN": "Description",
            "DE": "Beschreibung"
        }
    }`
}

export const caseJson: any = {
    key: "case",
    value: `{
        "case doesn't exist": {
            "EN": "case doesn't exist", 
            "DE": "Meldung existiert nicht"
        },
        "status": {
            "EN": "Status", 
            "DE": "Status"
        },
        "category": {
            "EN": "Category", 
            "DE": "Kategorie"
        },
        "created-date": {
            "EN": "Created date", 
            "DE": "Erstellungsdatum"
        },
        "results": {
            "EN": "Results", 
            "DE": "Ergebnisse"
        },
        "results-comment": {
            "EN": "Result comment", 
            "DE": "Resultats Kommentar"
        },
        "companies": {
            "EN": "Companies", 
            "DE": "Unternehmen"
        },
        "country": {
            "EN": "Country", 
            "DE": "Land"
        },
        "description": {
            "EN": "Description", 
            "DE": "Beschreibung"
        },
        "communication": {
            "EN": "Communication", 
            "DE": "Kommunikation"
        },
        "communication-files": {
            "EN": "Communication & Files", 
            "DE": "Kommunikation & Dateien"
        },
        "comments": {
            "EN": "Comments", 
            "DE": "Kommentare"
        },
        "comment": {
            "EN": "Comment", 
            "DE": "Kommentar"
        },
        "communication-text": {
            "EN": "Files and optionally a description can be uploaded here.", 
            "DE": "Hier können Dateien und optional eine Beschreibung dazu hochgeladen werden."
        },
        "communication-label": {
            "EN": "Add file and description to communicate with clerk", 
            "DE": "Datei und Beschreibung hinzufügen um mit Sachbearbeiter zu kommunizieren"
        },
        "files": {
            "EN": "Files", 
            "DE": "Dateien"
        },
        "character": {
            "EN": "Character", 
            "DE": "Zeichen"
        },
        "submited-description": {
            "EN": "Submitted description", 
            "DE": "Eingereichte Beschreibung"
        },
        "optional-data": {
            "EN": "Optional information", 
            "DE": "Optionale Angaben"
        },
        "notice-information": {
            "EN": "Notice information", 
            "DE": "Hinweisgeberinformationen"
        },
        "no data available": {
            "EN": "No data available", 
            "DE": "Keine Daten vorhanden"
        },
        "involved-people": {
            "EN": "Involved people", 
            "DE": "Beteiligte Personen"
        },
        "estimated-amount-damage": {
            "EN": "Estimated amount of damage", 
            "DE": "Geschätzte Schadenshöhe"
        },
        "involved-department": {
            "EN": "Involved department", 
            "DE": "Beteiligte Abteilungen"
        },
        "duration": {
            "EN": "Duration", 
            "DE": "Dauer"
        },
        "involved-company": {
            "EN": "Involved company", 
            "DE": "Beteiligtes Unternehmen"
        },
        "relation": {
            "EN": "Relation", 
            "DE": "Beziehung"
        },
        "name of hint": {
            "EN": "Name of hint", 
            "DE": "Hinweisgeber name"
        },
        "note given email": {
            "EN": "Note given email", 
            "DE": "Hinweisgeber email"
        },
        "whistleblower": {
            "EN": "Whistleblower", 
            "DE": "Hinweisgeber"
        },
        "clerk": {
            "EN": "Clerk", 
            "DE": "Sachbearbeiter/in"
        },
        "at": {
            "EN": "at", 
            "DE": "um"
        },
        "upload files": {
            "EN": "Upload files", 
            "DE": "Dateien hochladen"
        },
        "release the file to upload": {
            "EN": "Release the file to upload it for communication", 
            "DE": "Datei loslassen um sie zur Kommunikation hochzuladen"
        },
        "show-all": {
            "EN": "Show all", 
            "DE": "alle"
        },
        "show-comments": {
            "EN": "comments",
            "DE": "Kommentare anzeigen"
        }
    }`
}

export const trackJson: any = {
    key: "track",
    value: `{
        "case doesn't exist": {
            "EN": "Case doesn't exist", 
            "DE": "Meldung existiert nicht"
        },
        "thank you for staying in touch with us": {
            "EN": "Thank you for staying in touch with us!", 
            "DE": "Danke dass Sie mit uns in Kontakt bleiben!"
        },
        "identify yourself with your access data": {
            "EN": "Identify yourself with your access data", 
            "DE": "Identifizieren Sie sich mit Ihren Zugangsdaten"
        },
        "ticket number": {
            "EN": "Ticket number", 
            "DE": "Vorgangsnummer"
        },
        "continue": {
            "EN": "Continue", 
            "DE": "Weiter"
        }
    }`
}