import axios from 'axios'
import { config } from '../../config'
// const queryString = require('query-string')

export class UserService {
  // public getAdminToken = async (): Promise<any> => {
  //   try {
  //     const data = {
  //       client_id: 'admin-cli',
  //       grant_type: 'password',
  //       username: config.adminCliUserName,
  //       password: config.adminCliUserPassword,
  //     }
  //     const { keyCloakMasterUrl } = config
  //     const response = await axios.post(
  //       keyCloakMasterUrl + 'token',
  //       queryString.stringify(data)
  //     )
  //     return response.data
  //   } catch (error) {
  //     return {}
  //   }
  // }

  public updateUserInfo = async (user: any): Promise<any> => {
    try {
      const { serverUrl } = config
      const response = await axios.put(serverUrl + 'users/me', user)
      return response.data
    } catch (error) {
      return {}
    }
  }

  // public AddNewKeycloakUser = async (
  //   token: string,
  //   bodyObject: any
  // ): Promise<any> => {
  //   try {
  //     const headers = {
  //       Authorization: 'Bearer ' + token,
  //     }
  //     const { keyCloakAccountUrl } = config
  //     const response = await axios.post(
  //       keyCloakAccountUrl + 'users',
  //       bodyObject,
  //       {
  //         headers,
  //       }
  //     )
  //     return response
  //   } catch (error) {
  //     return error
  //   }
  // }

  // public getKeycloakUserIdByEmail = async (
  //   token: string,
  //   email: string
  // ): Promise<any> => {
  //   try {
  //     const headers = {
  //       Authorization: 'Bearer ' + token,
  //     }
  //     const { keyCloakAccountUrl } = config
  //     const response = await axios.get(
  //       keyCloakAccountUrl + 'users?email=' + email,
  //       {
  //         headers,
  //       }
  //     )
  //     return response.data
  //   } catch (error) {
  //     return error
  //   }
  // }

  // public addUserRoleMapping = async (
  //   token: string,
  //   id: string,
  //   body: any
  // ): Promise<any> => {
  //   try {
  //     const headers = {
  //       Authorization: 'Bearer ' + token,
  //     }
  //     const { keyCloakAccountUrl } = config
  //     const response = await axios.post(
  //       keyCloakAccountUrl + 'users/' + id + '/role-mappings/realm',
  //       body,
  //       {
  //         headers,
  //       }
  //     )
  //     return response.data
  //   } catch (error) {
  //     return error
  //   }
  // }

  public setUserPassword = async (
    token: string,
    id: string,
    body: any
  ): Promise<any> => {
    try {
      const headers = {
        Authorization: 'Bearer ' + token,
      }
      const { keyCloakUrl } = config
      const response = await axios.put(
        keyCloakUrl + 'users/' + id + '/reset-password',
        body,
        {
          headers,
        }
      )
      return response.data
    } catch (error) {
      return error
    }
  }

  public addNewUser = async (data: any, tenantId: any): Promise<any> => {
    try {
      const { serverUrl } = config
      const response = await axios.post(
        serverUrl + `users/add-user-to-tenant?tenantId=${tenantId}`,
        data
      )
      return response.data
    } catch (error) {
      return {}
    }
  }

  public addNewUserToKeyCloak = async (
    data: any,
    tenantId: any
  ): Promise<any> => {
    try {
      const { serverUrl } = config
      const response = await axios.post(
        serverUrl + `users/add-user-to-keycloak?tenantId=${tenantId}`,
        data
      )
      return response.data
    } catch (error) {
      return {}
    }
  }

  public verifyNewUser = async (data: any, tenantId: any): Promise<any> => {
    try {
      const { serverUrl } = config
      const response = await axios.post(
        serverUrl + 'users/verify-new-user?tenantId=' + tenantId,
        data
      )
      return response.data
    } catch (error) {
      return {}
    }
  }

  public resetPassword = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config
      const response = await axios.post(
        serverUrl + 'users/reset-password',
        data
      )
      return response.data
    } catch (error) {
      return {}
    }
  }

  public changePassword = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config
      const response = await axios.post(
        serverUrl + 'users/reset-password',
        data
      )
      return response.data
    } catch (error) {
      return error
    }
  }

  public addNewUserToCommonDb = async (
    data: any,
    tenantId: any
  ): Promise<any> => {
    try {
      const { serverUrl } = config
      const response = await axios.post(
        serverUrl + 'users/add-user?tenantId=' + tenantId,
        data
      )
      return response.data
    } catch (error) {
      return {}
    }
  }

  public sendUserActivationEmail = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config
      const response = await axios.post(
        serverUrl + 'users/send-user-activation-email',
        data
      )
      return response.data
    } catch (error) {
      return {}
    }
  }

  public getUserTenants = async (): Promise<any> => {
    try {
      const { serverUrl } = config
      const response = await axios.get(serverUrl + 'users/me/tenants')
      return response.data
    } catch (error) {
      return {}
    }
  }

  public canUserLogin = async (): Promise<any> => {
    try {
      const { serverUrl } = config
      const response = await axios.get(serverUrl + 'users/can-login')
      return response.data
    } catch (error) {
      return {}
    }
  }

  public sendUserForgottenPasswordEmail = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config
      const response = await axios.post(
        serverUrl + 'users/send-user-forgotten-password-email',
        data
      )
      return response.data
    } catch (error) {
      return {}
    }
  }

  public canResetForgottenPassword = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config
      const response = await axios.post(
        serverUrl + 'users/can-reset-forgotten-password',
        data
      )
      return response.data
    } catch (error) {
      return {}
    }
  }

  public checkTokenValidity = async (
    data: any,
    tenantId: any
  ): Promise<any> => {
    try {
      const { serverUrl } = config
      const response = await axios.post(
        serverUrl + 'users/can-set-password?tenantId=' + tenantId,
        data
      )
      return response.data
    } catch (error) {
      return error
    }
  }
}
