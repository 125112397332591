import React, { useContext, useEffect, useState } from "react";
import "./case-content.component.scoped.scss";
import { CaseContext } from "../../store";
import { CasesService } from "../../services/cases/cases.service";
import { MapperService } from "../../services/mapper/mapper.service";
import CaseHeaderComponent from "../case-header/case-header.component";
import CaseHeaderStickyComponent from "../case-header-sticky/case-header-sticky.component";
import CaseTabContentComponent from "../case-tab-content/case-tab-content.component";
import { dummyComments } from "../../statics/dummy-comments";
import { dummyFiles } from "../../statics/dummy-files";
import { dummyCommunication } from "../../statics/dummy-notes";
import { dummyQuestions } from "../../statics/dummy-questions";
import { deepClone, showToastMsg } from "../../helpers";
import { CommonService } from "../../services/common/common.service";
import { history } from "react-router-guard";
import { useTranslation } from "react-i18next";

export default function CaseContent(props: any) {
  const [t] = useTranslation();
  const casesService = new CasesService();
  const mapperService = new MapperService();
  const commonService: CommonService = new CommonService();
  const emptyArr: any = [];
  const [cachedStatuses, setCachedStatuses] = useState(emptyArr);
  const { caseId } = props;
  const {
    setCase,
    setLoading,
    setCommunicationsLoading,
    setWorkflowLoading,
    setCaseUsers,
    setCaseCategories,
    setLands,
    setBranches,
    setCaseAmounts,
    setCaseRelations,
    setCaseStatuses,
    setCaseComments,
    setCaseQuestions,
    setCaseTotalQuestions,
    setCaseTotalAnsweredQuestions,
    activeTab,
    caseDetailsUpdated,
    setCaseDetailsUpdated,
    activationWorkflowAI,
    caseDetails,
    completedStatusId,
    setCaseFiles,
    setFilesLoading,
    setNotesLoading,
    setCaseNotes,
  } = useContext(CaseContext);

  async function fetchCaseDetails(id: string) {
    setLoading(true);
    const caseDetailsData = await casesService.details(id);
    if (caseDetailsData === 'Not found') {
      history.push('/dashboard');
      showToastMsg("error", t("case not found"))
      return;
    }

    const statuses = await casesService.listStatuses();
    const mappedStatuses = mapperService.mapStatuses(statuses);
    const { mappedCase, mappedtimelineStatuses } = mapperService.mapCase(caseDetailsData, mappedStatuses);
    setCase(mappedCase);
    setCaseStatuses(mappedtimelineStatuses);
    setCachedStatuses(deepClone(mappedtimelineStatuses));
    setLoading(false);
    commonService.setTitle(caseDetailsData.internalId);
  }

  const getUsers = async (id: string) => {
    const usersResult = await casesService.getUsers(id);
    setCaseUsers(usersResult);
  };

  const getCaseCategories = async () => {
    const categories = await casesService.getAssignedCategories();
    setCaseCategories(categories);
  };

  const getLands = async () => {
    const categories = await casesService.listLands();
    setLands(categories);
  };

  const getBranches = async () => {
    const branches = await casesService.listBranches();
    setBranches(branches);
  };

  const getCaseAmounts = async () => {
    const amounts = await casesService.getCaseAmounts();
    const mappedAmounts = await mapperService.mapAmounts(amounts);
    setCaseAmounts(mappedAmounts);
  };

  const getCaseRelations = async () => {
    const relations = await casesService.getCaseRelations();
    const mappedRelations = await mapperService.mapRelations(relations);
    setCaseRelations(mappedRelations);
  };

  async function fetchCaseComments(id: string) {
    setCommunicationsLoading(true);
    setCaseComments(dummyComments);
    const caseComments = await casesService.caseComments(id);
    setTimeout(() => {
      setCaseComments(caseComments);
      setCommunicationsLoading(false);
    }, 100);
  }

  async function fetchCaseQuestions(id: string) {
    setWorkflowLoading(true);
    setCaseQuestions(dummyQuestions);
    const caseQuestions = await casesService.caseQuestions(id);
    if (caseDetails.statusId !== completedStatusId) {
      const {
        totalQuestions,
        totalAnsweredQuestions,
      } = mapperService.mapCaseQuestions(caseQuestions);
      setCaseTotalQuestions(totalQuestions);
      setCaseTotalAnsweredQuestions(totalAnsweredQuestions);
    }
    setTimeout(() => {
      setCaseQuestions(caseQuestions);
      setWorkflowLoading(false);
    }, 100);
  }

  async function fetchCaseFiles(id: string) {
    setFilesLoading(true);
    setCaseFiles(dummyFiles);
    const caseFiles = await casesService.caseFiles(id);
    setTimeout(() => {
      setCaseFiles(caseFiles);
      setFilesLoading(false);
    }, 100);
  }

  async function fetchCaseNotes(id: string) {
    setNotesLoading(true);
    setCaseNotes(dummyCommunication);
    const caseNotes = await casesService.getCaseCommunication(id);
    setTimeout(() => {
      setCaseNotes(caseNotes);
      setNotesLoading(false);
    }, 100);
  }

  useEffect(() => {
    switch (activeTab) {
      // Description Tab
      case 0:
        fetchCaseDetails(caseId);
        getUsers(caseId);
        getCaseCategories();
        getCaseAmounts();
        getCaseRelations();
        getLands();
        getBranches();
        break;
      // Communications Tab
      case 1:
        setCaseStatuses(
          mapperService.mapTimelineStatus(
            caseDetails,
            deepClone(cachedStatuses)
          )
        );
        fetchCaseComments(caseId);
        break;
      // Files Tab
      case 2:
        setCaseStatuses(
          mapperService.mapTimelineStatus(
            caseDetails,
            deepClone(cachedStatuses)
          )
        );
        fetchCaseFiles(caseId);
        break;
      // Workflow Tab
      case 3:
        setCaseStatuses(
          mapperService.mapTimelineStatus(
            caseDetails,
            deepClone(cachedStatuses)
          )
        );
        fetchCaseQuestions(caseId);
        break;
      // Notes Tab
      case 4:
        setCaseStatuses(
          mapperService.mapTimelineStatus(
            caseDetails,
            deepClone(cachedStatuses)
          )
        );
        fetchCaseNotes(caseId);
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [activeTab]);

  useEffect(() => {
    if (caseDetailsUpdated) {
      fetchCaseDetails(caseId);
      setCaseDetailsUpdated(false);
    }
    // eslint-disable-next-line
  }, [caseDetailsUpdated]);

  useEffect(() => {
    setCaseStatuses(
      mapperService.mapTimelineStatus(
        caseDetails,
        deepClone(cachedStatuses)
      )
    );
    fetchCaseQuestions(caseId);
    // eslint-disable-next-line
  }, [activationWorkflowAI]);

  return (
    <div className="case-content">
      <div className="case-header-content">
        <CaseHeaderComponent />
      </div>
      <div className="case-header-content sticky-header">
        <CaseHeaderStickyComponent />
      </div>
      <div className="case-tabs-content">
        <CaseTabContentComponent />
      </div>
    </div>
  );
}
