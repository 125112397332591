import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-guard";
import { LocalStorageService } from "../../services/local-storage/local-storage";
import { GlobalContext } from "../../store";
import './settings-side-menu.component.scoped.scss';

export default function SettingsSideMenu() {
    const [t] = useTranslation();
    const localStorageService = new LocalStorageService();
    const { user } = useContext(GlobalContext);

    const tenantName = localStorageService.getData("tenantName");

    return (
        <>
            <div className="side-menu-content">
                <div className="title">
                    <h1>{t('settings')}</h1>
                </div>
                <div className="workspace">
                    <h3>
                        {tenantName}
                        <small style={{ pointerEvents: (user.type === "superAdmin" || user.role === "coordinator") ? 'auto' : 'none' }}>
                            <NavLink activeClassName='active' exact to="/settings/workspace-management" >
                                WORKSPACE
                            </NavLink>
                        </small>
                    </h3>
                </div>
                <div className="links">
                    <ul>
                        <li>
                            <NavLink activeClassName='active' exact to="/settings/user-management">
                                {t('user management')}
                            </NavLink>
                        </li>
                        {(user.role === "coordinator" || user.type === 'superAdmin') &&
                            <li>
                                <NavLink activeClassName='active' exact to="/settings/roles-management" >
                                    {t('role management')}
                                </NavLink>
                            </li>
                        }
                        {(user.role === "coordinator" || user.type === 'superAdmin') &&
                            <>
                                <li>
                                    <NavLink activeClassName='active' exact to="/settings/workflow-management">
                                        {t('workflow management')}
                                    </NavLink>
                                </li>
                            </>
                        }
                        {(user.role === "coordinator" || user.type === 'superAdmin') &&
                            <>
                                <li>
                                    <NavLink activeClassName='active' exact to="/settings/translation-management">
                                        {t('database value management')}
                                    </NavLink>
                                </li>
                            </>
                        }
                        {(user.role === "coordinator" || user.type === 'superAdmin') &&
                            <>
                                <li>
                                    <NavLink activeClassName='active' exact to="/settings/branch-management">
                                        {t('branch management')}
                                    </NavLink>
                                </li>
                            </>
                        }
                        {(user.role === "coordinator" || user.type === 'superAdmin') &&
                            <>
                                <li>
                                    <NavLink activeClassName='active' exact to="/settings/reporter-configuration">
                                        {t('reporter page configuration')}
                                    </NavLink>
                                </li>
                            </>
                        }
                        {(user.role === "coordinator" || user.type === 'superAdmin') &&
                            <>
                                <li>
                                    <NavLink activeClassName='active' exact to="/settings/AI-management">
                                        {t('ai data management')}
                                    </NavLink>
                                </li>
                            </>
                        }
                        {(user.role === "coordinator" || user.type === 'superAdmin') &&
                            <>
                                <li className="logfile">
                                    <NavLink activeClassName='active' exact to="/settings/logfile">
                                        {t('logfile')}
                                    </NavLink>
                                </li>
                            </>
                        }
                    </ul>
                </div>
            </div>
        </>
    )
}
