import packageJson from '../../package.json';

export const localEnvironmentConfig = {

    // Staging
    domain: 'compliance2b.com',
    subDomain: 'staging',
    // serverUrl: 'http://localhost:4000/',
    serverUrl: 'https://staging-app-api.compliance2b.com/',
    keyCloakUrl: 'https://paladin-identity.compliance2b.com/auth/realms/paladin/',
    predictUrl: 'https://case-classsifier-staging.compliance2b.com/api/tasks/predict',
    appVersion: packageJson.version,
    environment: 'staging',
    // compliance
    // domain: 'compliance2b.com',
    // serverUrl: 'https://app-api.compliance2b.com/',
    // keyCloakUrl: 'https://keycloak-identity.compliance2b.com/auth/realms/compliance/',

    // GRC-experts
    // domain: 'grc-experts.report',
    // serverUrl: 'https://app-api.grc-experts.report/',
    // keyCloakUrl: 'https://keycloak-identity.grc-experts.report/auth/realms/grc-experts/',
}
