import React, { useEffect, useState } from "react";
import './company-info.component.scoped.scss';
import { Button, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { SettingsService } from "../../services/settings/settings.service";
import { Company } from "../../types/company";
import { digitOnly, emailRegex, stringRegex, telephoneRegex } from "../../statics/validators";
import { showToastMsg } from "../../helpers";

export default function CompanyInfo() {
    const settingsService: SettingsService = new SettingsService();
    const [t] = useTranslation();

    const [company, setCompany] = useState(new Company());
    const [companyName, setCompanyName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [telephoneError, setTelephoneError] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [postalCodeError, setPostalCodeError] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [saving, setSaving] = useState(false);
    const [formChanged, setFormChanged] = useState(false);
    const [id, setId] = useState();

    const getCompany = async () => {
        const companyInfo = await settingsService.getCompanyInfo();
        setId(companyInfo.id)
        delete companyInfo.id;
        setCompanyName(companyInfo.name);
        setCompany(companyInfo);
    }

    const handleChange = async (event: any, type: any) => {
        setCompany({ ...company, [type]: event.target.value });
        if (type === 'name') {
            setCompanyName(event.target.value);
        }
        setFormChanged(true);
    }

    const submit = async () => {
        setSaving(true);
        await settingsService.updateCompanyInfo(id, company).then(res => {
            setSaving(false);
            setFormChanged(false);
            if (res.message === 'success') {
                showToastMsg('success', t('company information updated successfully'))
            } else {
                showToastMsg('error', 'Something went wrong');
            }
        })
    }

    useEffect(() => {
        getCompany();
        // eslint-disable-next-line 
    }, []);

    return (
        <>
            <div className="company">
                <div className="company-form">
                    <div className="input-control">
                        <TextField
                            label={t('company name / company form')}
                            variant='filled'
                            name='name'
                            value={company.name}
                            style={{ width: '100%' }}
                            error={nameError}
                            onChange={(e: any) => {
                                e.target.value ? setNameError(!stringRegex.test(e.target.value)) : setNameError(false);
                                handleChange(e, 'name');
                            }}
                        />
                    </div>
                    <div className="input-control">
                        <TextField
                            label={t('company register number')}
                            variant='filled'
                            name='telephone'
                            value={company.telephone}
                            error={telephoneError}
                            style={{ width: '100%' }}
                            onChange={(e: any) => {
                                handleChange(e, 'telephone');
                                e.target.value ? setTelephoneError(!telephoneRegex.test(e.target.value)) : setTelephoneError(false);
                            }}
                        />
                    </div>
                    <div className="input-control">
                        <TextField
                            label={t('street, house number')}
                            variant='filled'
                            name='address'
                            value={company.address}
                            style={{ width: '100%' }}
                            error={addressError}
                            onChange={(e: any) => {
                                handleChange(e, 'address');
                                e.target.value ? setAddressError(!stringRegex.test(e.target.value)) : setAddressError(false);
                            }}
                        />
                    </div>
                    <div className="city">
                        <div className="input-control">
                            <TextField
                                label={t('ZIP CODE')}
                                variant='filled'
                                name='postal_code'
                                value={company.postal_code}
                                style={{ width: '100%' }}
                                error={postalCodeError}
                                onChange={(e: any) => {
                                    handleChange(e, 'postal_code');
                                    e.target.value ? setPostalCodeError(!digitOnly.test(e.target.value)) : setPostalCodeError(false);
                                }}
                            />
                        </div>
                        <div className="input-control">
                            <TextField
                                label={t('city')}
                                variant='filled'
                                name='city'
                                value={company.city}
                                error={cityError}
                                style={{ width: '100%' }}
                                onChange={(e: any) => {
                                    handleChange(e, 'city');
                                    e.target.value ? setCityError(!stringRegex.test(e.target.value)) : setCityError(false);
                                }}
                            />
                        </div>
                    </div>
                    <div className="input-control">
                        <TextField
                            label={t('email')}
                            variant='filled'
                            name='email'
                            value={company.email}
                            style={{ width: '100%' }}
                            error={emailError}
                            onChange={(e: any) => {
                                handleChange(e, 'email');
                                e.target.value ? setEmailError(!emailRegex.test(e.target.value)) : setEmailError(false);
                            }}
                        />
                    </div>
                </div>
                <div className="action">
                    <Button
                        variant="contained"
                        color="primary"
                        className="btn-primary-theme btn-save"
                        onClick={submit}
                        disabled={
                            nameError ||
                            telephoneError ||
                            addressError ||
                            postalCodeError ||
                            cityError ||
                            emailError ||
                            saving ||
                            !formChanged ||
                            companyName.length === 0
                        }>
                        {t("update company")}
                    </Button>
                </div>
            </div>
        </>
    )
}