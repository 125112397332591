export const DefaultCategories = [
  {
    name: "Procurement",
    translation: "Auftragsvergabe",
    slug: "procurement",
    prefix: "OP",
    key: 'PROC',
    src: "betrug.svg",
    activeSrc: "betrug_active.svg",
  },
  { // this Category changed from fraud to Corruption
    name: "Corruption",
    translation: "Korruption",
    slug: "fraud",
    prefix: "CU",
    key: 'CORR',
    src: "marktmanipulation.svg",
    activeSrc: "marktmanipultaion_active.svg",
  },
  {
    name: "Sexual Harassment",
    translation: "Sexuelle Belästigung",
    slug: "sexual_harassment",
    prefix: "SX",
    key: 'SEXU',
    src: "sexuelleBelaestigung.svg",
    activeSrc: "sexuelleBelaestigung_active.svg",
  },
  { // this Category changed from mobbing to Discrimination
    name: "Discrimination",
    translation: "Diskriminierung",
    slug: "mobbing",
    prefix: "DM",
    key: 'DISC',
    src: "mobbing.svg",
    activeSrc: "mobbing_active.svg",
  },
  { // this Category changed from data_protection to GDPR
    name: "Data Protection",
    translation: "Datenschutz",
    slug: "data_protection",
    prefix: "DS",
    key: 'GDPR',
    src: "datenschutz.svg",
    activeSrc: "datenschutz_active.svg",
  },
  {
    name: "Money Laundering",
    translation: "Geldwäsche",
    slug: "money_laundering",
    prefix: "ML",
    key: 'MONE',
    src: "money-laundering.svg",
    activeSrc: "money-laundering_active.svg",
  },
  {
    name: "Theft",
    translation: "Diebstahl",
    slug: "theft",
    prefix: "DT",
    key: 'THEF',
    src: "diebstahl.svg",
    activeSrc: "diebstahl_active.svg",
  },
  {
    name: "Environmental Protection",
    translation: "Umweltschutz",
    slug: "environmental_protection",
    prefix: "UW",
    key: 'ENVI',
    src: "umweltschutz.svg",
    activeSrc: "umweltschutz_active.svg",
  },
  {
    name: "Process Optimization",
    translation: "Prozessoptimierung",
    slug: "process_optimization",
    prefix: "PO",
    key: 'PROS',
    src: "prozessoptimierung.svg",
    activeSrc: "prozessoptimierung_active.svg",
  },
  {
    name: "Security Gaps",
    translation: "Sicherheitslücke",
    slug: "security",
    prefix: "SG",
    key: 'SECU',
    src: "sicherheitsluecke.svg",
    activeSrc: "sicherheitsluecke_active.svg",
  },
  {
    name: "Improvement Suggestion",
    translation: "Verbesserungs-vorschlag",
    slug: "improvement",
    prefix: "VB",
    key: 'IMPR',
    src: "verbesserungsvorschlag.svg",
    activeSrc: "verbesserungsvorschlag_active.svg",
  },
  {
    name: "Miscellaneous",
    translation: "Sonstiges",
    slug: "miscellaneous",
    prefix: "MU",
    key: 'MISC',
    src: "patientenschutz.svg",
    activeSrc: "patientenschutz_active.svg",
  }
];

