export const amountsValues: any[] = [
  {
    value: "< 1.000 €",
    max: 1000,
  },
  {
    value: "1.000 - 10.000 €",
    max: 10000,
  },
  {
    value: "10.000 - 25.000 €",
    max: 25000,
  },
  {
    value: "25.000 - 100.000 €",
    max: 100000,
  },
  {
    value: "> 100.000 €",
    max: 100000,
  },
];
