export default (state: any, action: any) => {
  switch (action.type) {
    case "SET_CASE":
      return {
        ...state,
        caseDetails: action.payload.caseDetails,
      };
    case "SET_CASE_DUE_DATE":
      return {
        ...state,
        caseDueDate: action.payload.caseDueDate,
      };
    case "SET_ACTIVE_TAB":
      return {
        ...state,
        activeTab: action.payload.activeTab,
      };
    case "SET_AI_ACTIVATION":
      return {
        ...state,
        aiActivation: action.payload.aiActivation,
      };
    case "SET_ACTIVATION_WORKFLOW_AI":
      return {
        ...state,
        activationWorkflowAI: action.payload.activationWorkflowAI,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };
    case "SET_COMMUNICATIONS_LOADING":
      return {
        ...state,
        communicationsLoading: action.payload.communicationsLoading,
      };
    case "SET_WORKFLOW_LOADING":
      return {
        ...state,
        workflowLoading: action.payload.workflowLoading,
      };
    case "SET_CASE_USERS":
      return {
        ...state,
        caseUsers: action.payload.caseUsers,
      };
    case "SET_CASE_STATUSES":
      return {
        ...state,
        caseStatuses: action.payload.caseStatuses,
      };
    case "SET_CASE_AMOUNTS_OF_DAMAGE":
      return {
        ...state,
        caseAmounts: action.payload.caseAmounts,
      };
    case "SET_CASE_Categories":
      return {
        ...state,
        caseCategories: action.payload.caseCategories,
      };
    case "SET_LANDS":
      return {
        ...state,
        lands: action.payload.lands,
      };
    case "SET_BRANCHES":
      return {
        ...state,
        branches: action.payload.branches,
      };
    case "SET_CASE_RELATIONS":
      return {
        ...state,
        caseRelations: action.payload.caseRelations,
      };
    case "SET_CASE_COMMENTS":
      return {
        ...state,
        caseComments: action.payload.caseComments,
      };

    case "SET_CASE_QUESTIONS":
      return {
        ...state,
        caseQuestions: action.payload.caseQuestions,
      };
    case "SET_CASE_TOTAL_QUESTIONS":
      return {
        ...state,
        caseTotalQuestions: action.payload.totalQuestions,
      };
    case "SET_CASE_TOTAL_ANSWERED_QUESTIONS":
      return {
        ...state,
        caseTotalAnsweredQuestions: action.payload.totalAnsweredQuestions,
      };
    case "SET_CASE_FILES":
      return {
        ...state,
        caseFiles: action.payload.caseFiles,
      };
    case "SET_FILES_LOADING":
      return {
        ...state,
        filesLoading: action.payload.filesLoading,
      };
    case "SET_CASE_NOTES":
      return {
        ...state,
        caseNotes: action.payload.caseNotes,
      };
    case "SET_NOTES_LOADING":
      return {
        ...state,
        notesLoading: action.payload.notesLoading,
      };
    case "SET_CASE_DETAILS_UPDATED":
      return {
        ...state,
        caseDetailsUpdated: action.payload.caseDetailsUpdated,
      };
    default:
      return state;
  }
};
