import axios from "axios";
import { config } from "../../config";
import { showToastMsg } from "../../helpers";
import { LocalStorageService } from "../local-storage/local-storage";

export class SettingsService {
  localStorageService: LocalStorageService = new LocalStorageService();

  /*********** Static Values ***********/

  public getDefaultPassword = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(
        serverUrl + "settings/get-default-password"
      );
      return response.data;
    } catch (err) {
      return {};
    }
  };

  public setDefaultPassword = async (newPassword: string): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.put(
        serverUrl + "settings/change-default-password",
        { newPassword }
      );
      return response.data;
    } catch (err) {
      return {};
    }
  };

  public getContactInfo = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "settings/contact-info");
      return response.data;
    } catch (error) {
      return {};
    }
  };

  /*********** Translation Management ***********/

  public getTranslationJson = async (key: string): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + `settings/translation-json/${key}`);
      return response.data;
    } catch (err) {
      return [];
    }
  }

  public updateTranslation = async (data: any, key: string): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.put(serverUrl + `settings/${key === 'categories' ? 'update-categories-translation' : 'update-translation'}/${key}`, data);
      return response.data;
    } catch (err: any) {
      return err.errors[0].message;
    }
  }

  /*********** Workspace Management ***********/

  public getWorkspaceInfo = async (): Promise<any> => {
    try {
      const tenantId = this.localStorageService.getData("tenantId");
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + `tenants/${tenantId}`);
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public updateWorkspaceInfo = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.put(serverUrl + `tenants`, data);
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public updateCompanyInfo = async (id: any, data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.put(serverUrl + `companies/${id}`, data);
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public getCompanyInfo = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + `companies`);
      return response.data;
    } catch (error) {
      return {};
    }
  };

  /*********** Category Management ***********/

  public listCategories = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "case-categories");
      return response.data;
    } catch (error) {
      return [];
    }
  };

  public getUserCategories = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "case-categories/user-categories");
      return response.data;
    } catch (error) {
      return [];
    }
  };

  public getMainProcessors = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "case-categories/main-processors");
      return response.data;
    } catch (error) {
      return [];
    }
  };

  public searchUsers = async (name: string): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "case-categories/search-users", { params: { name } });
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public isCategoryAvailable = async (id: string): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(`${serverUrl}case-categories/${id}/is-empty`);
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public assignCategories = async (categories: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(serverUrl + "case-categories/assign", { categories });
      return response.data;
    } catch (error) {
      return {};
    }
  };

  /*********** Branches Management ***********/
  public listAllBranches = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "branches/all");
      return response.data;
    } catch (error) {
      return { data: [] };
    }
  };

  public updateBranches = async (branches: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.put(serverUrl + "branches", branches);
      return response.data;
    } catch (error) {
      return { data: [] };
    }
  };

  public addBranches = async (branches: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(serverUrl + "branches", branches);
      return response.data;
    } catch (error) {
      return { data: [] };
    }
  };

  /*********** Super Users (Coordinators) Management ***********/
  public listCoordinators = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "users/coordinators");
      return response.data;
    } catch (error) {
      return { data: [] };
    }
  };

  public searchSuperUsers = async (name: string): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "users/filter-super-users", { params: { name } });
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public assignCoordinators = async (usersIds: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.put(serverUrl + "users/assign-coordinators", usersIds);
      return response.data;
    } catch (error: any) {
      showToastMsg("error", error.message);
    }
  };

  /*********** Reporter Configuration Management ***********/
  public getReporterTranslation = async (key: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + `settings/reporter-translation/${key}`);
      return response.data;
    } catch (error) {
      return { data: [] };
    }
  };

  public updateReporterTranslation = async (key: any, data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.put(serverUrl + `settings/reporter-translation/${key}`, data);
      return response.data;
    } catch (error) {
      return { data: [] };
    }
  };

  public getReporterConfiguration = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + `settings/reporter-configuration`);
      return response.data;
    } catch (error) {
      return { data: [] };
    }
  };

  public updateReporterConfiguration = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.put(serverUrl + `settings/reporter-configuration`, data);
      return response.data;
    } catch (error) {
      return { data: [] };
    }
  };

  public uploadReporterCover = async (form: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "settings/reporter-image/upload",
        form
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public getPhrases = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "settings/phrases");
      return response.data;
    } catch (err) {
      return [];
    }
  };

  public updatePhrases = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.put(serverUrl + "settings/phrases", data);
      return response.data;
    } catch (err: any) {
      return err.errors[0].message;
    }
  };

  /*********** Logs Management ***********/

  public getLogs = async (params: string): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + `settings/logs`, { params });
      return response.data;
    } catch (err) {
      return [];
    }
  }

  public addLog = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(serverUrl + `settings/logs`, data);
      return response.data;
    } catch (err: any) {
      return err.errors[0].message;
    }
  }

}
