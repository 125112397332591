import "./edit-noticeInfo.scoped.scss";
import React, { useState, useContext } from "react";
import { useTranslation, Trans } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
import { CasesService } from "../../services/cases/cases.service";
import { CaseContext } from "../../store";
import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";
import { icons } from "../../statics/icons";
import "moment/locale/de";
import InfoQuestion from "../../components/info-question/Info-question.component";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import ChipInput from "material-ui-chip-input";
import moment from "moment";
import LastUpdatedAt from "../../components/last-updated-at/last-updated.component";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MapperService } from "../../services/mapper/mapper.service";
import { LangService } from "../../services/lang/lang.service";
import { InputViewComponent } from "../../components/input-view/input-view.component";

const EditNoticeInfo = (props: any) => {
  const removeEmojiRegex = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;
  const [t] = useTranslation();
  const casesService = new CasesService();
  const langService: LangService = new LangService();
  const mapperService = new MapperService();
  const locale = langService.getCurrentLang();
  moment.locale(locale);

  const {
    caseDetails,
    setLoading,
    setCase,
    caseStatuses,
    lands,
    branches,
    caseCategories,
    setCaseUsers,
  } = useContext(CaseContext);

  const [saving, setSaving] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [labelsChanged, setLabelsChanged] = useState(false);
  const [formData, setFormData] = useState({
    labels: caseDetails.labels,
    categoryId: caseDetails.categoryId,
    landId: caseDetails.landId,
    branchId: caseDetails.branchId
  });

  const { categoryId, landId, branchId, labels } = formData;

  const handleClose = (value: any) => {
    props.onClose(value);
    setFormChanged(false);
    if (!value) {
      console.log(value);
      setFormData({
        labels: caseDetails.labels,
        categoryId: caseDetails.categoryId,
        landId: caseDetails.landId,
        branchId: caseDetails.branchId
      });
    }
  };

  async function fetchCaseDetails(id: string) {
    setLoading(true);
    const caseDetailsData = await casesService.details(id);
    const { mappedCase } = mapperService.mapCase(caseDetailsData, caseStatuses);
    setCase(mappedCase);
    setLoading(false);
  }

  const formatRequestBody = (...args: any): any => {
    const body: any = {};
    for (let index = 0; index < args.length; index++) {
      const item = args[index];
      if (item.value !== caseDetails[item.name]) {
        body[item.name] = item.value;
      }
    }
    return body;
  };

  const getUsers = async (id: string) => {
    const usersResult = await casesService.getUsers(id);
    setCaseUsers(usersResult);
  };

  const handleUpdate = async () => {
    setSaving(true);
    const bodyObject = formatRequestBody(
      { name: "categoryId", value: categoryId },
      { name: "landId", value: landId },
      { name: "branchId", value: branchId }
    );
    const data = { ...bodyObject, labels, labelsChanged };
    await casesService.updateNoticeInfo(caseDetails.id, data);
    setSaving(false);
    if (caseCategories.categoryId !== formData.categoryId) {
      getUsers(caseDetails.id);
    }
    fetchCaseDetails(caseDetails.id);
    handleClose(null);
  };

  const onChangeHandler = (e: any) => {
    setFormChanged(true);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleAddLabelClick = (chip: string) => {
    if (chip.length > 50) return;
    const label = handleChipInput(chip);
    if (!label) return;
    const newLabelsCopy = labels.length ? [...labels] : [];
    newLabelsCopy.push(label);
    setFormData({ ...formData, labels: newLabelsCopy });
  }

  const handleLabelDelete = (chip: string) => {
    setFormChanged(true);
    setLabelsChanged(true);
    const newOriginalLabels = labels.filter((item: string) => item !== chip);
    setFormData({ ...formData, labels: newOriginalLabels });
  }

  const handleChipInput = (value: string) => {
    setFormChanged(true);
    setLabelsChanged(true);
    let text = value.replace(removeEmojiRegex, '');
    if (text[0] === ',' || text[0] === ' ') text = text.substring(1);
    if (text[text.length - 1] === ',' || text[text.length - 1] === ' ') text = text.slice(0, -1);
    if ((text[0] === ',' && text[1] === ' ') || (text[0] === ' ' && text[1] === ',')) text = text.substring(2);
    if ((text[text.length - 1] === ',' && text[text.length - 2] === ' ') || (text[text.length - 1] === ' ' && text[text.length - 2] === ',')) text = text.slice(0, -2);
    text = text.replace(',,', ',');
    text = text.replace(', ', ',');
    text = text.replace(' ,', ',');
    if (text === ',' || text === '' || text === ' ') return;
    return text;
  }

  return (
    <Modal
      show={props.show}
      onHide={handleClose.bind(null, false)}
      centered
      dialogClassName="modal-xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans
            i18nKey="edit_case_notice_info"
            values={{ internalId: caseDetails.internalId }}
          ></Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <div className="row">
            <div className="col" />
            <div className="col main-text mb-2">{t("original input")}</div>
            <div className="col main-text mb-2">{t("current input")}</div>
          </div>

          {/* category section */}
          <div className="row mb-3 ">
            <InfoQuestion
              icon={icons.category}
              label={t("category")}
              question={t("Which category does the case belong to?")}
            />

            <div className="col">
              <div className="mb-2">
                <InputViewComponent
                  value={caseDetails.originalCategory}
                  label={t("category")}
                  icon={icons.chevronDown}
                  type="select"
                />
              </div>
            </div>

            <div className="col">
              <FormControl className="select-input select-grey w-100">
                <InputLabel id="category">{t("category")}</InputLabel>
                <Select
                  labelId="category"
                  id="category-select"
                  name="categoryId"
                  value={categoryId ? categoryId : null}
                  onChange={onChangeHandler}
                  IconComponent={ExpandMoreIcon}
                >
                  {caseCategories.map((item: any, index: any) => {
                    if (item.value === null) {
                      return (
                        <MenuItem value={item.id} key={index}>
                          0
                        </MenuItem>
                      );
                    }
                    return (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <LastUpdatedAt
                mainProcessor={caseDetails.categoryUpdateAdmin}
                updatedAt={caseDetails.categoryUpdatedAt}
              />
            </div>
          </div>

          {/* Lands section */}
          <div className="row mb-3">
            <InfoQuestion
              icon={icons.country}
              label={t("country")}
              question={t("In which country did the incident take place?")}
            />

            <div className="col">
              <div className="mb-2">
                <InputViewComponent
                  value={caseDetails.originalLand}
                  label={t("country")}
                  icon={icons.chevronDown}
                  type="select"
                />
              </div>
            </div>
            <div className="col">
              <FormControl className="select-input select-grey w-100">
                <InputLabel id="land">{t("country")}</InputLabel>
                <Select
                  labelId="land"
                  id="land-select"
                  name="landId"
                  value={landId}
                  onChange={onChangeHandler}
                  IconComponent={ExpandMoreIcon}
                >
                  {lands.map((item: any, index: any) => {
                    return (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <LastUpdatedAt
                mainProcessor={caseDetails.landUpdateAdmin}
                updatedAt={caseDetails.landUpdatedAt}
              />
            </div>
          </div>

          {/* branch section */}
          <div className="row mb-3">
            <InfoQuestion
              icon={icons.company}
              label={t("affected company")}
              question={t("what is the affected company?")}
            />

            <div className="col">
              <div className="mb-2">
                <InputViewComponent
                  value={caseDetails.originalBranch}
                  label={t("affected company")}
                  icon={icons.chevronDown}
                  type="select"
                />
              </div>
            </div>
            <div className="col">
              <FormControl className="select-input select-grey w-100">
                <InputLabel id="branch">{t("affected company")}</InputLabel>
                <Select
                  labelId="branch"
                  id="branch-select"
                  name="branchId"
                  value={branchId}
                  onChange={onChangeHandler}
                  IconComponent={ExpandMoreIcon}>

                  {branches.map((item: any, index: any) => {
                    return (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* labels section */}
          <div className="row mb-1">
            <InfoQuestion
              icon={icons.labels}
              label={t("Labels")}
              question={t("To describe the incident")} />
            <div className="col" />
            <div className="col opt-info-field rel-wrap chip-input-field">
              <ChipInput
                value={labels.length ? labels : []}
                onAdd={(chip) => handleAddLabelClick(chip)}
                onDelete={(chip) => handleLabelDelete(chip)}
                className="w-100 chip-input-field"
                label={t("labels")}
                variant="filled"
              />

              <LastUpdatedAt
                mainProcessor={caseDetails.labelsUpdateAdmin}
                updatedAt={caseDetails.labelsUpdatedAt}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outlined"
          color="primary"
          className="btn-secondary-theme"
          onClick={handleClose.bind(null, false)}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="btn-primary-theme"
          onClick={handleUpdate}
          disabled={!formChanged}
        >
          {saving ? (
            <CircularProgress color="inherit" />
          ) : (
            <>
              <CheckIcon /> {t("save")}{" "}
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditNoticeInfo;
