import { TableColumn } from "../types/table-column";

export const newCasesColumns: TableColumn[] = [
  // {
  //   type: 'client',
  //   value: 'client',
  //   fieldName: 'client',
  //   secondaryValue: 'branch',
  //   width: "23%"
  // },
  {
    type: "string",
    value: "case number",
    fieldName: "internalId",
    width: "26%",
  },
  {
    type: "string",
    value: "category",
    fieldName: "category",
    width: "23%",
  },
  {
    type: "string",
    value: "status",
    fieldName: "status",
    width: "26%",
  },
  {
    type: "date",
    value: "date",
    fieldName: "created_at",
    width: "20%",
  },
];
