import { makeStyles } from "@material-ui/core";

export default makeStyles({
  circleIconWrapperProps: {
    border: " 1.5px solid rgb(211, 214, 195)",
    height: "32px",
    width: "32px",
    fontSize: "1.25rem",
    fontWeight: 400,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(211, 214, 195)",
  },
  active: {
    color: "rgb(255, 255, 255)",
    border: "1.5px solid rgb(255, 255, 255)",
  },
});
