import React, { useState } from "react";
import "./timeline-statuses.component.scoped.scss";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { formatDate } from "../../helpers";
import Skeleton from "@material-ui/lab/Skeleton";

export const TimelineStatusesComponent = (props: any) => {
  const [expand, setExpand] = useState(props.expand);
  const handelExpand = (value: boolean) => {
    setExpand(value);
    props.handleExpand(value);
  };

  return (
    <div className="timeline-item">
      <div className="timeline-badge">
        {props.loading ? (
          <Skeleton variant="circle" height={30} width={30} />
        ) : (
          <div
            className={
              props.activeStatus && !props.lastStatus
                ? "bg-grey-dark"
                : props.dimmed
                ? "bg-grey-light"
                : "bg-green-light"
            }
          >
            <img src={props.icon} alt="" />
          </div>
        )}
      </div>
      <div
        className={`timeline-item-content ${
          (!props.activeStatus && !props.dimmed && !props.loading && !expand) ||
          props.lastStatus
            ? "status-completed"
            : ""
        }${
          props.activeStatus && !props.lastStatus && !props.loading && !expand
            ? "status-active"
            : ""
        } ${!expand ? "status-folded" : ""}`}
      >
        <div className={"timeline-status " + (props.dimmed ? "dimmed" : "")}>
          <div className="status-info">
            <div className="d-flex">
              {props.loading ? (
                <Skeleton variant="text" height={25} width={150} />
              ) : (
                <>
                  <div className="status-name">{props.status}</div>
                  {props.statusCount && (props.total || !props.dimmed) && (
                    <div className="status-count">{props.statusCount}</div>
                  )}
                </>
              )}
            </div>
            {props.loading && (
              <Skeleton variant="text" height={25} width={100} />
            )}
            {!props.loading && props.date && (
              <div className="status-date">
                {formatDate(props.date, "dddd, DD.MM.YYYY")}
              </div>
            )}
          </div>
          {!props.loading &&
            expand &&
            ((props.tab === "workflow" && !props.lastStatus) ||
              (props.tab === "files" && props.total !== 0)) && (
              <ExpandLessIcon
                className="expand-less"
                onClick={handelExpand.bind(null, false)}
              />
            )}
          {!props.loading &&
            !expand &&
            ((props.tab === "workflow" && !props.lastStatus) ||
              (props.tab === "files" && props.total !== 0)) && (
              <ExpandMoreIcon
                className="expand-more"
                onClick={handelExpand.bind(null, true)}
              />
            )}
        </div>
        {props.children}
      </div>
    </div>
  );
};
