import React from "react";
import { useTranslation } from "react-i18next";
import SettingsHead from "../../components/settings-head/settings-head.component";
import { WorkflowManagementContent } from "../../components/workflow-management-content/workflow-management-content.component";
import { CommonService } from "../../services/common/common.service";

export default function WorkflowManagementPage() {
    const [t] = useTranslation();
    const commonService: CommonService = new CommonService();
    commonService.setTitle(t("workflow management"));

    return (
        <>
            <div className="role-contain">
                <div className="content">
                    <SettingsHead title={t('workflow management')} subTitle={t('organize your workflows here')} />
                    <WorkflowManagementContent />
                </div>
            </div>
        </>
    );
}