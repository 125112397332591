import React from "react";
import { useTranslation } from "react-i18next";
import { LogfileContent } from "../../components/logfile-content/logfile.component";
import SettingsHead from "../../components/settings-head/settings-head.component";

import { CommonService } from "../../services/common/common.service";
import { LogsProvider } from "../../store";

export default function LogfilePage() {
    const [t] = useTranslation();
    const commonService: CommonService = new CommonService();
    commonService.setTitle(t("logfile"));

    return (
        <LogsProvider>
            <div className="role-contain">
                <div className="content">
                    <SettingsHead title={t('logfile')} subTitle={t('logfile description')} />
                    <LogfileContent />
                </div>
            </div>
        </ LogsProvider>
    );
}