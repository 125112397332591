import "./sorter.component.scoped.sass";
import React from "react";
import { SorterProps } from "../../../types/sorter-props";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export const SorterComponent = (props: SorterProps) => {
  const { sort, direction, sortColumn, fieldName } = props;
  const doSort = () => {
    let sortingDirection,
      sortingColumn = sortColumn;
    if (sortColumn === fieldName && direction === "asc") {
      sortingDirection = "desc";
    } else if (sortColumn === fieldName && direction === "desc") {
      sortingDirection = "asc";
    } else {
      sortingDirection = "asc";
      sortingColumn = fieldName;
    }
    return sort(sortingColumn, sortingDirection);
  };
  return (
    <div onClick={doSort} className="sorter">
      <ExpandLessIcon
        className={
          direction === "asc" && sortColumn === fieldName ? "active" : ""
        }
        style={{ fontSize: 13, color: "rgb(74, 74, 74)" }}
      />
      <ExpandMoreIcon
        className={
          "down" +
          (direction === "desc" && sortColumn === fieldName ? " active" : "")
        }
        style={{ fontSize: 13, color: "rgb(74, 74, 74)" }}
      />
    </div>
  );
};
