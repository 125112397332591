import React, { useEffect, useState } from "react";
import { RouterGuard } from "react-router-guard";
import router from "./router";
import "./app.component.scoped.scss";
import { GlobalProvider } from "./store";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DashboardService } from "./services/dashboard/dashboard.service";
import { ServerMessage } from "./components/server-message/server-message.component";

function App() {
  const dashboardService: DashboardService = new DashboardService();

  const empObj: any = {};
  const [configuration, setConfiguration] = useState(empObj);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#f2af1d",
        light: "#f6c761",
        dark: "#f0a81a",
        contrastText: '#fff',
      },
    },
    overrides: {
      MuiInputBase: {
        input: {
          "&::placeholder": {
            color: "rgb(128, 128, 128)",
            opacity: 1
          },
        }
      }
    }
  });


  const getConfiguration = async () => {
    const response = await dashboardService.getConfiguration();
    setConfiguration(response);
  }

  const close = () => {
    setConfiguration({ ...configuration, system_availability: 0 })
  }

  useEffect(() => {
    getConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GlobalProvider>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ThemeProvider theme={theme}>
          <div className="main-wrapper">
            {configuration && !!+configuration.system_availability && <ServerMessage close={close} />}
            <RouterGuard config={router} />
          </div>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </GlobalProvider>
  );
}

export default App;
