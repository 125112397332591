import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import CategoriesContent from "../../components/categories-content/categories-content.component";
import CoordinatorsContent from "../../components/coordinators-content/coordinators-content.component";
import SettingsHead from "../../components/settings-head/settings-head.component";
import { CommonService } from "../../services/common/common.service";
import { GlobalContext } from "../../store";

export default function RolesManagementPage(props: any) {
  const [t] = useTranslation();
  const commonService: CommonService = new CommonService();
  const { user } = useContext(GlobalContext);
  commonService.setTitle(t("role management"));


  return (
    <>
      <div className="role-contain">
        <div className="content">
          <SettingsHead title={t('role management')} subTitle={t('organize the permissions of your users here')} />
          {(user.type === 'superAdmin' || (user.type === 'superUser' && user.role === 'coordinator')) && <CategoriesContent />}
          {(user.type === 'superAdmin' || (user.type === 'superUser' && user.role === 'coordinator')) && <CoordinatorsContent />}
        </div>
      </div>
    </>
  );
}
