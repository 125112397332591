import "./Info-question.component.scoped.scss"
import React from 'react'

const InfoQuestion = (props: any) => {
    return (
    <div className="col info-question">
        <img src={props.icon} alt="" />

        <div className="">
            <p className="main-text">{props.label}</p>
            <p className="main-question">{props.question}</p>
        </div>
    </div>
    )
}

export default InfoQuestion
