import "./activate-AI.scoped.scss";
import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import CheckIcon from "@material-ui/icons/Check";
import { icons } from "../../statics/icons";
import { AIService } from "../../services/ai/ai.services";
import { CaseContext } from "../../store";
import { showToastMsg } from "../../helpers";

export const ActivateAiModal = (props: any) => {
    const [t] = useTranslation();
    const { aiType, onClose, dialogTitle, dialogBody, show } = props;
    const handleClose = (value: any) => onClose(value);

    const aiServices = new AIService();
    const { caseDetails } = useContext(CaseContext);
    const [AIInProgress, setAIInprogress] = useState(false);
    const [AIActivated, setAIActivated] = useState(false);
    const [AIActivatedWorkflow, setAIActivatedWorkflow] = useState(false);

    const activateAI = async () => {
        if (AIActivated || AIActivatedWorkflow) {
            handleClose(aiType === 'description' ? AIActivated : (aiType === 'workflow' ? AIActivatedWorkflow : false));
        } else {
            setAIInprogress(true);
            if (aiType === 'description') {
                const predictResponse = await aiServices.activateAI(true, caseDetails.id, caseDetails.description);
                if (predictResponse.message === 'Success') {
                    setAIActivated(true);
                    setAIInprogress(false);
                } else {
                    setAIInprogress(false);
                    showToastMsg("error", `Predict Failure with Task_Id: ${predictResponse.task_id}`);
                }
            } else if (aiType === 'workflow') {
                await aiServices.activateWorkflowAI(caseDetails.id);
                setTimeout(() => {
                    setAIActivatedWorkflow(true);
                    setAIInprogress(false);
                }, 5000);
            }
        }
    }

    return (
        <>
            <Modal onHide={handleClose.bind(null, AIActivated ? 'description' : AIActivatedWorkflow ? 'workflow' : false)}
                show={show} centered dialogClassName={"modal-lg"}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="content text-center">
                        {
                            AIActivated ? <img src={icons.AIActivated} alt="Activate-AI" /> :
                                AIInProgress ? <img src={icons.AIInProgress} alt="Activate-AI" /> :
                                    <img src={icons.activateAI} alt="Activate-AI" />
                        }
                        <div className="info mt-4">
                            <h5>{dialogTitle}</h5>
                            <p>{dialogBody}</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'center' }} >
                    <Button
                        onClick={activateAI}
                        className={"btn-primary-theme btn-confirm " + (AIActivated ? 'my-4' : '')}
                        variant="contained"
                        color="primary"
                        disabled={AIInProgress}>
                        <CheckIcon className="mr-2" />
                        {AIActivated || AIActivatedWorkflow ? t('finished') : t('lets go')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
