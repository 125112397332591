import { FormControlLabel } from "@material-ui/core";
import "./workflow-table-row.component.scoped.scss";
import React, { useContext, useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { LangService } from "../../services/lang/lang.service";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import { icons } from "../../statics/icons";
import WorkflowComment from "../workflow-comment/workflow-comment.component";
import WorkflowAddComment from "../workflow-add-comment/workflow-add-comment.component";
import { useTranslation } from "react-i18next";
import moment, { Moment } from "moment";
import "moment/locale/de";
import MomentUtils from "@date-io/moment";
import { Comment } from "../../types/comment";
import { Skeleton } from "@material-ui/lab";
import { CasesService } from "../../services/cases/cases.service";
import { CaseContext, GlobalContext } from "../../store";
import { MapperService } from "../../services/mapper/mapper.service";

const WorkflowTableRow = (props: any) => {
  const langService: LangService = new LangService();
  const casesService: CasesService = new CasesService();
  const mapperService: MapperService = new MapperService();
  const [t] = useTranslation();

  const { user } = useContext(GlobalContext);

  const {
    workflowLoading,
    caseDetails,
    completedStatusId,
    setCase,
    setCaseQuestions,
    setCaseTotalQuestions,
    setCaseTotalAnsweredQuestions,
    setWorkflowLoading,
  } = useContext(CaseContext);

  const [neinChecked, setNeinChecked] = useState(props.answer === 0 ? true : false);
  const [jaChecked, setJaChecked] = useState(props.answer === 1 ? true : false);
  const [NAChecked, setNAChecked] = useState(props.answer === 2 ? true : false);

  const [jaDisabled, setJaDisabled] = useState(props.answer === 0 || props.answer === 2 ? true : false);
  const [neinDisabled, setneinDisabled] = useState(props.answer === 1 || props.answer === 2 ? true : false);
  const [NADisabled, setNADisabled] = useState(props.answer === 0 || props.answer === 1 ? true : false);

  const [date, setDate] = useState(props.dueDate ? moment(props.dueDate) : null);

  const [openComments, setOpenComments] = useState(false);
  const [comments, setComments] = useState(props.comments);
  const [questionAnswered, setQuestionAnswered] =
    useState(props.answer === 0 || props.answer === 1 || props.answer === 2 ? true : false);

  const locale = langService.getCurrentLang();
  moment.locale(locale);

  async function fetchCaseDetails(id: string) {
    const caseDetailsData = await casesService.details(id);
    const mappedCase = { ...caseDetails, ...caseDetailsData };
    setCase(mappedCase);
  }

  async function fetchCaseQuestions(id: string, updateAnswers = false) {
    setWorkflowLoading(true);
    const caseQuestions = await casesService.caseQuestions(id);
    if (updateAnswers && caseDetails.statusId !== completedStatusId) {
      const {
        totalQuestions,
        totalAnsweredQuestions,
      } = mapperService.mapCaseQuestions(caseQuestions);
      setCaseTotalQuestions(totalQuestions);
      setCaseTotalAnsweredQuestions(totalAnsweredQuestions);
    }
    setTimeout(() => {
      setCaseQuestions(caseQuestions);
      setWorkflowLoading(false);
    }, 100);
  }

  const handleOpenComments = () => {
    setOpenComments(!openComments);
  };

  const handleNeinChange = () => {
    updateAnswer(!neinChecked ? 0 : null);
    setNeinChecked(!neinChecked);
    setJaDisabled(!jaDisabled);
    setNADisabled(!NADisabled);
  };

  const handleJaChange = () => {
    updateAnswer(!jaChecked ? 1 : null);
    setJaChecked(!jaChecked);
    setneinDisabled(!neinDisabled);
    setNADisabled(!NADisabled);
  };

  const handleNAChange = () => {
    updateAnswer(!NAChecked ? 2 : null);
    setNAChecked(!NAChecked);
    setJaDisabled(!jaDisabled);
    setneinDisabled(!neinDisabled);
  };

  const updateAnswer = async (answer: any) => {
    await casesService.answerQuestion(props.id, answer);
    fetchCaseQuestions(caseDetails.id, true);
    fetchCaseDetails(caseDetails.id);
  };

  useEffect(() => {
    if (jaChecked) {
      setneinDisabled(true);
      setNADisabled(true);
      setQuestionAnswered(true);
    } else if (neinChecked) {
      setJaDisabled(true);
      setNADisabled(true);
      setQuestionAnswered(true);
    } else if (NAChecked) {
      setJaDisabled(true);
      setneinDisabled(true);
      setQuestionAnswered(true);
    }
    if (!jaChecked && !neinChecked && !NAChecked) {
      setQuestionAnswered(false);
    }
  }, [jaChecked, neinChecked, NAChecked]);

  const handleDueDateChange = async (date: Moment) => {
    setDate(date);
    if (date === null || (date && date.isValid())) {
      await casesService.changeQuestionDueDate(
        props.id,
        date ? date.format("YYYY-MM-DD") : null
      );
      fetchCaseQuestions(caseDetails.id);
      setTimeout(() => {
        fetchCaseDetails(caseDetails.id);
      }, 100);
    }
  };

  const handleUpdateComments = (comment: string) => {
    const commentsCopy = [...comments];
    commentsCopy.push(comment);
    setComments(commentsCopy);
  };

  const handleDeleteComment = (index: number) => {
    const commentsCopy = [...comments];
    commentsCopy.splice(index, 1);
    // empty the comments first for rerender again.
    setComments([]);
    setComments(commentsCopy);
  };

  return (
    <div className="question-wrapper">
      <div className="d-flex align-items-center justify-content-between px-3 py-2">
        <div className="task-section">
          <div className=" d-flex justify-content-start align-items-center">
            {props.loading ? (
              <Skeleton
                variant="rect"
                height={20}
                width={20}
                style={{ marginRight: "15px" }}
              />
            ) : (
              <FormControlLabel
                className="checkbox-question-global"
                control={
                  <Checkbox
                    checked={questionAnswered}
                    disabled={true}
                    name="questionAnswered"
                    color="secondary"
                    size="small"
                  />
                }
                label=""
              />
            )}
            {props.loading ? (
              <Skeleton variant="text" height={37} width={365} />
            ) : (
              <p>{props.question}</p>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="frist-section">
            {props.loading ? (
              <Skeleton variant="text" height={37} />
            ) : (
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                libInstance={moment}
                locale={locale}
              >
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD/MM/YYYY"
                  autoOk
                  value={date}
                  onChange={(date: any): any => handleDueDateChange(date)}
                  className="date-picker-standard"
                  invalidDateMessage={t("invalid date format")}
                  label={t("dueDate")}
                  keyboardIcon={<EventOutlinedIcon />}
                  disabled={caseDetails.statusId === completedStatusId || user.type === 'auditor'}
                />
              </MuiPickersUtilsProvider>
            )}
          </div>
          <div className="yes-no-section">
            <div className="d-flex justify-content-end align-items-center">
              <div className="checkboxes-wrapper">
                {props.loading ? (
                  <>
                    <Skeleton
                      variant="rect"
                      height={20}
                      width={20}
                      style={{ marginRight: "15px" }}
                    />
                    <Skeleton
                      variant="text"
                      height={37}
                      width={25}
                      style={{ marginRight: "15px" }}
                    />
                  </>
                ) : (
                  <FormControlLabel
                    className="checkbox-global"
                    control={
                      <Checkbox
                        checked={jaChecked}
                        onChange={handleJaChange}
                        name="jaChecked"
                        size="small"
                        disabled={
                          jaDisabled ||
                          caseDetails.statusId === completedStatusId ||
                          user.type === 'auditor'
                        }
                      />
                    }
                    label={t("yes")}
                  />
                )}
                {props.loading ? (
                  <>
                    <Skeleton
                      variant="rect"
                      height={20}
                      width={20}
                      style={{ marginRight: "15px" }}
                    />
                    <Skeleton variant="text" height={37} width={30} />
                  </>
                ) : (
                  <FormControlLabel
                    className="checkbox-global"
                    control={
                      <Checkbox
                        checked={neinChecked}
                        onChange={handleNeinChange}
                        name="neinChecked"
                        size="small"
                        disabled={
                          neinDisabled ||
                          caseDetails.statusId === completedStatusId ||
                          user.type === 'auditor'
                        }
                      />
                    }
                    label={t("No")}
                  />
                )}
                {props.loading ? (
                  <>
                    <Skeleton
                      variant="rect"
                      height={20}
                      width={20}
                      style={{ marginRight: "15px" }}
                    />
                    <Skeleton variant="text" height={37} width={30} />
                  </>
                ) : (
                  <FormControlLabel
                    className="checkbox-global"
                    control={
                      <Checkbox
                        checked={NAChecked}
                        onChange={handleNAChange}
                        name="NAChecked"
                        size="small"
                        disabled={
                          NADisabled ||
                          caseDetails.statusId === completedStatusId ||
                          user.type === 'auditor'
                        }
                      />
                    }
                    label={t("N/A")}
                  />
                )}
              </div>
              {props.loading ? (
                <Skeleton variant="circle" height={30} width={30} />
              ) : (
                <div
                  className={`icon-wrap ${openComments &&
                    (comments.length ||
                      (props.answer === null &&
                        caseDetails.statusId !== completedStatusId))
                    ? "bg-yellow"
                    : ""
                    }`}
                  onClick={handleOpenComments}
                >
                  <img
                    src={
                      openComments &&
                        (comments.length ||
                          (props.answer === null &&
                            caseDetails.statusId !== completedStatusId))
                        ? icons.commentOrange
                        : icons.comment
                    }
                    alt=""
                  />
                  {comments && comments.length ? (
                    <div
                      className={`icon-number ${openComments ? "color-orange" : ""
                        }`}
                    >
                      {comments.length}
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {openComments &&
        (comments.length ||
          (props.answer === null &&
            caseDetails.statusId !== completedStatusId)) ? (
        <div className="p-3">
          <div className="comments-wrapper">
            <div className={comments.length === 0 ? "vis-hidden mr-4" : "mr-4"}>
              {t("comments")}:{" "}
            </div>

            <div className="d-flex flex-column w-100">
              {props.loading ? (
                <>
                  <Skeleton
                    variant="rect"
                    height={20}
                    width={20}
                    style={{ marginRight: "15px" }}
                  />
                  <Skeleton variant="text" height={37} width={30} />
                </>
              ) : (
                comments.map((comment: Comment, index: any) => {
                  return (
                    <WorkflowComment
                      index={props.index}
                      comment={comment.content}
                      commentIndex={index}
                      user={comment.user}
                      date={comment.createdAt}
                      id={comment.id}
                      key={index}
                      loading={workflowLoading}
                      isAuthorizedUser={
                        user.keycloakUserId === caseDetails.mainProcessorId ||
                        user.keycloakUserId === comment.userId ||
                        user.type === "superAdmin" || user.role === 'coordinator'
                      }
                      questionId={props.id}
                      caseQuestionId={props.caseQuestionId}
                      deleteComment={(deletedIndex: number) =>
                        handleDeleteComment(deletedIndex)
                      }
                    />
                  );
                })
              )}
              {props.answer === null &&
                caseDetails.statusId !== completedStatusId ? (
                <WorkflowAddComment
                  questionId={props.id}
                  updateComments={(newComment: string) =>
                    handleUpdateComments(newComment)
                  }
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default WorkflowTableRow;
