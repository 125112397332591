import React from "react";
import { useTranslation } from "react-i18next";
import ReporterConfigurationContent from "./components/reporter-configuration-content/reporter-configuration-content.component";
import SettingsHead from "../../components/settings-head/settings-head.component";
import { CommonService } from "../../services/common/common.service";

export default function ReporterConfigurationPage() {
    const [t] = useTranslation();
    const commonService: CommonService = new CommonService();
    commonService.setTitle(t("reporter page configuration"));

    return (
        <>
            <div className="role-contain">
                <div className="content">
                    <SettingsHead title={t('reporter page configuration')} subTitle={t('configure the reporter page here')} />
                    <ReporterConfigurationContent />
                </div>
            </div>
        </>
    );
}