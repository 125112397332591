export const dummyFiles = [
  {
    status: "EINGELANGT",
    files:
      [
        {
          name: "",
        },
        {
          name: "",
        },
        {
          name: "",
        },
      ]
  },
  {
    status: "IN BEARBEITUNG",
    files:
    [
      {
        name: "",
      },
      {
        name: "",
      },
      {
        name: "",
      },
    ]
  },
  {
    status: "REVIEW",
    files:
    [
      {
        name: "",
      },
      {
        name: "",
      },
      {
        name: "",
      },
    ]
  },
  {
    status: "ABGESCHLOSSEN",
    files: []
  },
];