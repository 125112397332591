import axios from "axios";
import { config } from "../../config";

export class DashboardService {
  public getCounts = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "dashboard/counts");
      return response.data;
    } catch (err) {
      return [];
    }
  };

  public getLatestInternalComments = async (key: string): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + `dashboard/latest-comments/${key}`);
      return response.data;
    } catch (err) {
      return [];
    }
  };

  public getStatusChartData = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(
        serverUrl + `dashboard/charts/status?from=${data.from}&to=${data.to}`
      );
      return response.data;
    } catch (err) {
      return [];
    }
  };

  public getBarChartData = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(
        serverUrl + `dashboard/charts/amounts?from=${data.from}&to=${data.to}`
      );
      return response.data;
    } catch (err) {
      return [];
    }
  };

  public getCategoriesChartData = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(
        serverUrl +
        `dashboard/charts/categories?from=${data.from}&to=${data.to}`
      );
      return response.data;
    } catch (err) {
      return [];
    }
  };

  public getLatestCases = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "dashboard/latest-cases");
      return response.data;
    } catch (err) {
      return [];
    }
  };

  /*********** help aside ***********/

  public getHelpData = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "help");
      return response.data;
    } catch (err) {
      return [];
    }
  };

  /*********** system configuration ***********/

  public getConfiguration = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "configuration");
      return response.data;
    } catch (err) {
      return [];
    }
  };

}
