import React, { useEffect, useState } from 'react';
import './reporter-translation.component.scoped.scss';
import AceEditor from "react-ace";
import { TranslationData } from "../../../../types/translation-data";
import OnboardingCategory from "../../../../components/onboarding-category/onboarding-category.component";
import moment from "moment";
import LoadIcon from "@material-ui/icons/SystemUpdateAltTwoTone";
import { createJson, recordJson, checkJson, downloadJson } from "../../../../statics/translation";
import { reporterTranslationValues } from "../../../../statics/reporterTranslationValues";
import { ConfirmDialog } from "../../../../dialogs/confirm-dialog/confirm-dialog";
import { SettingsService } from "../../../../services/settings/settings.service";
import { showToastMsg } from "../../../../helpers";
import { useTranslation } from 'react-i18next';
import { Button } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

export default function ReporterTranslation() {
    const [t] = useTranslation();
    const settingsService: SettingsService = new SettingsService();

    const [jsonList, setJsonList] = useState('');
    const [originalJsonList, setOriginalJsonList] = useState('');
    const [translationData, setTranslationData] = useState(new TranslationData());
    const [activeKey, setActiveKey] = useState('');
    const [invalidJsonFile, setInvalidJsonFile] = useState(false);
    const [jsonChanged, setJsonChanged] = useState(false);
    const [mouseHover, setMouseHover] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    useEffect(() => {
        setActiveKey(reporterTranslationValues[0].key);
        getReporterTranslation(reporterTranslationValues[0].key);
        // eslint-disable-next-line
    }, [])


    const onChange = (newValue: any) => {
        setJsonChanged(true);
        setJsonList(newValue);
        try {
            JSON.parse(newValue);
            setInvalidJsonFile(false);
        } catch (e) {
            setInvalidJsonFile(true);
        }
    }

    const getReporterTranslation = async (key: string) => {
        const translationJson = await settingsService.getReporterTranslation(key);
        setActiveKey(key);
        setTranslationData(translationJson);
        setJsonList(translationJson.value);
        setOriginalJsonList(translationJson.value);
    }


    /**
    * load reporter translation JSON
    * 
    */
    const loadDefaultTranslation = async (value: any) => {
        setShowConfirmModal(false);
        if (value) {
            let defaultJson;
            if (activeKey === 'create') {
                defaultJson = createJson.value;
            } else if (activeKey === 'record') {
                defaultJson = recordJson.value;
            } else if (activeKey === 'check') {
                defaultJson = checkJson.value;
            } else if (activeKey === 'download') {
                defaultJson = downloadJson.value;
            }
            setJsonList(defaultJson);
            setJsonChanged(true);
        }
    }

    const submit = async () => {
        setJsonChanged(false);
        setErrorMessage('');
        const res = await settingsService.updateReporterTranslation(activeKey, JSON.parse(jsonList));
        if (res && res.message === "Success") {
            showToastMsg("success", t("translation updated successfully"))
            getReporterTranslation(activeKey);
            setOriginalJsonList(jsonList);
        } else {
            setErrorMessage(res);
        }
    }

    const cancel = async () => {
        setJsonList(originalJsonList);
        setJsonChanged(false);
    }

    return (
        <>
            <div className="reporter-translation">
                <div className="section-info">
                    <h3>{t("specify reporter page")}</h3>
                    <p>{t("here you can configure the individual pages of the reporter page")}</p>
                </div>
                <div className="json-content">
                    <div className="items">
                        {
                            reporterTranslationValues.map((item: any, i: any) => {
                                return (
                                    <>
                                        {
                                            <div onMouseEnter={() => setMouseHover(i)}
                                                onMouseLeave={() => setMouseHover(null)} key={i}>
                                                <OnboardingCategory
                                                    name={t(item.name)}
                                                    active={mouseHover === i || activeKey === item.key}
                                                    borderNum={1}
                                                    number={i + 1}
                                                    onclick={() => getReporterTranslation(item.key)}
                                                />
                                            </div>
                                        }
                                    </>
                                )
                            })
                        }
                    </div>
                    <div className="editor">
                        <div className="data">
                            <AceEditor
                                placeholder={t("workflow specifications")}
                                mode="json"
                                theme="tomorrow"
                                name="testchjhjks"
                                onChange={onChange}
                                fontSize={12}
                                showPrintMargin={true}
                                showGutter={true}
                                highlightActiveLine={true}
                                style={{ width: '100%', height: '480px' }}
                                value={`${jsonList}`}
                                setOptions={{
                                    enableBasicAutocompletion: true,
                                    enableLiveAutocompletion: true,
                                    enableSnippets: true,
                                    showLineNumbers: true,
                                    tabSize: 4,
                                }} />
                            {
                                translationData.user_updated &&
                                <p>{t("last changed")}:  {translationData.user_updated}, {moment(translationData.updated_at).format("DD.MM.YYYY/h:mm a")}</p>
                            }
                            {
                                errorMessage &&
                                <p className="error-msg">{errorMessage}</p>
                            }
                        </div>
                        <div className="actions">
                            <Button
                                variant="outlined"
                                color="primary"
                                className="btn-secondary-theme"
                                onClick={() => { setShowConfirmModal(true) }}>
                                <LoadIcon className="mr-3" />
                                {"Load Default"}
                            </Button>
                            <div className="right-action">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className="btn-cancel"
                                    onClick={cancel}
                                    disabled={!jsonChanged}>
                                    {t("cancel")}
                                </Button>
                                <Button
                                    className="btn-primary-theme btn-save"
                                    variant="contained"
                                    color="primary"
                                    onClick={submit}
                                    disabled={!jsonChanged || !jsonList || invalidJsonFile}>
                                    <CheckIcon />
                                    {t("save changes")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showConfirmModal && (
                <ConfirmDialog
                    show={showConfirmModal}
                    onClose={loadDefaultTranslation}
                    dialogTitle={t("load default title")}
                    dialogBody={t("load default desc")}
                    confirmBtnText={t("finalize")}
                    cancelBtn={true}
                    showCheck={true}
                    dialogClassName='modal-lg'
                />
            )}
        </>
    );
}