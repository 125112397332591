import React, { useContext, useEffect, useState } from "react";
import { UserService } from "../../services/user/user.service";
import "./workspaces-component.component.scoped.scss";
import WorkspaceItem from "../workspace-item/workspace-item.component";
import { MapperService } from "../../services/mapper/mapper.service";
import { CircularProgress } from "@material-ui/core";
import { GlobalContext } from "../../store";
import { splitUserName } from "../../helpers";
import { LocalStorageService } from "../../services/local-storage/local-storage";
import { AuthService } from "../../services/auth/auth.service";
import { history } from "react-router-guard";

const WorkspacesComponent = () => {
  const userService: UserService = new UserService();
  const mapperService: MapperService = new MapperService();
  const localStorageService: LocalStorageService = new LocalStorageService();
  const authService: AuthService = new AuthService();
  const emptyArr: any = [];
  const [tenantsData, setTenantsData] = useState(emptyArr);
  const [loading, setLoading] = useState(true);
  const { setUser } = useContext(GlobalContext);
  async function fetchData() {
    const tenants = await userService.getUserTenants();
    const mappedTenants = await mapperService.mapTenants(tenants);
    if (mappedTenants.length === 1) {
      localStorageService.setData("tenantId", mappedTenants[0].id);
      localStorageService.setData("tenantName", mappedTenants[0].name);
      localStorageService.setData("tenantIcon", mappedTenants[0].logoUrl);
      let user = await authService.account();
      const { firstName, lastName } = splitUserName(user.name);
      user = { ...user, firstName, lastName };
      localStorageService.setData("user", user);
      setUser(user);
      history.push("/dashboard");
    }
    setTenantsData(mappedTenants);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="workspaces-page">
      <div className="compliance-title d-flex flex-row">
        <img src="/icons/logos/compliance2b.svg" alt="compliance2b logo" />
        <h3 className="ml-2">Compliance2b</h3>
      </div>

      <div className="welcome-text">
        <div className="welcome-text-main">
          <span className="purple-text">Willkommen zurück! </span>
          Du siehst heute gut aus.
        </div>
        <p>
          Wähle unten einen Workspace, um wieder mit deinem Team zu arbeiten.
        </p>
      </div>

      {loading ? (
        <div className="mt-5">
          <CircularProgress color="inherit" size={60} />
        </div>
      ) : (
        <div className="workspaces-card">
          <div className="header">Deine workspaces</div>
          <div className="workspaces-wrapper">
            {tenantsData.length > 0 ? (
              tenantsData.map((item: any, index: number) => {
                return <WorkspaceItem key={index} tenantData={item} />;
              })
            ) : (
              <div className="no-workspaces">
                Du bist in keinem Workspace registriert
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkspacesComponent;
