import React from "react";
import { CommonService } from "../../services/common/common.service";
import { useTranslation } from "react-i18next";
import SettingsContent from "../../components/settings-content/settings-content.component";

export default function SettingsPage(props: any) {
    const [t] = useTranslation();
    const commonService: CommonService = new CommonService();
    commonService.setTitle(t("settings"));

    return (
        <>
            <div className="setting-content">
                <SettingsContent />
            </div>
        </>
    );
}