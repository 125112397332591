export class ContactInfo {
  public externalAdminName: string = "";
  public externalAdminEmail: string = "";
  public externalAdminNumber: string = "";
  public internalAdminName: string = "";
  public internalAdminEmail: string = "";
  public internalAdminNumber: string = "";
  public techQuestionsEmail: string = "";
  public organisationQuestionEmail: string = "";
}
