import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import "./case-editor.component.scoped.scss";

export const CaseEditorComponent = (props: any) => {
  const { icon, role, name, loading } = props;
  return (
    <div className="editor">
      {loading ? (
        <Skeleton variant="circle" height={40} width={40} style={{marginRight: "10px"}}/>
      ) : (
        <div className="editor-icon">
          <img src={icon} alt="" />
        </div>
      )}
      <div className="editor-info">
        {loading ? (
          <Skeleton variant="text"/>
        ) : (
          <div className="editor-role">{role}</div>
        )}
        {loading ? (
          <Skeleton variant="text"/>
        ) : (
          <div className="editor-name trim">{name}</div>
        )}
      </div>
    </div>
  );
};
