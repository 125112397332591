import React from "react";
import "./files-comment.component.scoped.scss";
import { icons } from "../../statics/icons";
import { formatDate } from "../../helpers";
import { useTranslation } from "react-i18next";

const FilesTableComment = (props: any) => {
  const [t] = useTranslation();

  return (
    <div className="comment-section w-100">
      <div className="timeline-user align-items-center">
        <div className="user-img">
          <img src={icons.user} alt="" />
        </div>
        <div className="user-info pl-3">
          <div className="user-name">{props.user}</div>
          <div className="comment-date">
            {formatDate(props.date, "dddd, DD.MM.YYYY")} {t("at")}{" "}
            {formatDate(props.date, "HH:mm")}
          </div>
        </div>
      </div>

      <div className="comment-content">{props.comment}</div>
    </div>
  );
};

export default FilesTableComment;
