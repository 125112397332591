import { compliance2bEnvironmentConfig } from "./environment.compliance2b";
import { grcExpertsbEnvironmentConfig } from "./environment.grc-exports";
import { stagingEnvironmentConfig } from "./environment.staging";
import { localEnvironmentConfig } from "./environment.local";

// const stage = process.env.REACT_APP_STAGE;

const domain = window.location.host;

export const config =
    domain.includes('staging') ? stagingEnvironmentConfig :
        domain.includes('compliance2b.com') ? compliance2bEnvironmentConfig :
            domain.includes('grc-experts.report') ? grcExpertsbEnvironmentConfig : localEnvironmentConfig;
