import React from "react";
import HeaderComponent from "../../components/header/header.component";
import CasesContent from "../../components/cases-content/cases-content.component";
import { CasesProvider } from "../../store";
import { CommonService } from "../../services/common/common.service";
import { useTranslation } from "react-i18next";

export default function CasesPage() {
  const [t] = useTranslation();
  const commonService: CommonService = new CommonService();
  commonService.setTitle(t("Case Manager"));
  return (
    <CasesProvider>
      <HeaderComponent />
      <CasesContent />
    </CasesProvider>
  );
}
