import React from "react";
import { DashboardGraphCardProps } from "../../types/dashboard-graph-card-props";
import ShuffleIcon from "@material-ui/icons/Shuffle";
import "./dashboard-graph-card.component.scoped.scss";
import { ChartComponent } from "../chart/chart.component";
import { ChartDoughnutComponent } from "../chart-doughnut/chart-doughnut.component";
import { useTranslation } from "react-i18next";
import { chartDoughnutColors } from "../../statics/charts";
import DashboardGraphFilter from "../dashboard-graph-filter/dashboard-graph-filter.component";

export default function DashboardGraphCard(props: DashboardGraphCardProps) {
  const [t] = useTranslation();
  const {
    title,
    subTitle,
    isDraggable,
    isDragging,
    chart,
    type,
    durationChange,
  } = props;

  function isZero(item: number) {
    return item === 0;
  }

  return (
    <div className={"dashboard-card " + (isDragging ? "dragging" : "")}>
      <div
        className="draggable-icon"
        style={{ visibility: isDraggable ? "visible" : "hidden" }}
      >
        <ShuffleIcon />
      </div>
      <div className="d-flex justify-content-between align-items-end flex-wrap mb-4">
        <div className="card-details">
          <div className="card-title">{title}</div>
          <div className="card-sub-title">{subTitle}</div>
        </div>
        {type === "pie" && (
          <div className="card-filter">
            <DashboardGraphFilter onDurationChange={durationChange} />
          </div>
        )}
      </div>
      <div className="card-graph">
        {type === "bar" &&
          (!chart.data.every(isZero) ? (
            <div className="chart">
              <ChartComponent
                data={chart.data}
                labels={chart.labels}
                name={title}
                tooltipLabel={t(title)}
              />
            </div>
          ) : (
            <div className="falback-wrapper">
              <img
                src="dashboard-fallback.png"
                className="empty-chart-fallback"
                alt="empty-charts"
              />
            </div>
          ))}
        {type === "pie" &&
          (!chart.data.every(isZero) ? (
            <div className="chart">
              <ChartDoughnutComponent
                data={chart.data}
                labels={chart.labels}
                colors={chartDoughnutColors}
                name={title}
              />
              <div className="chart-stats">
                {chart.labels.map((item: any, index: any) => {
                  return (
                    <div className="stats-row" key={index}>
                      <div
                        className="stats-color"
                        style={{ backgroundColor: chartDoughnutColors[index] }}
                      />
                      <div className="stats-label trim">
                        {chart.data[index]}% {item}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="falback-wrapper">
              <img
                src="dashboard-fallback.png"
                className="empty-chart-fallback"
                alt="empty-charts"
              />
            </div>
          ))}
      </div>
    </div>
  );
}
