export const reporterTranslationValues = [
    {
        name: "Select country/company",
        translation: "Land/Unternehmen auswählen",
        key: "create",
    },
    {
        name: "Enter message",
        translation: "Meldung erfassen",
        key: "record"
    },
    {
        name: "Check message",
        translation: "Meldung überprüfen",
        key: "check"
    },
    {
        name: "Receive access data",
        translation: "Zugangsdaten erhalten",
        key: "download"
    },
    // {
    //     name: "PDF",
    //     translation: "PDF",
    //     key: "PDF"
    // },
    // {
    //     name: "Manage Case",
    //     translation: "Case",
    //     key: "case"
    // },
    // {
    //     name: "Track",
    //     translation: "Track",
    //     key: "track"
    // }
]