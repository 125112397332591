import React, { useContext, useState } from "react";
import "./normal-login.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { InputComponent } from "../input/input.component";
import { AuthContext } from "../../store";
import { Button, CircularProgress } from "@material-ui/core";
import { AuthService } from "../../services/auth/auth.service";
import { CommonService } from "../../services/common/common.service";
import { history } from "react-router-guard";
import { LocalStorageService } from "../../services/local-storage/local-storage";
import { UserService } from "../../services/user/user.service";

const authService: AuthService = new AuthService();
const userService: UserService = new UserService();
const localStorageService: LocalStorageService = new LocalStorageService();
const commonService: CommonService = new CommonService();

export const NormalLoginComponent = () => {
  const [t] = useTranslation();
  commonService.setTitle(t("Case Management"));
  const { validate, errors, email, password, setLoginError } = useContext(
    AuthContext
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [capsLock, setCapsLock] = useState(false);
  const { email: emailError, password: passwordError } = errors;

  const validateEmail = (event: any) => {
    setLoginError(null);
    const value = event.target.value;
    setError("");
    return validate("email", value);
  };

  const inputPassword = (event: any) => {
    setLoginError(null);
    const value = event.target.value;
    setError("");
    return validate("password", value);
  };

  const login = async () => {
    if (Object.keys(errors).length > 0 || loading || !email || !password) {
      return;
    }
    try {
      setLoading(true);
      const token = await authService.login(email, password);
      if (!token) {
        setError("invalid_user_credentials");
      } else {
        localStorageService.setData("token", token);
        const res = await userService.canUserLogin();
        if (res.verified) {
          history.push("/workspaces");
        } else {
          localStorageService.setData("token", "");
          setError("invalid_user_credentials");
        }
      }
    } catch (error) {
      return setLoginError("login-error");
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && !Object.keys(errors).length) {
      login();
    }
  };

  const onKeyDown = (e: any) => {
    if (e.getModifierState("CapsLock")) {
      setCapsLock(true);
    } else {
      setCapsLock(false);
    }
  };

  return (
    <div className="normal-login">
      <div className="label">{t("welcome back")}</div>
      <form
        className="login-form"
        noValidate
        autoComplete="off"
        onKeyDown={handleKeyDown}
      >
        <InputComponent
          label="Email"
          icon="user"
          type="text"
          validate={validateEmail}
          error={emailError}
          focused={true}
        />
        <InputComponent
          label="password"
          icon="password"
          type="password"
          validate={inputPassword}
          error={passwordError}
          onKeyDown={onKeyDown}
        />
        {capsLock && <div className="error-msg capsLock">{t('caps lock is turned on')}</div>}

        {error && <div className="error-msg">{t(error)}</div>}
        <div className="forget-password-link" onClick={() => history.push("/forgot-password")}>{t("forgot password")}</div>
        <Button
          variant="contained"
          className="btn"
          onClick={login}
          disabled={
            Object.keys(errors).length > 0 || loading || !email || !password
          }
        >
          <span className="login-btn-content">
            {!loading && t("login")}
            {loading && <CircularProgress color="primary" />}
          </span>
        </Button>
      </form>
    </div>
  );
};
