import React, { useState, useEffect } from "react";
import "./dashboard-first-block.component.scoped.scss";
import { useTranslation } from "react-i18next";
import DashboardCard from "../dashboard-card/dashboard-card.component";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import { DashboardService } from "../../services/dashboard/dashboard.service";
import { MapperService } from "../../services/mapper/mapper.service";
import { LocalStorageService } from "../../services/local-storage/local-storage";

export default function DashboardFirstBlock(props: any) {
  const dashboardService = new DashboardService();
  const mapperService = new MapperService();
  const localStorageService = new LocalStorageService();
  const user = localStorageService.getData("user");
  const [t] = useTranslation();
  const emptyArr: any = [];
  const [cards, setCards] = useState(emptyArr);

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const orderedCards = reorder(
      cards,
      result.source.index,
      result.destination.index
    );
    setCards(orderedCards);
    props.onCardsChange(orderedCards);
  };

  async function fetchData() {
    const cardsInfo = await dashboardService.getCounts();
    const cards = mapperService.mapCounts(cardsInfo);
    setCards(cards);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list" direction="horizontal">
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="row mb-5"
          >
            {cards.map((card: any, index: number) => {
              return (
                <Draggable key={card.id} draggableId={card.id} index={index}>
                  {(
                    provided: DraggableProvided,
                    snapshot: DraggableStateSnapshot
                  ) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={(user.group === "external" ? "col-4" : "col-3") + " card-container"}
                    >
                      <DashboardCard
                        title={t(card.title)}
                        total={card.total}
                        icon={card.icon}
                        isDraggable={true}
                        isDragging={snapshot.isDragging}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
