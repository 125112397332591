import React, { useContext } from "react";
import "./case-header.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { CaseContext, GlobalContext } from "../../store";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Skeleton from "@material-ui/lab/Skeleton";
import { formatDate, sanitize } from "../../helpers";
import { icons } from "../../statics/icons";
import { CaseEditorComponent } from "../case-editor/case-editor.component";
import { history } from "react-router-guard";
import CaseHeaderActionComponent from "../case-header-action/case-header-action.component";

export default function CaseHeaderComponent(props: any) {
  const [t] = useTranslation();

  const { fullNav } = useContext(GlobalContext);
  const { caseDetails, loading } = useContext(CaseContext);

  const backToCases = () => {
    history.push("/cases");
  };

  return (
    <div
      className={"case-header w-100 " + (fullNav ? "full-nav" : "")}>
      <div className="row no-gutters">
        <div className="left-header">
          <div className="header-content">
            {loading ? (<Skeleton variant="text" height={35} width={210} />) :
              (<div className="back" onClick={backToCases}>
                <ChevronLeftIcon />
                <div className="back-link">{t("back to the search result")}</div>
              </div>
              )}
            {loading ? (<Skeleton variant="text" height={50} width={500} />) :
              (<div className="case-info">
                <h2 className="case-title">
                  {t("case")} {caseDetails.internalId}
                </h2>
                <div className="case-status">
                  <div className="status-color" style={{ backgroundColor: caseDetails.statusColor }} />
                  <div className="status-text">
                    {caseDetails.status?.toLowerCase()}
                  </div>
                </div>
                <div className="case-due">
                  – {t("dueDate")}:{" "}
                  {formatDate(caseDetails.dueDate, "DD.MM.YYYY")}
                </div>
              </div>
              )}
            {loading ? (<Skeleton variant="text" height={50} />) :
              (<div className="case-brief" dangerouslySetInnerHTML={sanitize(caseDetails.brief)}></div>)}
            {loading ? (<Skeleton variant="text" height={25} width={150} />) :
              (<div className="case-date">
                {t("created")}:{" "}
                {formatDate(caseDetails.createdAt, "dddd, DD.MM.YYYY")}
              </div>
              )}
          </div>
        </div>
        <div className="middle-header">
          {loading ? (<Skeleton variant="text" width={150} />) : <h3 className="title">{t('users management')}</h3>}
          <CaseEditorComponent
            icon={icons.firstEditor}
            role={t("main processor")}
            name={caseDetails.mainProcessor}
            loading={loading} />
          <CaseEditorComponent
            icon={icons.otherEditors}
            role={t("intern")}
            name={caseDetails.internalUsers + ' ' + t("personen")}
            loading={loading} />
          <CaseEditorComponent
            icon={icons.thirdParty}
            role={t("extern")}
            name={caseDetails.externalUsers + ' ' + t("personen")}
            loading={loading} />
        </div>
        <div className="right-header">
          <CaseHeaderActionComponent display='vertical' />
        </div>
      </div>
    </div>
  );
}
