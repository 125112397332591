export default (state: any, action: any) => {
  switch (action.type) {
    case "SET_USERS":
      return {
        ...state,
        users: action.payload.users,
      };
    case "SET_PAGINATION":
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: action.payload.params.currentPage,
          items: action.payload.params.items,
          totalPages: action.payload.params.totalPages,
          total: action.payload.params.total,
        }
      };
    case "SORT_USERS":
      return {
        ...state,
        options: {
          ...state.options,
          sortDirection: action.payload.params.sortDirection,
          sortColumn: action.payload.params.fieldName,
        }
      };
    case "SET_FILTER":
      return {
        ...state,
        filter: {...state.filter, ...action.payload.filter}
      }
    default:
      return state;
  }
};