import packageJson from '../../package.json';

export const compliance2bEnvironmentConfig = {
    domain: 'compliance2b.com',
    subDomain: 'app',
    serverUrl: 'https://app-api.compliance2b.com/',
    keyCloakUrl: 'https://keycloak-identity.compliance2b.com/auth/realms/compliance/',
    predictUrl: 'https://case-classsifier-staging.compliance2b.com/api/tasks/predict',
    appVersion: packageJson.version,
    environment: 'production'
}
