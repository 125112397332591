import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, CircularProgress } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutline";
import './ai-management-content.component.scoped.scss';

import "ace-builds";
import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-json5";
import { SettingsService } from "../../services/settings/settings.service";
import { showToastMsg } from "../../helpers";
import { PhrasesData } from "../../types/phrases-data";
import moment from "moment";
import { ConfirmDialog } from "../../dialogs/confirm-dialog/confirm-dialog";
import { GlobalContext } from "../../store";

export const AIManagementContent = () => {
    const [t] = useTranslation();
    const settingsService = new SettingsService();
    const { setEncodeStatus, encodeStatus } = useContext(GlobalContext);

    const [phrases, setPhrases] = useState('');
    const [phrasesInfo, setPhrasesInfo] = useState(new PhrasesData());
    const [originalPhrases, setOriginalPhrases] = useState('');
    const [phrasesChanged, setPhrasesChanged] = useState(false);
    const [invalidPhrases, setInvalidPhrases] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const onChange = (j: any) => {
        setPhrasesChanged(true);
        setPhrases(j);
        try {
            if (hasDuplicateKey(j)) {
                setInvalidPhrases(true);
                setErrorMessage('Duplicate values');
                return;
            } else {
                JSON.parse(j);
                setErrorMessage('');
                setInvalidPhrases(false);
            }
        } catch (e) {
            setInvalidPhrases(true);
        }
    }

    const hasDuplicateKey = (j: any) => {
        let x = JSON.stringify(JSON.parse(j));
        let y = JSON.stringify(JSON.parse(j), null, '\t');
        return !(j === y || j === x);
    };


    useEffect(() => {
        listPhrases();
        // eslint-disable-next-line
    }, [])

    const listPhrases = async () => {
        setErrorMessage('');
        const response = await settingsService.getPhrases();

        setPhrasesInfo(response);
        setPhrases(JSON.stringify(response.phrases, null, '\t'));
        setOriginalPhrases(JSON.stringify(response.phrases, null, '\t'));
    }

    const cancel = () => {
        setPhrases(originalPhrases);
        setPhrasesChanged(false);
    }


    const submit = async (value: any) => {
        setShowConfirmModal(false);
        if (value) {
            setEncodeStatus(0);
            setPhrasesChanged(false);
            setErrorMessage('');
            const res = await settingsService.updatePhrases(JSON.parse(phrases));
            if (res && res.message === "Updated") {
                showToastMsg("success", t("edited categories sent to AI successfully"))
                listPhrases();
                setOriginalPhrases(phrases);
            } else {
                setEncodeStatus(1);
                setErrorMessage(res);
            }
        }
    }

    return (
        <>
            <section className="ai-management">
                <div className="section-info">
                    <h3>{t("ai specify text modules")}</h3>
                    <p>{t("ai specify desc")}</p>
                </div>
                <div className="ai-content">
                    <div className="phrases-tab">
                        <div className="item">
                            <img src="/icons/status_default.svg" alt="" className="mx-2" />
                            <span>{t('AI phrases file')}</span>
                        </div>
                    </div>
                    <div className="phrases">
                        <div className="editor">
                            <AceEditor
                                mode="json"
                                theme="tomorrow"
                                name="testchjhjks"
                                onChange={onChange}
                                fontSize={12}
                                showPrintMargin={true}
                                showGutter={true}
                                highlightActiveLine={true}
                                style={{ width: '100%', height: '480px' }}
                                value={`${phrases}`}
                                setOptions={{
                                    enableBasicAutocompletion: true,
                                    enableLiveAutocompletion: true,
                                    enableSnippets: true,
                                    showLineNumbers: true,
                                    tabSize: 4,
                                }} />
                            {
                                phrasesInfo.user_updated &&
                                <p>{t("last changed")}:  {phrasesInfo.user_updated}, {moment(phrasesInfo.updated_at).format("DD.MM.YYYY/h:mm a")}</p>
                            }
                            {
                                errorMessage &&
                                <p className="error-msg">{errorMessage}</p>
                            }
                        </div>
                        <div className="actions">
                            <div className="right-action">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className="btn-cancel"
                                    onClick={cancel}
                                    disabled={!phrasesChanged}>
                                    {t("cancel")}
                                </Button>
                                <Button
                                    className="btn-primary-theme btn-save"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setShowConfirmModal(true)}
                                    disabled={!phrasesChanged || !phrases || invalidPhrases || !encodeStatus}>
                                    <CheckIcon />
                                    {t("save changes and create encoding file")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-info">
                    <h3>{t("creating the phrases encoding file on")}</h3>
                    <p>{t("creating the phrases file desc")}</p>
                </div>
                <div className="file-section">
                    <h5>Phrases Encoding File</h5>
                    <div className="file-content">
                        <div className="file">
                            <div className="info">
                                <img src="/icons/text.svg" alt="" className="mx-2" />
                                <span>Phrase Encoding File
                                    <br></br>
                                    <small>4Mb</small>
                                </span>
                            </div>
                            {
                                encodeStatus ?
                                    <CheckCircleIcon /> :
                                    <CircularProgress color="inherit" size={24} />
                            }
                        </div>
                        {
                            phrasesInfo.fileUpdatedAt &&
                            <p>{t("last updated")}:  {moment(phrasesInfo.fileUpdatedAt).format("DD.MM.YYYY/h:mm a")}</p>
                        }
                    </div>
                </div>
            </section>
            {showConfirmModal && (
                <ConfirmDialog
                    show={showConfirmModal}
                    onClose={submit}
                    dialogTitle={t("update phrases")}
                    dialogBody={t("update phrases desc")}
                    confirmBtnText={t("confirm")}
                    cancelBtn={true}
                    dialogClassName='modal-lg'
                />
            )}
        </>
    )
}
