import React, { useContext, useEffect, useState } from "react";
import "./workflow-results.component.scoped.scss";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslation } from "react-i18next";
import { icons } from "../../statics/icons";
import { CasesService } from "../../services/cases/cases.service";
import { GlobalContext } from "../../store";

const WorkflowResults = (props: any) => {
  const [t] = useTranslation();
  const [resultId, setResultId] = useState("");
  const [comment, setComment] = useState("");
  const [resolutions, setResolutions] = useState([]);
  const { user, fullNav } = useContext(GlobalContext);
  const casesService: CasesService = new CasesService();
  const maxChar = 500;

  const isValid = (comment: string = "") => {
    return comment.length <= maxChar;
  };



  const handleChange = (event: any) => {
    if (event.target?.name === "results") {
      setResultId(event.target?.value);
      props.handleReviewNotes({ resolutionId: event.target?.value, resolutionComment: comment })
    } else if (event.target?.name === "comment") {
      setComment(event.target?.value);
      props.handleReviewNotes({ resolutionId: resultId, resolutionComment: event.target?.value });
    }
  }

  async function fetchData() {
    const resolutionsResponse = await casesService.getCasesResolutions();
    setResolutions(resolutionsResponse);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="results ml-3">
      <div className={"section mb-2 " + (fullNav ? "flex-wrap" : "")}>
        <div className="mr-1 mb-1" style={{ width: fullNav ? "290px" : "330px" }}>
          <FormControl className="select-input select-grey" style={{ width: fullNav ? "290px" : "330px" }}>
            <InputLabel id="results">
              {t("The result of the analysis")}
            </InputLabel>
            <Select
              labelId="results"
              id="results-select"
              value={resultId}
              name="results"
              onChange={(event: any) => handleChange(event)}
              IconComponent={ExpandMoreIcon}
              required
              disabled={user.type === 'auditor'}
            >
              {resolutions.map((item: any, index: any) => {
                return (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="hint-wrapper">
          <div className="">
            <img src={icons.infoDark} alt="" />
          </div>
          <div className="hint">{t("this result will be shared with the reporter")}</div>
        </div>
      </div>
      <div className={"section flex-column align-items-end  mb-2 " + (fullNav ? "flex-wrap" : "")}>
        <div className="mr-1 " style={{ minWidth: fullNav ? "290px" : "330px" }}>
          <TextField
            className="text-area-multiline w-100"
            label={t("optional case specific comment")}
            variant="filled"
            name="comment"
            multiline
            rows={5}
            rowsMax={5}
            value={comment}
            disabled={user.type === 'auditor'}
            onChange={(event: any) => handleChange(event)}
          />
        </div>

        <div className={"input-char-count mt-1 mr-1 " + (comment && !isValid(comment) ? "invalid" : "")}>{comment.length}/{maxChar} {t("character")}</div>

      </div>
    </div>
  );
};

export default WorkflowResults;
