import React from "react";
// import { history } from "react-router-guard";
// import { useTranslation } from "react-i18next";
// import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import './settings-head.component.scoped.scss';

export default function SettingsHead(props: any) {
    // const [t] = useTranslation();

    // const backToSettings = () => {
    //     history.push("/settings")
    // }

    return (
        <>
            <div className="head">
                <h1 className="title">{props.title}</h1>
                <p className="sub-title">{props.subTitle}</p>
                {/* <button className="back" onClick={backToSettings}>
                    <ChevronLeftIcon />
                    <span>{t("back to settings")}</span>
                </button> */}
            </div>
        </>
    )
}