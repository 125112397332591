import React, { useContext, useEffect, useState } from "react";
import "./update-branch-content.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { Button, Checkbox } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { SettingsService } from "../../services/settings/settings.service";
import { showToastMsg } from "../../helpers";
import { GlobalContext } from "../../store";

export default function UpdateBranchContent(props: any) {
    const [t] = useTranslation();
    const settingsService: SettingsService = new SettingsService();
    const { setBranches, branches } = useContext(GlobalContext);
    const [saving, setSaving] = useState(false);
    const [updatedVisibility, setUpdatedVisibility] = useState(false);
    const [branchesChange, setBranchesChange] = useState(false);

    useEffect(() => {
        setUpdatedVisibility(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedVisibility]);


    const updateBranch = (index: number) => {
        const updatedBranches = branches;
        updatedBranches[index].visibility = !updatedBranches[index].visibility;
        setBranches(updatedBranches);
        setUpdatedVisibility(true);
        setBranchesChange(true);
    }

    const submit = async () => {
        setSaving(true);
        await settingsService.updateBranches(branches);
        setSaving(false);
        setBranchesChange(false);
        showToastMsg("success", t("branches updated successfully"));
    }

    return (
        <div className="branches">
            <div className="section-info">
                <h3>{t("hide and show branches")}</h3>
                <p>{t('here you can hide and show existing departments or locations')}</p>
            </div>
            <section className="branches-section mb-3">
                <div className="row">
                    <div className="col-12">
                        <div className="branches-card">
                            <div className="card-content">
                                <div className="mt-3 mb-4">
                                    <div className="branches-content no-gutters">
                                        {branches.map((branch: any, index: number) => {
                                            return (
                                                <div className="item mb-2" key={index}>
                                                    <Checkbox
                                                        checked={branch.visibility}
                                                        onChange={(e) => { updateBranch(index) }}
                                                        name="updateBranche"
                                                        size="small" />
                                                    <p>
                                                        {branch.name}
                                                    </p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="d-flex justify-content-end mb-4">
                <div className="footer mt-3">
                    <Button
                        className="btn-primary-theme btn-save"
                        variant="contained"
                        color="primary"
                        onClick={submit}
                        disabled={saving || !branchesChange}>
                        <CheckIcon /> {t("save changes")}
                    </Button>
                </div>
            </div>
        </div>
    )
}
