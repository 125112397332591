import { TableColumn } from "../types/table-column";

export const newCommentsColumns: TableColumn[] = [
  {
    type: "case",
    value: "process",
    fieldName: "internalId",
    secondaryValue: "name",
    width: "24.5%",
  },
  {
    type: "comment",
    value: "comment",
    fieldName: "content",
    width: "50.5%",
  },
  {
    type: "date",
    value: "date",
    fieldName: "created_at",
    width: "20%",
  },
];
