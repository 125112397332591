import { icons } from "./icons";

export const caseNoticeInfo: any[] = [
  {
    icon: icons.category,
    value: "category",
    title: "category",
    classes: "col-6",
  },
  {
    icon: icons.country,
    value: "land",
    title: "country",
    classes: "col-6",
  },
  {
    icon: icons.result,
    value: "resolution",
    title: "result",
    classes: "col-12",
  },
  {
    icon: icons.result,
    value: "resolutionComment",
    title: "result comment",
    classes: "col-12",
  },
  {
    icon: icons.labels,
    value: "labels",
    title: "labels",
    classes: "col-6",
  },
  {
    icon: icons.company,
    value: "branch",
    title: "affected company",
    classes: "col-6",
  },
];
