import packageJson from '../../package.json';

export const grcExpertsbEnvironmentConfig = {
    domain: 'grc-experts.report',
    subDomain: 'app',
    serverUrl: 'https://app-api.grc-experts.report/',
    keyCloakUrl: 'https://keycloak-identity.grc-experts.report/auth/realms/grc-experts/',
    predictUrl: 'https://case-classsifier-staging.compliance2b.com/api/tasks/predict',
    appVersion: packageJson.version,
    environment: 'production'
}
