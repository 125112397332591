import { newCommentsColumns } from "./new-comments-columns"
import { newCasesColumns } from "./new-cases-columns"

export const TableCards = [
  {
    id: "1",
    title: "internal communication",
    subTitle: "newest internal comments",
    tableName: "internal comments",
    columns: newCommentsColumns,
  },
  {
    id: "2",
    title: "new cases",
    subTitle: "newest cases",
    tableName: "cases",
    columns: newCasesColumns,
  },
  {
    id: "3",
    title: "comments from reporter",
    subTitle: "newest reporter comments",
    tableName: "reporter comments",
    columns: newCommentsColumns,
  },
]
