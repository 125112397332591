import React, { useContext, useEffect, useState } from "react";
import "./case-header-action.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { CaseContext, GlobalContext } from "../../store";
import Skeleton from "@material-ui/lab/Skeleton";
import { icons } from "../../statics/icons";
import { ActivateAiModal } from "../../dialogs/activate-AI/activate-AI";
import { AIService } from "../../services/ai/ai.services";
import { showToastMsg } from "../../helpers";
import { jsPDF } from "jspdf";
import { saveAs } from 'file-saver';
import { AddUserDialog } from "../../dialogs/add-user/add-user";
import { Button } from "@material-ui/core";
import { SettingsService } from "../../services/settings/settings.service";

export default function CaseHeaderActionComponent(props: any) {
    const [t] = useTranslation();
    const { display } = props;
    const aiService = new AIService();
    const settingsServices = new SettingsService();

    const { user, encodeStatus } = useContext(GlobalContext);
    const {
        caseDetails,
        loading,
        setAIActivation,
        aiActivation,
        setActivationWorkflowAI,
        activeTab,
        setCaseDetailsUpdated
    } = useContext(CaseContext);

    const [showActivateAiModal, setShowActivateAiModal] = useState(false);
    const [showUserModal, setShowUserModal] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogBody, setDialogBody] = useState('');
    const [aiType, setAiType] = useState('');

    const handleAIModalClose = (value: any) => {
        setTimeout(() => {
            setShowActivateAiModal(false);
            if (value) {
                if (aiType === 'description') {
                    setCaseDetailsUpdated(true);
                    setAIActivation(true);
                } else if (aiType === 'workflow') {
                    setActivationWorkflowAI(true);
                }
            }
        }, 0);
    };

    const deactivateAI = async () => {
        await aiService.activateAI(false, caseDetails.id);
        setAIActivation(false);
        setCaseDetailsUpdated(true);
        showToastMsg("success", t("AI deactivated successfully"));
    }

    const activateAI = (type: string) => {
        if (type === 'description') {
            setDialogTitle('sit back ACT4 analyzes the message for you');
            setDialogBody('ACT4 helps you analyze the case and capture important clues faster');
            setAiType('description');
        } else if (type === 'workflow') {
            setDialogTitle('ACT4 is prefilling the checklist based on empirical values for you');
            setDialogBody('');
            setAiType('workflow');
        }
        setShowActivateAiModal(true);
    }

    const downloadPdfFile = async () => {
        await settingsServices.addLog({ caseId: caseDetails.id, key: 'DOWNLOAD_PDF' });
        const doc = new jsPDF('p');
        doc.setFontSize(15);
        let lineHeight = 10;

        const person = doc.splitTextToSize(`${t('involved people')}: ${caseDetails.involvedPersons.replace(/,/g, ', ')}`, 180);
        person.forEach((text: any) => {
            doc.text(text, 10, (lineHeight += 7));
        });

        lineHeight += 3;
        const department = doc.splitTextToSize(`${t('involved department')}: ${caseDetails.involvedDepartment.replace(/,/g, ', ')}`, 180);
        department.forEach((text: any) => {
            doc.text(text, 10, (lineHeight += 7));
        });

        doc.text(`${t('category')}: ${caseDetails.category}`, 10, (lineHeight += 10));
        doc.text(`${t('affected company')}: ${caseDetails.branch}`, 10, (lineHeight += 10));
        doc.text(`${t('country')}: ${caseDetails.land}`, 10, (lineHeight += 10));
        doc.text(`${t('amount')}: ${caseDetails.amount ? caseDetails.amount : ""}`, 10, (lineHeight += 10));
        doc.text(`${t('ongoing-case')}: ${caseDetails.caseOngoing ? t('yes') : t("No")}`, 10, (lineHeight += 10));

        lineHeight += 3;
        const company = doc.splitTextToSize(`${t('involved company')}: ${caseDetails.involvedCompany.replace(/,/g, ', ')}`, 180);
        company.forEach((text: any) => {
            doc.text(text, 10, (lineHeight += 7));
        });
        doc.text(`${t('relation')}: ${caseDetails.relationship ? caseDetails.relationship : ""}`, 10, (lineHeight += 10));
        doc.text(`${t('start date')}: ${caseDetails.startDate ? caseDetails.startDate : ""}`, 10, (lineHeight += 10));
        doc.text(`${t('end date')}: ${caseDetails.endDate ? caseDetails.endDate : ""}`, 10, (lineHeight += 10));
        // set line-height for description
        doc.setLineHeightFactor(1.5);
        const description = doc.splitTextToSize(`${t('description')}: ${caseDetails.description.replace(/<[^>]+>/g, '')}`, 180);
        // add new page with condition
        const pageHeight = doc.internal.pageSize.getHeight();
        description.forEach((text: any) => {
            if (lineHeight + 10 > pageHeight) {
                lineHeight = 10;
                doc.addPage();
            }
            doc.text(text, 10, (lineHeight += 10));
        });
        doc.setProperties({ title: `${t("case")}-${caseDetails.internalId}` });
        let blobPDF = doc.output('blob');
        const blob = new Blob([blobPDF], {
            type: "pdf/plain;charset=utf-8",
        });
        saveAs(blob, `${t("case")}.pdf`);
    };

    useEffect(() => {
        setAIActivation(!!caseDetails.ai_activate);
        // eslint-disable-next-line
    }, [caseDetails]);

    return (
        <div className={"ai-action " + display}>
            {/* {caseDetails.statusKey !== 'COMPLETED' && activeTab === 0 &&
                <>
                    {loading ? (<Skeleton variant="circle" height={35} width={35} />) :
                        <Button
                            className="btn"
                            disabled={!encodeStatus}
                            onClick={() => { aiActivation ? deactivateAI() : activateAI('description') }} >
                            <img src={aiActivation ? icons.brainActive : icons.brain} alt="brain" className={aiActivation ? 'active' : ''} />
                            <span>
                                {aiActivation ? t('ai deactivate') : t('ai activate')}
                            </span>
                        </Button>
                    }
                </>
            }
            {caseDetails.statusKey !== 'COMPLETED' && activeTab === 3 &&
                <>
                    {loading ? (<Skeleton variant="circle" height={35} width={35} />) :
                        <Button
                            className="btn"
                            disabled={!encodeStatus}
                            onClick={() => activateAI('workflow')}>
                            <img src={icons.brain} alt="brain" />
                            <span>{t('ai support')}</span>
                        </Button>
                    }
                </>
            } */}
            {
                loading ? (<Skeleton variant="circle" height={35} width={35} />) :
                    <Button className="btn" onClick={downloadPdfFile}>
                        <img src={icons.downloadOrange} alt="" />
                        <span>{t('download case')}</span>
                    </Button>
            }
            {
                loading ? (<Skeleton variant="circle" height={35} width={35} />) :
                    <Button className="btn" onClick={() => setShowUserModal(true)}>
                        <img src={icons.usersOrange} alt="user" />
                        <span>
                            {
                                (user.keycloakUserId === caseDetails.mainProcessorId ||
                                    user.type === 'superAdmin' ||
                                    user.role === 'coordinator') &&
                                    caseDetails.statusKey !== 'COMPLETED' ? t('manage users') : t('show users')
                            }
                        </span>
                    </Button>
            }

            {showActivateAiModal && (
                <ActivateAiModal
                    show={showActivateAiModal}
                    onClose={handleAIModalClose}
                    dialogTitle={t(dialogTitle)}
                    dialogBody={t(dialogBody)}
                    aiType={aiType}
                />
            )}
            <AddUserDialog
                show={showUserModal}
                onClose={() => setShowUserModal(false)}
                isEditable={
                    (user.keycloakUserId === caseDetails.mainProcessorId ||
                        user.type === "superAdmin" || user.role === 'coordinator') && caseDetails.statusKey !== 'COMPLETED'
                }
            />
        </div>
    );
}
