import React from "react";
import "./forgot-password.page.scoped.scss";
import { SignUpLayout } from "../../layouts/signup/sign-up.layout";
import NormalForgotPasswordComponent from "../../components/normal-forgot-password/normal-forgot-password.component";
import { useTranslation } from "react-i18next";
import { CommonService } from "../../services/common/common.service";

const commonService: CommonService = new CommonService();

const ForgotPasswordPage = () => {
  const [t] = useTranslation();
  commonService.setTitle(t("Case Management"));

  return (
    <SignUpLayout>
      <NormalForgotPasswordComponent />
    </SignUpLayout>
  );
};

export default ForgotPasswordPage;
