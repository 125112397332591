import React, { useEffect, useRef, useContext } from "react";
import "./files-upload-section.component.scoped.scss";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CasesService } from "../../services/cases/cases.service";
import { useTranslation } from "react-i18next";
import { CaseContext } from "../../store";
import {
  TextField,
  InputAdornment,
  withStyles,
  Tooltip,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { icons } from "../../statics/icons";
import { FileSizes } from "../../statics/file-sizes";
import { alertToastConfig } from "../../statics/alert-config";
import { turncate } from "../../helpers";
import { dummyFiles } from "../../statics/dummy-files";
import { fileTypes } from "../../statics/file-types";
import { dummyComments } from "../../statics/dummy-comments";
import { dummyCommunication } from "../../statics/dummy-notes";
import { GlobalContext } from "../../store";

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f2af1d",
    color: "#f5f6f6",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export const FilesUploadSectionComponent = (props: any) => {
  const { user } = useContext(GlobalContext);

  const {
    caseDetails,
    setFilesLoading,
    setCaseFiles,
    setCaseComments,
    setCommunicationsLoading,
    setNotesLoading,
    setCaseNotes,
  } = React.useContext(CaseContext);
  const maxChar = 2500;
  const maxFiles = 5;
  const emptyArr: any = [];
  const [t] = useTranslation();
  const casesService = new CasesService();
  const [comment, setComment] = React.useState("");
  const [saving, setSaving] = React.useState(false);
  const [filesListProgress, setFilesListProgress] = React.useState(emptyArr);
  const [filesList, setFilesList] = React.useState(emptyArr);
  const [filesListCanceled, setFilesListCanceled] = React.useState(emptyArr);
  const inputFileRef = useRef(null);
  const uploadEl = useRef(null);

  const handleChange = (event: any) => {
    const value = event?.target?.value;
    setComment(value);
  };

  const isValid = (comment: string = "") => {
    return comment.length <= maxChar;
  };

  const submit = async () => {
    if (
      !saving &&
      isValid(comment) &&
      filesList.length <= maxFiles &&
      (((props.tab === "reporter" || props.tab === "communication") &&
        comment) ||
        (props.tab === "files" && filesList.length))
    ) {
      setSaving(true);
      props.onUploadingFiles(true);
      const filesSavedOrCanceled: any[] = [];
      const filesProgress: any[] = [];
      const filesCanceled: any[] = [];
      const filesUploaded: any[] = [];
      if (filesList.length) {
        filesList.forEach((file: any, index: number) => {
          casesService.requestSignedUrl(file.name, getFileInfo(file.type).fileType, props.tab, caseDetails.internalId)
            .then((response: any) => {
              const { signedRequest, url } = response;
              const cancelTokenSource = axios.CancelToken.source();
              filesList[index].cancelTokenSource = cancelTokenSource;
              filesList[index].url = url;
              casesService.uploadToSignedURL(signedRequest, filesList[index], cancelTokenSource.token, (event: any) => {
                filesProgress[index] = Math.round(
                  (100 * event.loaded) / event.total
                );
                setFilesListProgress([
                  ...filesListProgress,
                  ...filesProgress,
                ]);
              }).then((uploadResponse: any) => {
                if (uploadResponse === "canceled") {
                  filesSavedOrCanceled.push(file);
                  filesCanceled.push(file);
                  setFilesListCanceled([...filesCanceled]);
                  if (
                    filesSavedOrCanceled.length === filesList.length &&
                    props.tab === "files"
                  ) {
                    resetData();
                  } else if (
                    filesUploaded.length + filesCanceled.length ===
                    filesList.length
                  ) {
                    saveCommentWithFiles(filesUploaded);
                  }
                  return;
                }
                const type = getFileInfo(file.type).fileType;
                const name = file.name.split("." + type)[0];
                const size = `${(file.size / 1024 / 1000).toFixed(2)} MB`;
                const payload = { name, type, size, url: file.url };

                // push uploaded files to save in communication or notes tabs
                filesUploaded.push(payload);

                if (props.tab === "files") {
                  casesService.saveFileAndComment(
                    caseDetails.id,
                    caseDetails.statusId,
                    payload,
                    comment
                  ).then((success: any) => {
                    filesSavedOrCanceled.push(file);
                    if (filesSavedOrCanceled.length === filesList.length) {
                      resetData();
                    }
                  });
                } else if (
                  filesUploaded.length + filesCanceled.length ===
                  filesList.length
                ) {
                  saveCommentWithFiles(filesUploaded);
                }
              });
            });
        });
      } else {
        saveCommentWithFiles();
      }
    }
  };

  async function saveCommentWithFiles(files: any[] = []) {
    if (props.tab === "reporter") {
      await casesService.addReporterComment(
        caseDetails.id,
        caseDetails.statusId,
        files,
        comment
      );
    } else if (props.tab === "communication") {
      await casesService.addCommunicationComment(
        caseDetails.id,
        caseDetails.statusId,
        files,
        comment
      );
    }
    resetData();
  }

  const resetData = () => {
    setSaving(false);
    props.onUploadingFiles(false);
    setComment("");
    setFilesList([]);
    setFilesListProgress([]);
    if (props.tab === "files") {
      fetchCaseFiles(caseDetails.id);
    } else if (props.tab === "reporter") {
      fetchCaseReporterComments(caseDetails.id);
    } else if (props.tab === "communication") {
      fetchCaseCommunication(caseDetails.id);
    }
  };

  async function fetchCaseFiles(id: string) {
    setFilesLoading(true);
    setCaseFiles(dummyFiles);
    const caseFiles = await casesService.caseFiles(id);
    setTimeout(() => {
      setCaseFiles(caseFiles);
      setFilesLoading(false);
    }, 100);
  }

  async function fetchCaseReporterComments(id: string) {
    setCommunicationsLoading(true);
    setCaseComments(dummyComments);
    const caseComments = await casesService.caseComments(id);
    setTimeout(() => {
      setCaseComments(caseComments);
      setCommunicationsLoading(false);
    }, 100);
  }

  async function fetchCaseCommunication(id: string) {
    setNotesLoading(true);
    setCaseNotes(dummyCommunication);
    const caseNotes = await casesService.getCaseCommunication(id);
    setTimeout(() => {
      setCaseNotes(caseNotes);
      setNotesLoading(false);
    }, 100);
  }

  const clickUpload = () => {
    if (!saving) {
      const element: any = inputFileRef.current;
      element.click();
    }
  };

  async function getImagePreview(file: File) {
    return new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const src = reader.result;
        resolve(src);
      };
      reader.readAsDataURL(file);
    });
  }

  const validateFiles = async (files: any[]) => {
    const filteredFilesList: any = [];
    const errorsMsg: any[] = [];
    for (const file of files) {
      const { fileType, maxSize } = getFileInfo(file.type);
      const fileName = file.name.split("." + fileType)[0];

      if (file.size > maxSize) {
        errorsMsg.push(
          `<div class='alert-file-name'>${turncate(
            fileName,
            20
          )}</div> is bigger than max ${fileType.toUpperCase()} file size (${maxSize / 1024 / 1000
          } MB)`
        );
      } else {
        const newFile: any = new File([file], file.name.replace(/[&/\\#, +()$~%'":*?<>{}]/g, '-'), { type: file.type });
        if (fileType.includes("jpg") || fileType.includes("png")) {
          newFile.src = await getImagePreview(newFile);
          filteredFilesList.push(newFile);
        } else {
          filteredFilesList.push(newFile);
        }
      }
    }
    showAlertMsg(errorsMsg);
    setFilesList([...filesList, ...filteredFilesList]);
  };

  useEffect(() => {
    if (!saving && props.droppedFiles && props.droppedFiles.length) {
      validateFiles(props.droppedFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.droppedFiles]);

  const onFileChange = async (e: any) => {
    await validateFiles(e.target.files);
    const element: any = inputFileRef.current;
    element.value = "";
  };

  const getFileInfo = (type: string): any => {
    switch (type) {
      case "video/mp4":
        return { fileType: "mp4", maxSize: FileSizes.mp4.maxSize };
      case "image/jpeg":
        return { fileType: "jpg", maxSize: FileSizes.jpg.maxSize };
      case "image/png":
        return { fileType: "png", maxSize: FileSizes.png.maxSize };
      case "application/pdf":
        return { fileType: "pdf", maxSize: FileSizes.pdf.maxSize };
      default:
        break;
    }
  };

  async function showAlertMsg(queues: any[]) {
    const MySwal = withReactContent(Swal);
    for (const message of queues) {
      await MySwal.fire({
        icon: "error",
        title: message,
        ...alertToastConfig,
      });
    }
  }

  const deleteFile = (index: number) => {
    const filesCopy = [...filesList];
    if (filesList[index].cancelTokenSource) {
      filesList[index].cancelTokenSource.cancel();
      filesList[index].canceled = true;
    } else {
      filesCopy.splice(index, 1);
      setFilesList(filesCopy);
    }
  };

  const handleUploadFocus = (event: any) => {
    const element: any = uploadEl.current;
    element.classList.add("active");
  };

  const handleUploadBlur = (event: any) => {
    const element: any = uploadEl.current;
    element.classList.remove("active");
  };

  return (
    <div className="add-comment">
      <div className="row">
        <div className="notes-col">
          <div className="d-flex">
            <div className="info-icon">
              <img src={icons.info} alt="" />
            </div>
            <div className="info-note">
              {t("files and optionally a description can be uploaded here")}
            </div>
          </div>
        </div>
        <div className="comment-col">
          <div className="w-100">
            <input
              type="file"
              ref={inputFileRef}
              onChange={onFileChange}
              style={{ display: "none" }}
              multiple
              accept={fileTypes}
              disabled={saving || user.type === 'auditor'}
            />
            <TextField
              onFocus={handleUploadFocus}
              onBlur={handleUploadBlur}
              className="text-area-multiline upload-area w-100"
              id="timeline-file-upload"
              label={props.label ? props.label : t("add file and description to communicate with clerk")}
              multiline
              rows={2}
              variant="filled"
              value={comment}
              onChange={handleChange}
              disabled={saving || user.type === 'auditor'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AttachFileIcon
                      className="upload-icon mr-3"
                      onClick={clickUpload}
                    />
                    <SendIcon
                      className={
                        !saving &&
                          (((props.tab === "reporter" ||
                            props.tab === "communication") && comment) ||
                            (props.tab === "files" && filesList.length)) &&
                          isValid(comment) &&
                          filesList.length <= maxFiles
                          ? "svg-active"
                          : "svg-grey"
                      }
                      onClick={submit}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <div className="files-preview" ref={uploadEl}>
              {filesList.map((file: any, index: any) => {
                return (
                  !file.canceled && (
                    <div
                      className={"file-preview " + (saving ? "loading" : "")}
                      key={index}
                    >
                      <StyledTooltip title={file.name} placement="bottom">
                        <img
                          src={
                            file.src
                              ? file.src
                              : icons[getFileInfo(file.type).fileType]
                          }
                          alt=""
                        />
                      </StyledTooltip>
                      {filesListProgress[index] !== 100 && (
                        <div
                          className="file-delete"
                          onClick={() => deleteFile(index)}
                        >
                          <ClearIcon />
                        </div>
                      )}
                      {filesListProgress[index] === 100 && (
                        <div className="file-success">
                          <DoneIcon />
                        </div>
                      )}
                      {saving && filesListProgress[index] < 100 && (
                        <div className="file-progress">
                          {filesListProgress[index] > 5 ? (
                            <CircularProgress
                              variant="static"
                              value={filesListProgress[index]}
                            />
                          ) : (
                            <CircularProgress />
                          )}
                        </div>
                      )}
                    </div>
                  )
                );
              })}
            </div>
            <div className="d-flex justify-content-end">
              <div
                className={
                  "input-char-count mt-1 mr-1 " +
                  (filesList.length > maxFiles ? "invalid" : "")
                }
              >
                {filesList.length - filesListCanceled.length}/{maxFiles}{" "}
                {t("files")}
              </div>
              <div className="input-char-count mt-1 mr-3">
                (PDF max. 20MB; JPG, PNG max. 8MB; MP4 max. 200MB)
              </div>
              <div
                className={
                  "input-char-count mt-1 " +
                  (comment && !isValid(comment) ? "invalid" : "")
                }
              >
                {comment.length}/{maxChar} {t("character")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
