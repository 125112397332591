import { icons } from "./icons";

export const caseDetailsInfo: any[] = [
  {
    icon: icons.involvedPeople,
    value: "involvedPersons",
    AI: "persons",
    title: "involved people",
    classes: "col-6",
  },
  {
    icon: icons.amountOfDamage,
    value: "amount",
    AI: "amount",
    title: "estimated amount of damage",
    classes: "col-6",
  },
  {
    icon: icons.involvedDepartments,
    value: "involvedDepartment",
    AI: "departments",
    title: "involved department",
    classes: "col-6",
  },
  {
    icon: icons.date,
    value: "duration",
    AI: "end_date",
    title: "duration",
    classes: "col-6",
  },
  {
    icon: icons.company,
    value: "involvedCompany",
    AI: "companies",
    title: "involved company",
    classes: "col-6",
  },
  {
    icon: icons.relation,
    value: "relationship",
    AI: "relationship",
    title: "relation",
    classes: "col-6",
  },
  {
    icon: icons.date,
    value: "startDate",
    AI: "start_date",
    title: "start date",
    classes: "col-6",
  },
  {
    icon: icons.date,
    value: "endDate",
    AI: "end_date",
    title: "end date",
    classes: "col-6",
  }
];
