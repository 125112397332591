import React from "react";
import { DashboardTableCardProps } from "../../types/dashboard-table-card-props";
import ShuffleIcon from "@material-ui/icons/Shuffle";
import "./dashboard-table-card.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { TableColumn } from "../../types/table-column";
import { formatDate } from "../../helpers";
import { history } from "react-router-guard";
import { CaseTabs } from "../../statics/caseTabs";

export default function DashboardTableCard(props: DashboardTableCardProps) {
  const [t] = useTranslation();
  const {
    title,
    subTitle,
    tableName,
    columns,
    data,
    isDraggable,
    isDragging,
  } = props;

  const handleRowClick = (caseId: number, tab: number) => {
    history.push(`/case/${caseId}`, {
      tab,
    });
  };

  return (
    <div className={"dashboard-card " + (isDragging ? "dragging" : "")}>
      <div
        className="draggable-icon"
        style={{ visibility: isDraggable ? "visible" : "hidden" }}
      >
        <ShuffleIcon />
      </div>
      <div className="card-details mb-4">
        <div className="card-title">{title}</div>
        <div className="card-sub-title">{subTitle}</div>
      </div>
      {data.length > 0 ? (
        <div className="card-table">
          {/* Table Columns */}
          <div className="card-table-header">
            <div className="d-flex align-items-center justify-content-between">
              {columns.map((column: TableColumn, index: number) => {
                return (
                  <div
                    className="card-table-column trim"
                    key={index}
                    style={{ width: column.width }}
                  >
                    {t(column.value)}
                  </div>
                );
              })}
            </div>
          </div>
          {/* Table Rows */}
          <div className="card-table-body">
            {data.map((row: any, rowIndex: number) => {
              return (
                <div
                  className="card-table-row d-flex justify-content-between"
                  key={rowIndex}
                  style={{
                    minHeight: tableName === "comments" ? "88px" : "53px",
                  }}
                  onClick={handleRowClick.bind(
                    null,
                    row.caseId,
                    tableName === "internal comments"
                      ? CaseTabs.Notes
                      : CaseTabs.Communication
                  )}
                >
                  {columns.map((column: TableColumn, columnIndex: number) => {
                    const { type, fieldName, secondaryValue }: any = column;
                    return (
                      <div
                        className={
                          "card-table-cell " +
                          (type === "string" ? " trim" : "")
                        }
                        key={columnIndex}
                        style={{ width: column.width }}
                      >
                        {type === "string" ? (
                          row[fieldName]
                        ) : type === "date" ? (
                          formatDate(row[fieldName], "DD.MM.YYYY")
                        ) : type === "client" ? (
                          <div className="client-cell">
                            <div className="profile-picture">
                              <img src={row["img"]} alt="" />
                            </div>
                            <div className="client-info">
                              <div className="client-name trim">
                                {row[fieldName]}
                              </div>
                              <div className="branch-name trim">
                                {row[secondaryValue]}
                              </div>
                            </div>
                          </div>
                        ) : type === "case" ? (
                          <div className="case-cell">
                            <div className="case-number trim">
                              {row[fieldName]}
                            </div>
                            <div className="case-admin trim">
                              {row[secondaryValue]}
                            </div>
                          </div>
                        ) : (
                          <div className={type}>{row[fieldName]}</div>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="falback-wrapper">
          <img
            src="dashboard-fallback.png"
            className="empty-chart-fallback"
            alt="empty-charts"
          />
        </div>
      )}
    </div>
  );
}
