export const translationValues = [
    {
        name: "Countries",
        translation: "Länder",
        key: "lands",
        src: "country_icon_default.svg",
        activeSrc: "country_icon_selected.svg",
    },
    {
        name: "Status",
        translation: "Status",
        key: "status",
        src: "status_icon_default.svg",
        activeSrc: "status_icon_selected.svg",
    },
    {
        name: "Resolutions",
        translation: "Ergebnisse",
        key: "resolutions",
        src: "decision_icon_default.svg",
        activeSrc: "decision_icon_selected.svg",
    },
    {
        name: "Relationships",
        translation: "Beziehungen",
        key: "relationships",
        src: "relationship_icon_default.svg",
        activeSrc: "relationship_icon_selected.svg",
    },
    {
        name: "Categories",
        translation: "Kategorien",
        key: "categories",
        src: "betrug.svg",
        activeSrc: "betrug_active.svg",
    }
]