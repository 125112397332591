import React, { useContext, useEffect, useState } from "react";
import "./users-content.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AddNewUserModal from "../../dialogs/add-newUser-modal/add-new-user";
import { DataTableComponent } from "../table/data-table/data-table.component";
import { MapperService } from "../../services/mapper/mapper.service";
import { UsersService } from "../../services/users/users.service";
import { GlobalContext, UsersContext } from "../../store";
import { defaultItems } from "../../statics/pagination";
import moment from "moment";

const UsersContent = () => {
  const [t] = useTranslation();
  const [showUserModal, setShowUserModal] = useState(false);
  const usersService = new UsersService();
  const mapperService = new MapperService();
  const emptyArr: any = [];
  const {
    users,
    columns,
    setUsers,
    setPagination,
    setFilter,
    filter,
    options,
    pagination,
    sort,
  } = useContext(UsersContext);
  const { helpData, setHelpContent } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [reset, setReset] = useState(false);
  const [counts, setCounts] = useState({
    coordinator: 0,
    internal: 0,
    external: 0,
    auditor: 0,
    total: 0,
  });

  async function fetchData(filter?: any) {
    setUsers(emptyArr);
    setLoading(true);
    const usersDetails = await usersService.list(filter);
    if (!usersDetails) { return }
    const { counts, readyUsers, paginationObj } = mapperService.mapUsers(usersDetails);
    setUsers(readyUsers);
    setPagination(paginationObj);
    setCounts(counts);
    setLoading(false);
    setReset(false);
  }

  const refreshData = () => {
    setFilter({ group: '', type: '' });
    setReset(true);
    const paginationParams = { items: defaultItems, page: 1 };
    fetchData(paginationParams);
  };

  const refresh = (action: string) => {
    let page = pagination.currentPage;
    if (users.length === 1 && action === "delete") {
      page = pagination.currentPage === 1 ? 1 : pagination.currentPage - 1;
    }
    const filterObj = {
      ...filter,
      page,
      items: pagination.items,
      sort: options.sortColumn,
      order: options.sortDirection,
    };
    fetchData(filterObj);
  };

  const exportData = async () => {
    const file = await usersService.exportList(filter);
    if (file) {
      const csvContent = "data:text/csv;charset=utf-8,%EF%BB%BF";
      const downloadLink = document.createElement("a");
      downloadLink.href = csvContent + file;
      const today = moment().format("YYYY-MM-DD");
      downloadLink.download = `Users List - ${today}.csv`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  useEffect(() => {
    refreshData();
    setHelpContent(helpData.UserManagementForAdmins);

    // eslint-disable-next-line
  }, [helpData]);

  const sortTable = (fieldName: string, sortDirection: string) => {
    const params = { fieldName, sortDirection };
    const filterObj = {
      ...filter,
      page: 1,
      items: pagination.items,
      sort: fieldName,
      order: sortDirection,
    };
    fetchData(filterObj);
    return sort(params);
  };

  const paginateTable = (pagination: any) => {
    const { items, currentPage } = pagination;
    const params = {
      ...filter,
      items,
      page: currentPage,
      order: options.sortDirection,
      sort: options.sortColumn,
    };
    fetchData(params);
  };

  const filterChange = (value: string, attr: string) => {
    const filterObj: any = {
      items: pagination.items,
      page: 1,
    };
    let obj: any = {};
    if (attr === 'type') {
      filterObj.type = value;
      obj.type = value;
    } else {
      filterObj.group = value;
      obj.group = value;
    }
    setFilter(obj);
    setReset(true);
    sort({
      fieldName: "",
      sortDirection: ""
    });
    fetchData(filterObj);
  };

  const handleModalClose = (value: any) => {
    setTimeout(() => {
      setShowUserModal(false);
    }, 0);
    if (value) {
      refreshData();
    }
  };

  return (
    <div className="users-content">
      <div className="users-header">
        <div className="main-title">
          {t("manage the users accounts")}, <br />
          {t("with whom you work together")}
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <Button
            className="btn-primary-theme btn-add-user mb-5"
            variant="contained"
            color="primary"
            onClick={() => setShowUserModal(true)}
          >
            <AddIcon /> {t("add new user")}
          </Button>
          {showUserModal && (
            <AddNewUserModal
              show={showUserModal}
              onClose={(value: any) => handleModalClose(value)}
            />
          )}
        </div>
      </div>
      <div className="theme-container table-section">
        <DataTableComponent
          tableName="users"
          loading={loading}
          reset={reset}
          columns={columns}
          data={users}
          options={options}
          sort={sortTable}
          pagination={pagination}
          paginate={paginateTable}
          title={t("Overview of the created persons")}
          subTitle={
            `${counts.total} ${t("user")} 
            (${counts.internal} ${t("intern")} / ${counts.external} ${t("extern")} / 
            ${counts.auditor} ${t("auditor")} /  ${counts.coordinator} ${t("coordinator")})`}
          reload={true}
          onReload={refreshData}
          exportData={true}
          onExport={exportData}
          filter={filter}
          onFilterChange={filterChange}
          refresh={refresh}
        />
      </div>
    </div>
  );
};

export default UsersContent;
