import "./settings.layout.scoped.scss";
import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../store";
import { AuthService } from "../../services/auth/auth.service";
import { LocalStorageService } from "../../services/local-storage/local-storage";
import { splitUserName } from "../../helpers";
import SettingsSideMenu from "../../components/settings-side-menu/settings-side-menu.component";

export default function SettingsLayout(props: any) {
    const localStorageService: LocalStorageService = new LocalStorageService();
    const authService: AuthService = new AuthService();
    const { setUser } = useContext(GlobalContext);
    const setUserData = async () => {
        let userData = await authService.account();
        const { firstName, lastName } = splitUserName(userData.name);
        userData = { ...userData, firstName, lastName };
        localStorageService.setData("user", userData);
        setUser(userData);
    };

    useEffect(() => {
        setUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="settings-layout">
            <SettingsSideMenu />
            <div className="content">
                {props.children}
            </div>
        </div>
    );
}
