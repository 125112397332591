import React, { useEffect, useState } from 'react'
import './normal-reset-password.component.scoped.scss'
import { useTranslation } from 'react-i18next'
import { InputComponent } from '../input/input.component'
import { Button, CircularProgress } from '@material-ui/core'
import { history } from 'react-router-guard'
import { UserService } from '../../services/user/user.service'
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { capitalChar, digitChar, passwordRegex, specialChar } from '../../statics/validators'

const userService: UserService = new UserService()
const NormalResetPasswordCompenent = ({ token }: any) => {
  const [loading, setLoading] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [confirmation, setConfirmation] = useState('')
  const [passwordMatchError, setPasswordMatchError] = useState(false)
  const [newPasswordError, setNewPasswordError] = useState('')
  const [tokenIsValid, setTokenIsValid] = useState(true)
  const [tokenValidityLoading, setTokenValidityLoading] = useState(false)
  const [capsLock, setCapsLock] = useState(false);
  const linkExpiredImage = '/Lost-connection.png';

  const [t] = useTranslation()

  const inputPassword = (event: any) => {
    const value = event.target.value
    setNewPassword(value)
    if (!passwordRegex.test(value)) {
      setNewPasswordError('Die Mindestlänge des Passworts beträgt 8 Zeichen')
    } else {
      setNewPasswordError('')
    }

    if (value && confirmation && value !== confirmation) {
      setPasswordMatchError(true)
    } else {
      setPasswordMatchError(false)
    }
  }

  const inputConfirmation = (event: any) => {
    const value = event.target.value
    setConfirmation(value)
    if (value && newPassword && value !== newPassword) {
      setPasswordMatchError(true)
    } else {
      setPasswordMatchError(false)
    }
  }

  const setPassword = async () => {
    try {
      setLoading(true)

      await userService.resetPassword({
        keycloakUserId: token,
        password: newPassword.toString(),
      })
      history.push('/login')
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const canUserSetPassword = async (token: any) => {
    setTokenValidityLoading(true)
    const response = await userService.canResetForgottenPassword({
      token: token,
    })
    if (response && response.validity === 'valid') {
      setTokenIsValid(true)
    } else if (response && response.validity === 'invalid') {
      setTokenIsValid(false)
    } else {
      setTokenIsValid(false)
    }
    setTokenValidityLoading(false)
  }

  const handleKeyDown = (e: any) => {
    if (
      e.key === 'Enter' &&
      !passwordMatchError &&
      !newPasswordError &&
      newPassword.length >= 8 &&
      confirmation.length >= 8
    ) {
      setPassword()
    }
  }

  useEffect(() => {
    canUserSetPassword(token)
    // eslint-disable-next-line
  }, [])

  if (tokenValidityLoading) {
    return <CircularProgress />
  }

  const onKeyDown = (e: any) => {
    if (e.getModifierState("CapsLock")) {
      setCapsLock(true);
    } else {
      setCapsLock(false);
    }
  };

  return (
    <div className="normal-login">
      {!tokenValidityLoading && tokenIsValid ? (
        <>
          <div className="label">{t('Set Your Password')}</div>
          <form
            className="login-form"
            noValidate
            autoComplete="off"
            onKeyDown={handleKeyDown}>
            <InputComponent
              label="password"
              icon="password"
              type="password"
              validate={inputPassword}
              // error={newPasswordError}
              focused={true}
              onKeyDown={onKeyDown}
            />
            <InputComponent
              label="password wiederholen"
              icon="password"
              type="password"
              validate={inputConfirmation}
              // error={confirmationError}
              onKeyDown={onKeyDown}
            />
            {capsLock && <div className="error-msg capsLock">{t('caps lock is turned on')}</div>}
            <div className="password-validation">
              <p style={{ color: newPassword ? ((newPassword.length < 8 || newPassword.length > 15) ? "red" : "#aacd34") : "" }}>
                <CheckCircleOutlineIcon />
                {t("length 8 to 15 characters")}
              </p>
              <p style={{ color: newPassword ? (specialChar.test(newPassword) ? "#aacd34" : "red") : "" }}>
                <CheckCircleOutlineIcon />
                {t("at least 1 special character")}
              </p>
              <p style={{ color: newPassword ? (capitalChar.test(newPassword) ? "#aacd34" : "red") : "" }}>
                <CheckCircleOutlineIcon />
                {t("at least 1 capital letter")}
              </p>
              <p style={{ color: newPassword ? (digitChar.test(newPassword) ? "#aacd34" : "red") : "" }}>
                <CheckCircleOutlineIcon />
                {t("at least 1 digit")}
              </p>
              <p style={{ color: confirmation ? (passwordMatchError ? "red" : "#aacd34") : "" }}>
                <CheckCircleOutlineIcon />
                {t("passwords do not match")}
              </p>
            </div>
            <Button
              variant="contained"
              className="btn"
              onClick={setPassword}
              disabled={
                passwordMatchError ||
                loading ||
                newPasswordError.length > 0 ||
                !newPassword ||
                !confirmation
              }
            >
              <span className="login-btn-content">
                {!loading && t('Save & login')}
                {loading && <CircularProgress color="primary" />}
              </span>
            </Button>
          </form>
        </>
      ) : (
        <>
          <img
            src={linkExpiredImage}
            className="link-expired-img"
            alt="outdated-link"
          />
          <p className="link-expired-text">
            Sorry, but this link is broken or outdated!
          </p>
        </>
      )}
    </div>
  )
}

export default NormalResetPasswordCompenent
