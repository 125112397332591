import "./confirm-dialog.scoped.scss";
import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
import { ConfirmDialogProps } from "../../types/confirm-dialog-props";
import { useTranslation } from "react-i18next";
import CheckIcon from "@material-ui/icons/Check";

export const ConfirmDialog = (props: ConfirmDialogProps) => {
  const handleClose = (value: any) => props.onClose(value);
  const [t] = useTranslation();

  return (
    <Modal show={props.show} onHide={handleClose.bind(null, false)} centered dialogClassName={props.dialogClassName ? props.dialogClassName : "modal-md"}>
      <Modal.Header closeButton>
        <Modal.Title>{props.dialogTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.dialogBody}</p>
        <p>{props.dialogExtraText}</p>
      </Modal.Body>
      <Modal.Footer>
        {props.cancelBtn && (
          <Button
            variant="outlined"
            color="primary"
            className="btn-secondary-theme"
            onClick={handleClose.bind(null, false)}>
            {t("cancel")}
          </Button>
        )}
        <Button
          onClick={handleClose.bind(null, true)}
          className="btn-primary-theme"
          variant="contained"
          color="primary">
          {props.showCheck ? <CheckIcon /> : null} {props.confirmBtnText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
