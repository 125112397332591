import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { icons } from "../../statics/icons";
import { GlobalContext } from "../../store";
import { CardComponent } from "../card/card.component";
import { SettingsService } from "../../services/settings/settings.service";
import './contact-info.component.scoped.scss';

export default function ContactInfo(props: any) {
    const [t] = useTranslation();
    const settingsService: SettingsService = new SettingsService();

    const emptyObj: any = {};

    const { user } = useContext(GlobalContext);
    const [contactData, setContactData] = useState(emptyObj);


    const getContactData = async () => {
        const res = await settingsService.getContactInfo();
        setContactData(res);
    };

    useEffect(() => {
        getContactData();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-6">
                    <div className="contact-card">
                        <CardComponent
                            icon={(user.type === "user" || user.type === 'auditor' || (user.role !== 'coordinator' && user.type === 'superUser')) ? icons.user : icons.user}
                            title={
                                (user.type === "user" || user.type === 'auditor' || (user.role !== 'coordinator' && user.type === 'superUser'))
                                    ? t("your act4 admin")
                                    : t("your act4 admin")
                            }
                            active={false}
                            minHeight="145px">
                            <div className="card-content">
                                <div className="row">
                                    <div className="card-main-text col-4 mt-3 mb-4">
                                        {(user.type === "user" || user.type === 'auditor' || (user.role !== 'coordinator' && user.type === 'superUser'))
                                            ? contactData.internalAdminName
                                            : contactData.externalAdminName}
                                    </div>
                                    <div className="col-6 mt-3 d-flex flex-column">
                                        <div className="card-sec-text mb-1">
                                            {(user.type === "user" || user.type === 'auditor' || (user.role !== 'coordinator' && user.type === 'superUser'))
                                                ? contactData.internalAdminEmail
                                                : contactData.externalAdminEmail}
                                        </div>
                                        <div className="card-sec-text">
                                            {(user.type === "user" || user.type === 'auditor' || (user.role !== 'coordinator' && user.type === 'superUser'))
                                                ? contactData.internalAdminNumber
                                                : contactData.externalAdminNumber}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardComponent>
                    </div>
                </div>
                <div className="col-6">
                    <div className="contact-card">
                        <CardComponent
                            icon={icons.support}
                            title={"ACT4 Support"}
                            active={false}
                            minHeight="145px"
                        >
                            <div className="card-content">
                                <div className="row">
                                    <div className="card-text col-5 mt-3 d-flex flex-column">
                                        <div className="card-main-text mb-2">
                                            {t("technical questions?")}
                                        </div>
                                        <div className="card-main-text">
                                            {t("organisational questions?")}
                                        </div>
                                    </div>
                                    <div className="col-6 mt-3 d-flex flex-column">
                                        <div className="card-sec-text mb-2">
                                            {/* {(user.type === "user" || user.type === 'auditor' || (user.role !== 'coordinator' && user.type === 'superUser'))
                                                ? contactData.techQuestionsEmail
                                                : "technik@compliance2b.com"} */}
                                            {contactData.techQuestionsEmail}
                                        </div>
                                        <div className="card-sec-text">
                                            {/* {(user.type === "user" || user.type === 'auditor' || (user.role !== 'coordinator' && user.type === 'superUser'))
                                                ? contactData.organisationQuestionEmail
                                                : "office@compliance2b.com" } */}
                                            {contactData.organisationQuestionEmail}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardComponent>
                    </div>
                </div>
            </div>
        </>
    )
}