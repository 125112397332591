import { Box } from "@material-ui/core";
import React from "react";
import { CircleIconProps, CircleIconStatus } from "../../types/circle-icon";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import useStyles from "./stylesheet";
const CircleIcon: React.FC<CircleIconProps> = ({ status, value }: any) => {
  const classes = useStyles();
  let boxWrapper = classes.circleIconWrapperProps;
  if (status === CircleIconStatus.ACTIVE) {
    boxWrapper = `${classes.circleIconWrapperProps} ${classes.active}`;
  }

  return (
    <>
      {status === CircleIconStatus.COMPLETED ? (
        <CheckCircleIcon htmlColor="#42b47f" fontSize="large" />
      ) : (
        <Box className={boxWrapper}>{value}</Box>
      )}
    </>
  );
};
export default CircleIcon;
