import React, { useContext, useState, useEffect } from "react";
import "./cases-filter.component.scoped.scss";
import { CasesService } from "../../services/cases/cases.service";
import { useTranslation } from "react-i18next";
import { CasesContext } from "../../store";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CaseFilter } from "../../types/case-filter";
import { periodsRange } from "../../statics/periods";

export default function CasesFilterComponent(props: any) {
  const [t] = useTranslation();
  const { submitFilter } = props;
  const casesService = new CasesService();
  const emptyArr: any = [];
  const { setFilter } = useContext(CasesContext);
  const [statuses, setStatuses]: any = useState(emptyArr);
  const [branches, setBranches]: any = useState(emptyArr);
  const [categories, setCategories]: any = useState(emptyArr);
  const [clerks, setClerk]: any = useState(emptyArr);
  const [activeFilter, setActiveFilter]: any = useState(new CaseFilter());
  const [period, setPeriod]: any = useState("");

  const inputChangeHandler = (event: any) => {
    const value = event?.target?.value;
    const name = event?.target?.name;
    let obj: any = {};
    if (name === "period") {
      setPeriod(value);
      obj = { ...value };
    } else {
      obj[name] = value;
    }
    const result: CaseFilter = { ...activeFilter, ...obj };
    setActiveFilter({ ...result });
    if ((result.categoryId || result.branchId || result.statusId || result.fromDate) && name !== "assigneeId") {
      getUsers(result);
    }
  };

  async function fetchData() {
    const statusRes = await casesService.listStatuses();
    statusRes.unshift({ id: "all", name: t("all") });
    setStatuses(statusRes);
    const categoriesRes = await casesService.listCategories();
    categoriesRes.unshift({ id: "all", name: t("all") });
    setCategories(categoriesRes);
    const branchesRes = await casesService.listBranches();
    branchesRes.unshift({ id: "all", name: t("all") });
    setBranches(branchesRes);
  }

  const getUsers = async (filter: CaseFilter) => {
    const clerksRes = await casesService.listClerks({ ...filter });
    clerksRes.unshift({ id: "all", name: t("all") });
    setClerk(clerksRes);
    if (filter.assigneeId) {
      let isClerkExist = false;
      for (let index = 0; index < clerksRes.length; index++) {
        const clerk = clerksRes[index];
        if (clerk.id === filter.assigneeId) {
          isClerkExist = true;
          break;
        }
      }
      if (!isClerkExist) {
        setActiveFilter({ ...filter, assigneeId: "" });
      }
    }
  };

  useEffect(() => {
    fetchData();
    getUsers({});
    // eslint-disable-next-line
  }, []);

  const submit = () => {
    setFilter({ ...activeFilter });
    submitFilter({ ...activeFilter });
  };

  return (
    <div className="theme-container cases-filters w-100">
      <div className="filters-head">
        <h2 className="title"> {t("filter cases")}</h2>
        <h6 className="sub-title">
          {" "}
          {t("refine the displayed cases using one or more filters")}
        </h6>
      </div>
      <div className="filters-form">
        <div className="filter-select">
          <FormControl
            className="select-input"
            style={{ width: "calc(100% - 20px)" }}
          >
            <InputLabel id="status">{t("status")}</InputLabel>
            <Select
              labelId="status"
              id="status-select"
              value={activeFilter.statusId}
              name="statusId"
              onChange={(event: any) => inputChangeHandler(event)}
              IconComponent={ExpandMoreIcon}
            >
              {statuses.map((item: any, index: any) => {
                return (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="filter-select">
          <FormControl
            className="select-input"
            style={{ width: "calc(100% - 20px)" }}
          >
            <InputLabel id="branch">{t("affected company")}</InputLabel>
            <Select
              labelId="branch"
              id="branch-select"
              value={activeFilter.branchId}
              name="branchId"
              onChange={(event: any) => inputChangeHandler(event)}
              IconComponent={ExpandMoreIcon}
            >
              {branches.map((item: any, index: any) => {
                return (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="filter-select">
          <FormControl
            className="select-input"
            style={{ width: "calc(100% - 20px)" }}
          >
            <InputLabel id="period">{t("period")}</InputLabel>
            <Select
              labelId="period"
              id="period-select"
              value={period}
              name="period"
              onChange={(event: any) => inputChangeHandler(event)}
              IconComponent={ExpandMoreIcon}
            >
              {periodsRange.map((item: any, index: any) => {
                return (
                  <MenuItem value={item.value} key={index}>
                    {t(item.name)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="filter-select">
          <FormControl
            className="select-input"
            style={{ width: "calc(100% - 20px)" }}
          >
            <InputLabel id="category">{t("category")}</InputLabel>
            <Select
              labelId="category"
              id="category-select"
              value={activeFilter.categoryId}
              name="categoryId"
              onChange={(event: any) => inputChangeHandler(event)}
              IconComponent={ExpandMoreIcon}
            >
              {categories.map((item: any, index: any) => {
                return (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="filter-select">
          <FormControl
            className="select-input"
            style={{ width: "calc(100% - 20px)" }}
          >
            <InputLabel id="clerk">{t("clerk")}</InputLabel>
            <Select
              labelId="clerk"
              id="clerk-select"
              value={activeFilter.assigneeId}
              name="assigneeId"
              onChange={(event: any) => inputChangeHandler(event)}
              IconComponent={ExpandMoreIcon}
            >
              {clerks.map((item: any, index: any) => {
                return (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="filter-btn-container">
          <Button
            onClick={submit}
            className="btn-primary-theme filter-btn"
            variant="contained"
            color="primary"
          >
            {t("filter")}
          </Button>
        </div>
      </div>
    </div>
  );
}
