import React from "react";
import { InputViewProps } from "../../types/input-view-props";
import "./input-view.component.scoped.scss";

export const InputViewComponent = (props: InputViewProps) => {
  const { value, label, icon, type, height, width, color } = props;

  return (
    <div
      className={`input-view ${type ? type : ""} ${!value ? "label-only" : ""}`}
      style={{
        height: height ? `${height}px` : "47px",
        width: width ? `${width}px` : "",
        backgroundColor: color ? `${color}` : "",
      }}
    >
      <div>
        <div className="input-view-label">{label}</div>
        <div className="input-view-value">
          <div>{value ? value : label}</div>
        </div>
      </div>
      {icon && (
        <div className="input-view-icon">
          <img src={icon} alt="" />
        </div>
      )}
    </div>
  );
};
