import React, { useState } from "react";
import "./user-search.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import ClearIcon from "@material-ui/icons/Clear";
import { CaseUser } from "../../types/case-user";
import { SettingsService } from "../../services/settings/settings.service";
import { Category } from "../../types/category";
import { icons } from "../../statics/icons";
import { CircularProgress, IconButton } from "@material-ui/core";

export const UserSearchComponent = (props: any) => {
  const settingsService: SettingsService = new SettingsService();
  const [t] = useTranslation();
  const { onUserChange, onError } = props;
  const obj: any = {};
  const arr: any[] = [];
  const [_typeahead, setTypeahead] = useState(obj);
  const [category, setCategory] = useState(props.category);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState(arr);
  // Track the index of the highlighted menu item.
  const labelKey: any = "name";
  const minCharTyped = 3;

  const onFocus = async () => {
    if (category.mainProcessor) {
      setLoading(true);
      const usersResult = await settingsService.searchUsers(category.mainProcessor);
      setUsers(usersResult);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleSearch = async (query: any) => {
    if (query && query.length >= minCharTyped) {
      setLoading(true);
      const usersResult = await settingsService.searchUsers(query);
      setUsers(usersResult);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } else {
      setUsers([]);
    }
  };

  const deleteUser = () => {
    const selected: Category = { ...category };
    selected.mainProcessorId = null;
    selected.mainProcessor = "";
    setCategory(selected);
    onUserChange(selected);
    checkCategoryAvailability(selected.id);
    setUsers([])
    setTimeout(() => {
      _typeahead.clear();
    }, 0);
  };

  const selectUser = (options: CaseUser[]) => {
    const selected: Category = { ...category };
    if (options.length === 0) {
      selected.mainProcessor = "";
      selected.mainProcessorId = null;
      checkCategoryAvailability(selected.id);
    } else {
      const option: CaseUser = options[0];
      selected.mainProcessor = option.name;
      selected.mainProcessorId = option.id;
      setError(false);
      onError(false);
      setTimeout(() => {
        _typeahead.blur();
      }, 0);
    }
    setCategory(selected);
    onUserChange(selected);
  };

  const checkCategoryAvailability = async (id: string) => {
    const res = await settingsService.isCategoryAvailable(id);
    setError(!res);
    onError(!res);
  };

  return (
    <>
      <div
        className={"typeahead-search categories-assign " + (error ? "required" : "")}>
        <AsyncTypeahead
          id="typeahead-search"
          isLoading={false}
          minLength={minCharTyped}
          defaultSelected={
            category.mainProcessor ? [category.mainProcessor] : undefined
          }
          onFocus={onFocus}
          onChange={selectUser}
          labelKey={labelKey}
          onSearch={handleSearch}
          onInputChange={handleSearch}
          options={users}
          placeholder={t("responsible")}
          promptText={(!loading && users.length === 0) ? "No matches found." : "Searching..."}
          emptyLabel={(!loading && users.length === 0) ? "No matches found." : "Searching..."}
          searchText="Searching..."
          ref={(ref: any) => setTypeahead(ref)}
          renderMenuItemChildren={(option: CaseUser, props: any) => (
            <div className="user-search-option">
              <div className="user-info">
                <img alt="" src={option.image ? option.image : icons.userImg} />
                <div className="user-name">{option.name}</div>
              </div>
            </div>
          )}
        />
      </div>
      <div
        className="d-flex align-items-center error-msg mt-1"
        style={{ visibility: error ? "visible" : "hidden" }}>
        <img src={icons.alert} alt="" className="alert-icon mr-2" />
        <div className="trim">
          Can’t be unassigned, because there is an open case with this category
        </div>
      </div>
      {category.mainProcessor && (
        <div className="typeahead-delete-icon">
          <IconButton aria-label="delete" onClick={deleteUser}>
            <ClearIcon className="user-delete" />
          </IconButton>
        </div>
      )}
      {loading && (
        <div className="typeahead-loading-icon"
          style={{ right: category.mainProcessor ? "40px" : "16px" }}>
          <CircularProgress color="inherit" />
        </div>
      )}
    </>
  );
};
