export class UserProfileForm {
  public firstName: string = "";
  public lastName: string = "";
  public title: string = "";
  public telephone: string = "";
  public companyEmail: string = "";
  public companyName: string = "";
  public companyStreet: string = "";
  public companyRegisterNumber: string = "";
  public companyPostalCode: string = "";
  public companyCity: string = "";
}