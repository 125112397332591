import { history } from "react-router-guard";
import axios from "axios";
import { config } from "../../config";
import { LocalStorageService } from "../local-storage/local-storage";
import { User } from "../../types/user";
const queryString = require("query-string");

export class AuthService {
  private localStorageService: LocalStorageService = new LocalStorageService();

  public login = async (username: string, password: string): Promise<any> => {
    const data = {
      username,
      password,
      client_id: "frontend",
      grant_type: "password",
    };
    try {
      const { keyCloakUrl } = config;
      const response = await axios.post(
        keyCloakUrl + "protocol/openid-connect/token",
        queryString.stringify(data)
      );
      return response.data;
    } catch (err) {
      return null;
    }
  };

  public refreshToken = async (): Promise<any> => {
    const refresh_token = this.localStorageService.getData("token").refresh_token;
    const data = {
      refresh_token,
      grant_type: "refresh_token",
      client_id: "frontend",
    };
    try {
      const { keyCloakUrl } = config;
      const response = await axios.post(
        keyCloakUrl + "protocol/openid-connect/token",
        queryString.stringify(data)
      );
      this.localStorageService.setData("token", response.data);
      return response.data;
    } catch (err) {
      this.localStorageService.clearAll();
      history.push("/login");
    }
  }

  public logout = async (): Promise<string> => {
    const refresh_token = this.localStorageService.getData("token")
      .refresh_token;
    const data = {
      refresh_token,
      client_id: "frontend",
    };
    try {
      const { keyCloakUrl } = config;
      await axios.post(
        keyCloakUrl + "protocol/openid-connect/logout",
        queryString.stringify(data)
      );
      const lang = this.localStorageService.getData("lang");
      localStorage.clear();
      this.localStorageService.setData("lang", lang);
      history.push("/login");
      return 'success';
    } catch (err: any) {
      return err;
    }
  };

  public account = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "users/me");
      return response.data;
    } catch (error) {
      this.localStorageService.clearAll();
      history.push("/login");
      return new User();
    }
  };

  public tenants = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "tenants");
      return response.data;
    } catch (error) {
      return [];
    }
  };

  public myTenants = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "users/me/tenants");
      return response.data;
    } catch (error) {
      return [];
    }
  };


  // public myLoginStatus = async (): Promise<any> => {
  //   try {
  //     const { serverUrl } = config;
  //     const response = await axios.get(serverUrl + "users/me/status");
  //     return response.data;
  //   } catch (error) {
  //     return {};
  //   }
  // };
}
