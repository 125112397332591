import "./login.layout.scoped.scss";
import React from "react";

export const LoginLayout = (props: any) => {
  const loginImage = "/auth-cover.svg";
  return (
    <div className="login-layout">
      <div className="login-form">
        <div>
          {props.children}
        </div>
      </div>
      <div className="panel">
        <div className="content">
          <img className="login-image" src={loginImage} alt="login" />
        </div>
      </div>
    </div>
  );
};
