import i18n from "../../i18n";

export class LangService {
  public getCurrentLang = (): string => {
    return i18n.language;
  }

  public setCurrentLang = (lang: string): void => {
    i18n.changeLanguage(lang);
  }
}
