import React, { useEffect, useState } from "react";
import "./reporter-configuration-content.component.scoped.scss";
import { SettingsService } from "../../../../services/settings/settings.service";

import ReporterTranslation from "../reporter-translation/reporter-translation.component";
import ReporterFields from "../reporter-fields/reporter-fields.component";
import ReporterDesign from "../reporter-design/reporter-design.component";
import { ReporterConfiguration } from "../../../../types/reporter-configuration";
import ReporterLinks from "../reporter-links/reporter-links.components";

export default function ReporterConfigurationContent() {
    const settingsService: SettingsService = new SettingsService();
    const [reporterConfiguration, setReporterConfiguration] = useState(new ReporterConfiguration());

    useEffect(() => {
        getReporterConfiguration();
        // eslint-disable-next-line
    }, [])


    /**
     * fetch reporter configuration
     * 1- fetch reporter design values
     * 2- fetch reporter fields values
     */

    const getReporterConfiguration = async () => {
        const response = await settingsService.getReporterConfiguration();
        const { links, fields, design } = response;
        setReporterConfiguration({ links, fields, design });
    }

    return (
        <>
            <section className="json-management">
                <ReporterTranslation />
                <ReporterFields data={reporterConfiguration.fields} />
                <ReporterDesign data={reporterConfiguration.design} />
                <ReporterLinks data={reporterConfiguration.links} />
            </section>
        </>
    )
}
