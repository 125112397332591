import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { LocalStorageService } from "../services/local-storage/local-storage";
const localStorageService: LocalStorageService = new LocalStorageService();

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false
    },
    lng: localStorageService.getData("lang") || 'de',
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;