import React from "react"
import Skeleton from "@material-ui/lab/Skeleton"
import "./card-info.component.scoped.scss"
import { useTranslation } from "react-i18next"
import { LabelRoundComponent } from "../label-round/label-round.component"
import moment from "moment"
import { CaseContext } from "../../store"


export const CardInfoComponent = (props: any) => {
  const [t] = useTranslation();
  const { caseDetails } = React.useContext(CaseContext);
  const { icon, title, value, loading } = props;

  return (
    <div className="card-info">
      {loading ? (
        <Skeleton
          variant="circle"
          height={25}
          width={25}
          style={{ marginRight: "15px" }}
        />
      ) : (
        <div className="card-info-icon">
          <img src={icon} alt="" />
        </div>
      )}

      <div className="card-info-body">
        {loading ? (
          <Skeleton variant="text" height={15} style={{ marginBottom: "5px" }} />
        ) : (
          <div className="card-info-title">
            {t(title)}
          </div>
        )}

        {loading ?
          (
            <Skeleton variant="text" height={15} />
          ) : title === "labels" && value && value.length === 0 ? (
            <div className="card-info-text dimmed">{t("no labels added")}</div>
          ) : (
            title !== "labels" &&
            title !== "involved company" &&
            title !== "involved people" &&
            title !== "involved department" && (
              <div className="card-info-text">
                {((title === 'start date' || title === 'end date') && value) ? moment(value).format('dddd, DD.MM.YYYY') : value}
              </div>
            )
          )
        }

        {!loading &&
          (title === "labels") && (
            <div className="card-info-labels">
              {
                Array.isArray(value) &&
                value.map((label: any, index: any) => {
                  return (
                    <div className="card-label" key={index}>
                      <LabelRoundComponent label={label} />
                    </div>
                  )
                })
              }
            </div>
          )
        }

        {!loading &&
          (title === "involved company" ||
            title === "involved people" ||
            title === "involved department") && (
            <div className="card-info-labels">
              {
                Array.isArray(value.split(",")) &&
                value.split(",").map((label: any, index: any) => {
                  return (
                    label !== '' ?
                      <div className="card-label" key={index}>
                        <LabelRoundComponent label={label} />
                      </div> : null
                  )
                })
              }
            </div>
          )
        }

        {title === 'reporter email' &&
          <div style={{ lineHeight: 1 }}>
            {
              (caseDetails && caseDetails.reporterActivated) ?
                <span className="valid-msg">{t("your email is already validated")}</span> :
                <span className="err-msg">{t("validate email msg")}</span>
            }
          </div>
        }

        {!loading && caseDetails.AI &&
          <div className="AI">
            {title === 'category' && caseDetails.AI.categories && caseDetails.AI.categories.length &&
              caseDetails.AI.categories.map((category: any, index: number) => {
                return (
                  <div className="AI-content categories mt-1" key={index}>
                    <span className="title">{category.name}</span>
                    <span className="percentage">{category.percentage}</span>
                  </div>
                )
              })
            }

            {title === 'country' && caseDetails.AI.locations && caseDetails.AI.locations.length &&
              caseDetails.AI.locations.map((location: any, index: number) => {
                return (
                  <div className="AI-content country mt-1" key={index}>
                    <span className="title">{location.name}</span>
                    <span className="percentage">{location.percentage}</span>
                  </div>
                )
              })
            }

            {title === 'affected company' && caseDetails.AI.affected_company &&
              <div className="AI-content company mt-1">
                <span className="title">{caseDetails.AI.affected_company}</span>
              </div>
            }

            {title === 'involved people' && caseDetails.AI.persons && caseDetails.AI.persons.length &&
              caseDetails.AI.persons.map((person: any, index: number) => {
                return (
                  <div className="AI-content persons mt-1" key={index}>
                    <span className="title">{person.name}</span>
                    <span className="percentage">{person.percentage}</span>
                  </div>
                )
              })
            }
            {title === 'involved department' && caseDetails.AI.departments && caseDetails.AI.departments.length &&
              caseDetails.AI.departments.map((department: any, index: number) => {
                return (
                  <div className="AI-content departments mt-1" key={index}>
                    <span className="title">{department.name}</span>
                    <span className="percentage">{department.percentage}</span>
                  </div>
                )
              })
            }
            {title === 'involved company' && caseDetails.AI.companies && caseDetails.AI.companies.length &&
              caseDetails.AI.companies.map((company: any, index: number) => {
                return (
                  <div className="AI-content companies mt-1" key={index}>
                    <span className="title">{company.name}</span>
                    <span className="percentage">{company.percentage}</span>
                  </div>
                )
              })
            }

            {title === 'estimated amount of damage' && caseDetails.AI.moneys && caseDetails.AI.moneys.length &&
              caseDetails.AI.moneys.map((money: any, index: number) => {
                return (
                  <div className="AI-content amount mt-1" key={index}>
                    <span className="title">{money.name}</span>
                    <span className="percentage">{money.percentage}</span>
                  </div>
                )
              })
            }

            {title === 'start date' && caseDetails.AI.start_date &&
              <div className="AI-content date mt-1">
                <span className="title">{moment(new Date(caseDetails.AI.start_date)).format('dddd, DD.MM.YYYY')}</span>
              </div>
            }

            {title === 'end date' && caseDetails.AI.end_date &&
              <div className="AI-content date mt-1">
                <span className="title">{moment(new Date(caseDetails.AI.end_date)).format('dddd, DD.MM.YYYY')}</span>
              </div>
            }

            {title === 'duration' && caseDetails.AI.end_date && caseDetails.AI.start_date &&
              <div className="AI-content date mt-1">
                <span className="title">
                  {moment(new Date(caseDetails.AI.start_date)).format('dddd, DD.MM.YYYY')} - {moment(new Date(caseDetails.AI.end_date)).format('dddd, DD.MM.YYYY')}
                </span>
              </div>
            }
          </div>
        }
      </div>
    </div >
  )
}
