import React from 'react';
import { LoginLayout } from '../../layouts/login/login.layout';
import './login.page.scoped.sass'
import { AuthProvider } from '../../store';
import { NormalLoginComponent } from '../../components/normal-login/normal-login.component';

export default function LoginPage() {
  return (
    <LoginLayout>
      <AuthProvider>
        <NormalLoginComponent />
      </AuthProvider>
    </LoginLayout>
  );
}
