import React, { useState } from "react";
import "./timeline-comment.component.scoped.scss";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { formatDate, turncate } from "../../helpers";
import Skeleton from "@material-ui/lab/Skeleton";
import { TimelineDeleteCommentComponent } from "../timeline-delete-comment/timeline-delete-comment.component";
import { TimelineFileComponent } from "../timeline-file/timeline-file.component";
import { File } from "../../types/file";
import { CaseContext } from "../../store";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';

export const TimelineCommentComponent = (props: any) => {
  const { caseDetails } = React.useContext(CaseContext);

  const [t] = useTranslation();
  const [expand, setExpand] = useState(false);
  const maxCommentChars = 80;
  return (
    <div className={`timeline-item-comment timeline-comment-${props.position}`}>
      <div
        className="timeline-badge"
        style={props.loading ? { top: "16px" } : {}}
      >
        <div
          className={!props.loading ? "bg-grey-dark" : ""}
        />
      </div>
      {!props.loading && (
        <div className={"timeline-dash grey-dark"}>
          <hr />
        </div>
      )}
      <div
        className={
          "d-flex justify-content-between align-items-center " +
          (props.position === "left" ? "flex-row-reverse" : "")
        }
      >
        <div className="timeline-user">
          {props.loading ? (
            <Skeleton
              variant="circle"
              height={30}
              width={30}
              className="skeleton-user-img"
            />
          ) : (
            <div className="user-img">
              <img src={props.icon} alt="" />
            </div>
          )}
          <div
            className={
              "user-info " +
              (props.loading && props.position === "left"
                ? "d-flex flex-column align-items-end"
                : "")
            }
          >
            {props.loading ? (
              <Skeleton variant="text" height={25} width={150} />
            ) : (
              <div className="user-name">
                {
                  props.user ? props.user :
                    caseDetails.isAnonymous
                      ? t("whistleblower")
                      : (caseDetails.reporterEmail ? caseDetails.reporterEmail : caseDetails.phoneNumber)
                }
                {(!props.user && !caseDetails.isAnonymous) && (
                  <span style={{ marginLeft: '0.3rem' }}>
                    {(caseDetails && caseDetails.reporterActivated) ? <CheckCircleOutlineIcon style={{ margin: '0', color: 'rgb(170, 205, 52)' }} /> : <CancelTwoToneIcon style={{ margin: '0' }} />}
                  </span>
                )}
              </div>
            )}
            {props.loading ? (
              <Skeleton variant="text" height={25} width={200} />
            ) : (
              <div className="comment-date">
                {formatDate(props.date, "dddd, DD.MM.YYYY")} {t("at")}{" "}
                {formatDate(props.date, "HH:mm")}
              </div>
            )}
          </div>
          {!props.loading &&
            expand &&
            props.comment.length > maxCommentChars && (
              <ExpandLessIcon
                className="expand-less"
                onClick={setExpand.bind(null, false)}
              />
            )}
          {!props.loading &&
            !expand &&
            props.comment.length > maxCommentChars && (
              <ExpandMoreIcon
                className="expand-more"
                onClick={setExpand.bind(null, true)}
              />
            )}
        </div>
        {!props.loading &&
          !props.isCompletedCase &&
          props.isAuthorizedUser &&
          (props.comment.length < maxCommentChars || expand) && (
            <TimelineDeleteCommentComponent id={props.id} tab={props.tab} />
          )}
      </div>
      {
        props.loading ? (
          <Skeleton
            variant="text"
            height={Math.random() * 71 + 30}
            className="comment-skeleton"
          />
        ) : (
          <div className="timeline-comment">
            {props.comment.length > maxCommentChars && !expand
              ? turncate(props.comment, maxCommentChars)
              : props.comment}
          </div>
        )
      }
      {
        !props.loading && props.files.length ? (
          <div className={"row " + (props.position === "left" ? "flex-row-reverse" : "")}>
            {props.files.map((file: File, index: number) => {
              return (
                <div className="col-6" key={index}>
                  <TimelineFileComponent
                    name={file.name}
                    type={file.type}
                    size={file.size}
                    url={file.url}
                  />
                </div>
              );
            })}
          </div>
        ) : null
      }
    </div >
  );
};
