import axios from "axios";
import { config } from "../../config";
import { CaseFilter } from "../../types/case-filter";
import { deleteEmptyProp } from "../../helpers";

export class CasesService {
  /*********** List Cases ***********/
  public list = async (filter?: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const params = deleteEmptyProp(filter);
      if (params.statusId === "all") {
        delete params.statusId;
      }
      if (params.categoryId === "all") {
        delete params.categoryId;
      }
      if (params.branchId === "all") {
        delete params.branchId;
      }
      if (params.assigneeId === "all") {
        delete params.assigneeId;
      }
      if (params.fromDate === "all" && params.toDate === "all") {
        delete params.fromDate;
        delete params.toDate;
      }
      const response = await axios.get(serverUrl + "cases", { params });
      return response.data;
    } catch (error) {
      return { data: [] };
    }
  };

  /*********** Lookups ***********/
  public listStatuses = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "case-status");
      return response.data;
    } catch (err) {
      return [];
    }
  };

  public listCategories = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "case-categories");
      return response.data;
    } catch (err) {
      return [];
    }
  };

  public listQuestions = async (categoryId: number): Promise<any> => {
    try {
      const { serverUrl } = config;
      const params = { categoryId }
      const response = await axios.get(serverUrl + "questions/questions-json", { params });
      return response.data;
    } catch (err) {
      return [];
    }
  }

  public importQuestions = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(serverUrl + "questions/import", data);
      return response.data;
    } catch (err: any) {
      return err.errors[0].message;
    }
  }

  public getAssignedCategories = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(
        serverUrl + "case-categories/assigned-categories"
      );
      return response.data;
    } catch (err) {
      return [];
    }
  };

  public listBranches = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "branches");
      return response.data;
    } catch (error) {
      return [];
    }
  };

  public listLands = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "lands");
      return response.data;
    } catch (err) {
      return [];
    }
  };

  public listPeriods = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "case-periods");
      return response.data;
    } catch (err) {
      return [];
    }
  };

  public listClerks = async (filter: CaseFilter): Promise<any> => {
    try {
      const { serverUrl } = config;
      const params: CaseFilter = deleteEmptyProp(filter);
      if (params.statusId === "all") {
        delete params.statusId;
      }
      if (params.assigneeId) {
        delete params.assigneeId;
      }
      const response = await axios.get(serverUrl + "users/filter", { params });
      return response.data;
    } catch (err) {
      return [];
    }
  };

  public getCasesResolutions = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "cases/resolutions");
      return response.data;
    } catch (error) {
      return [];
    }
  };

  public getCaseAmounts = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "cases/amounts");
      return response.data;
    } catch (error) {
      return [];
    }
  };

  public getCaseRelations = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "cases/relations");
      return response.data;
    } catch (error) {
      return [];
    }
  };
  /*********** End Lookups ***********/

  /*********** List case Details ***********/
  public details = async (id: string): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "cases/" + id);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  /*********** Description Tab ***********/
  public updateDescription = async (
    id: string,
    description: string
  ): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.put(
        serverUrl + "cases/" + id + "/description",
        { description }
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public updateOptionalInfo = async (id: string, data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.put(
        serverUrl + "cases/" + id + "/edit-optional-info",
        data
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public updateNoticeInfo = async (id: string, data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.put(
        serverUrl + "cases/" + id + "/edit-notice-info",
        data
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public updateBrief = async (id: string, brief: string): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.put(serverUrl + "cases/" + id + "/brief", {
        brief,
      });
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public searchUsers = async (id: string, name: string): Promise<any> => {
    try {
      const { serverUrl } = config;
      const params = { name };
      const response = await axios.get(
        serverUrl + "cases/" + id + "/search-users",
        {
          params,
        }
      );
      return response.data;
    } catch (error) {
      return [];
    }
  };

  public searchLabels = async (id: string, name: string): Promise<any> => {
    try {
      const { serverUrl } = config;
      const params = { name };
      const response = await axios.get(
        serverUrl + "cases/" + id + "/search-labels",
        { params }
      );
      return response.data;
    } catch (error) {
      return [];
    }
  };

  public getUsers = async (id: string): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(
        serverUrl + "cases/" + id + "/get-users"
      );
      return response.data;
    } catch (error) {
      return [];
    }
  };

  public updateUsers = async (id: string, usersIds: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "cases/" + id + "/manage-users",
        { usersIds }
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };
  /*********** End Description Tab ***********/

  /*********** Begin reporter Tab ***********/
  public caseComments = async (id: string, items?: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      let params = {};
      if (items) {
        params = { items };
      }
      const response = await axios.get(
        serverUrl + "cases/" + id + "/comments",
        { params }
      );
      return response.data;
    } catch (error) {
      return [];
    }
  };

  public addReporterComment = async (
    id: string,
    statusId: number,
    files: any[],
    comment: string
  ): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "comments/cases/" + id + "/add",
        {
          statusId,
          files,
          comment,
        }
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public deleteReporterComment = async (commentId: string): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.delete(serverUrl + "comments/" + commentId);
      return response.data;
    } catch (error) {
      return {};
    }
  };
  /*********** End reporter Tab ***********/

  /*********** Begin Workflow Tab ***********/
  public caseQuestions = async (id: string): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "cases/" + id + "/workflow");
      return response.data;
    } catch (error) {
      return [];
    }
  };

  public answerQuestion = async (id: string, answer: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "questions/" + id + "/answer",
        { answer }
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public changeQuestionDueDate = async (
    id: string,
    dueDate: any
  ): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.put(
        serverUrl + "questions/" + id + "/due-date/edit",
        { dueDate }
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public addWorkflowComment = async (
    id: string,
    payload: any
  ): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "questions/" + id + "/add-comment",
        payload
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public deleteWorkflowComment = async (id: string): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.delete(
        serverUrl + "questions/remove-comment/" + id
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public updateWorkflowComment = async (
    id: string,
    payload: any
  ): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.put(
        serverUrl + "questions/edit-comment/" + id,
        payload
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public updateWorkflowStatus = async (
    id: string,
    payload: any
  ): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.put(
        serverUrl + "cases/" + id + "/update-status",
        payload
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };
  /*********** End Workflow Tab ***********/

  /*********** Begin Files Tab ***********/
  public caseFiles = async (id: string): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "cases/" + id + "/files");
      return response.data;
    } catch (error) {
      return [];
    }
  };

  public saveFileAndComment = async (
    caseId: number,
    statusId: number,
    file: any,
    comment: string
  ): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "cases/" + caseId + "/save-file",
        {
          statusId,
          ...file,
          comment,
        }
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public addFilesComment = async (
    id: string,
    payload: any,
    fileId: string
  ): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "cases/" + id + "/add-file-comment/" + fileId,
        payload
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public deleteFile = async (id: string, fileId: string): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.delete(
        serverUrl + "cases/" + id + "/delete-file/" + fileId
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };
  /*********** End Files Tab ***********/

  /*********** Begin communication Tab ***********/
  public getCaseCommunication = async (id: string): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(
        serverUrl + "cases/" + id + "/communication"
      );
      return response.data;
    } catch (error) {
      return [];
    }
  };

  public addCommunicationComment = async (
    id: string,
    statusId: number,
    files: any[],
    comment: string
  ): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "communication/cases/" + id + "/add",
        {
          statusId,
          files,
          comment,
        }
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public deleteCommunicationComment = async (
    commentId: string
  ): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.delete(
        serverUrl + "communication/" + commentId
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };
  /*********** End communication Tab ***********/

  public requestSignedUrl = async (
    fileName: string,
    fileType: string,
    category: string,
    caseId: string
  ): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(serverUrl + "cases/sign-file", {
        fileName,
        fileType,
        caseId,
        category
      });
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public uploadToSignedURL = async (
    signedRequest: string,
    file: any,
    cancelToken: any,
    onUploadProgress: any
  ): Promise<any> => {
    try {
      const response = await axios.put(signedRequest, file, {
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        cancelToken,
        onUploadProgress,
      });
      return response.data;
    } catch (error) {
      return "canceled";
    }
  };

  public resendActivation = async (id: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "cases/resend-activation",
        { id }
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };
}
