import React from "react";
import { CommonService } from "../../services/common/common.service";
import { useTranslation } from "react-i18next";
import HeaderComponent from "../../components/header/header.component";
import DashboardContent from "../../components/dashboard-content/dashboard-content.component";

export default function DashboardPage() {
  const [t] = useTranslation();
  const commonService: CommonService = new CommonService();
  commonService.setTitle(t("dashboard"));
  return (
    <>
      <HeaderComponent />
      <DashboardContent />
    </>
  );
}
