import React from "react";

const FallbackPage = () => {
  return (
    <div className="fallback-image">
      <img src="/fallback.png" alt="fallback" />
    </div>
  );
};

export default FallbackPage;
