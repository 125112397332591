import "./edit-optInfo.scoped.scss"
import React, { useState, useContext } from "react"
import { useTranslation, Trans } from "react-i18next"
import Modal from "react-bootstrap/Modal"
import Button from "@material-ui/core/Button"
import { CasesService } from "../../services/cases/cases.service"
import { CaseContext } from "../../store"
import CheckIcon from "@material-ui/icons/Check"
import CircularProgress from "@material-ui/core/CircularProgress"
import { icons } from "../../statics/icons"
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import "moment/locale/de"
import MomentUtils from "@date-io/moment"
import { formatDate } from "../../helpers"
import InfoQuestion from "../../components/info-question/Info-question.component"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  // TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core"
import moment from "moment"
import LastUpdatedAt from "../../components/last-updated-at/last-updated.component"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import EventOutlinedIcon from "@material-ui/icons/EventOutlined"
import { MapperService } from "../../services/mapper/mapper.service"
import { LangService } from "../../services/lang/lang.service"
import { InputViewComponent } from "../../components/input-view/input-view.component"
import ChipInput from "material-ui-chip-input"

const EditOptionalInfo = (props: any) => {
  const removeEmojiRegex = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;
  const [t] = useTranslation()
  const casesService = new CasesService()
  const langService: LangService = new LangService()
  const mapperService = new MapperService()
  const locale = langService.getCurrentLang()
  moment.locale(locale)
  const {
    caseDetails,
    setLoading,
    setCase,
    caseStatuses,
    caseAmounts,
    caseRelations,
  } = useContext(CaseContext);
  const [saving, setSaving] = useState(false);
  const [selectedEndDate, setSelectedEndDate] = useState(caseDetails.endDate ? moment(caseDetails.endDate) : null);
  const [selectedStartDate, setSelectedStartDate] = useState(caseDetails.startDate ? moment(caseDetails.startDate) : null);
  const [caseOngoing, setCaseOngoing] = useState(caseDetails.caseOngoing ? true : false);
  const [datesInvalid, setDatesInvalid] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const [formData, setFormData] = useState({
    involvedPersons: caseDetails.involvedPersons,
    involvedDepartment: caseDetails.involvedDepartment,
    involvedCompany: caseDetails.involvedCompany,
    amountId: caseDetails.amountId,
    relationId: caseDetails.relationshipId,
  })

  const {
    involvedPersons,
    involvedDepartment,
    involvedCompany,
    amountId,
    relationId,
  } = formData;

  const handleClose = (value: any) => {
    props.onClose(value);
    setFormChanged(false);
    if (!value) {
      setFormData({
        involvedPersons: caseDetails.involvedPersons,
        involvedDepartment: caseDetails.involvedDepartment,
        involvedCompany: caseDetails.involvedCompany,
        amountId: caseDetails.amountId,
        relationId: caseDetails.relationshipId,
      })
      setSelectedEndDate(
        caseDetails.endDate ? moment(caseDetails.endDate) : null
      )
      setSelectedStartDate(
        caseDetails.startDate ? moment(caseDetails.startDate) : null
      )
      setCaseOngoing(caseDetails.caseOngoing ? true : false)
      setDatesInvalid(false)
    }
  }

  async function fetchCaseDetails(id: string) {
    setLoading(true)
    const caseDetailsData = await casesService.details(id)
    const { mappedCase } = mapperService.mapCase(caseDetailsData, caseStatuses)
    setCase(mappedCase)
    setLoading(false)
  }

  const formatRequestBody = (...args: any): any => {
    const body: any = {}
    for (let index = 0; index < args.length; index++) {
      const item = args[index]
      if (item.value !== caseDetails[item.name]) {
        body[item.name] = item.value
      }
    }
    return body
  }

  const formatDatesObject = (...args: any): any => {
    const body: any = {}
    for (let index = 0; index < args.length; index++) {
      const item = args[index]
      if (
        (item.value ? moment(item.value).format("YYYY-MM-DD hh:mm:ss") : item.value) !==
        (caseDetails[item.name] ? moment(caseDetails[item.name]).format("YYYY-MM-DD hh:mm:ss")
          : caseDetails[item.name])
      ) {
        body[item.name] = item.value
      }
    }
    return body
  }

  const handleUpdate = async () => {
    setSaving(true)
    const bodyObject = formatRequestBody(
      { name: "involvedPersons", value: involvedPersons },
      { name: "involvedDepartment", value: involvedDepartment },
      { name: "involvedCompany", value: involvedCompany },
      { name: "amountId", value: amountId },
      { name: "relationshipId", value: relationId },
      { name: "caseOngoing", value: caseOngoing }
    )

    const dates = formatDatesObject(
      { name: "endDate", value: selectedEndDate ? moment(selectedEndDate).format("YYYY-MM-DD hh:mm:ss") : null },
      { name: "startDate", value: selectedStartDate ? moment(selectedStartDate).format("YYYY-MM-DD hh:mm:ss") : null }
    )

    const data = { ...bodyObject, ...dates }

    await casesService.updateOptionalInfo(caseDetails.id, data)
    setSaving(false);
    fetchCaseDetails(caseDetails.id);
    handleClose(null);
  }

  const onChangeHandler = (e: any) => {
    setFormChanged(true);
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const isValidDate = (date: any) => date && date.isValid()

  const onStartDateChangeHandler = (startDate: any) => {
    setSelectedStartDate(startDate)
    checkFormDates(startDate, selectedEndDate, caseOngoing);
    setFormChanged(true);
  }

  const onEndDateChangeHandler = (endDate: any) => {
    setSelectedEndDate(endDate)
    checkFormDates(selectedStartDate, endDate, caseOngoing);
    setFormChanged(true);
  }

  const checkFormDates = (startDate: any, endDate: any, ongoing: Boolean) => {
    if (
      ongoing ||
      (startDate === null && endDate === null) ||
      (isValidDate(startDate) &&
        isValidDate(endDate) &&
        startDate.isSameOrBefore(endDate))
    ) {
      setDatesInvalid(false)
      return
    }
    setDatesInvalid(true)
  }

  const handleAddPersonClick = (chip: string) => {
    if (chip.length > 50) return;
    const person = handleChipInput(chip);
    if (!person) return;
    const newInvolvedPersonsCopy = involvedPersons.length ? involvedPersons.split(',') : [];
    newInvolvedPersonsCopy.push(person);
    setFormData({
      ...formData,
      involvedPersons: newInvolvedPersonsCopy.toString()
    })
  }

  const handlePersonDelete = (chip: string) => {
    setFormChanged(true);
    const newOriginalInvolvedPersons = involvedPersons
      .split(",")
      .filter((item: string) => item !== chip)
      .join()
    setFormData({ ...formData, involvedPersons: newOriginalInvolvedPersons })
  }

  const handleAddDepartmentClick = (chip: string) => {
    if (chip.length > 25) return;
    const department = handleChipInput(chip);
    if (!department) return;
    const newInvolvedDepartmentCopy = involvedDepartment.length ? involvedDepartment.split(',') : [];
    newInvolvedDepartmentCopy.push(department);
    setFormData({
      ...formData,
      involvedDepartment: newInvolvedDepartmentCopy.toString()
    })
  }

  const handleDepartmentDelete = (chip: string) => {
    setFormChanged(true);
    const newOriginalInvolvedDepartments = involvedDepartment
      .split(",")
      .filter((item: string) => item !== chip)
      .join()
    setFormData({
      ...formData,
      involvedDepartment: newOriginalInvolvedDepartments,
    })
  }

  const handleAddCompanyClick = (chip: string) => {
    if (chip.length > 20) return;
    const company = handleChipInput(chip);
    if (!company) return;
    const newInvolvedCompanyCopy = involvedCompany.length ? involvedCompany.split(',') : [];
    newInvolvedCompanyCopy.push(company);
    setFormData({
      ...formData,
      involvedCompany: newInvolvedCompanyCopy.toString()
    });
  }

  const handleCompanyDelete = (chip: string) => {
    setFormChanged(true);
    const newOriginalInvolvedCompanys = involvedCompany
      .split(",")
      .filter((item: string) => item !== chip)
      .join()
    setFormData({
      ...formData,
      involvedCompany: newOriginalInvolvedCompanys,
    })
  }

  const handleChipInput = (value: string) => {
    setFormChanged(true);
    let text = value.replace(removeEmojiRegex, '');
    if (text[0] === ',' || text[0] === ' ') text = text.substring(1);
    if (text[text.length - 1] === ',' || text[text.length - 1] === ' ') text = text.slice(0, -1);
    if ((text[0] === ',' && text[1] === ' ') || (text[0] === ' ' && text[1] === ',')) text = text.substring(2);
    if ((text[text.length - 1] === ',' && text[text.length - 2] === ' ') || (text[text.length - 1] === ' ' && text[text.length - 2] === ',')) text = text.slice(0, -2);
    text = text.replace(',,', ',');
    text = text.replace(', ', ',');
    text = text.replace(' ,', ',');
    if (text === ',' || text === '' || text === ' ') return;
    return text;
  }

  return (
    <Modal
      show={props.show}
      onHide={handleClose.bind(null, false)}
      centered
      dialogClassName="modal-xl">
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans
            i18nKey="edit_case_details"
            values={{ internalId: caseDetails.internalId }}
          ></Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <div className="row">
            <div className="col" />
            <div className="col main-text mb-2">{t("original input")}</div>
            <div className="col main-text mb-2">{t("current input")}</div>
          </div>

          {/* involved persons section */}

          <div className="row mb-3 ">
            <InfoQuestion
              icon={icons.involvedPeople}
              label={t("involved people")}
              question={t("who was involved in the case")}
            />

            <div className="col">
              <InputViewComponent
                value={caseDetails.originalInvolvedPersons}
                label={t("involved people")}
                height={66}
              />
            </div>
            <div className="col opt-info-field rel-wrap chip-input-field">
              <ChipInput
                value={involvedPersons.length ? involvedPersons.split(",") : []}
                onAdd={(chip) => handleAddPersonClick(chip)}
                onDelete={(chip) => handlePersonDelete(chip)}
                className="w-100 chip-input-field"
                label={t("involved people")}
                variant="filled"
              />

              <LastUpdatedAt
                mainProcessor={caseDetails.involvedPersonsUpdatedAdmin}
                updatedAt={caseDetails.involvedPersonsUpdatedAt}
              />
            </div>
          </div>

          {/* department section */}

          <div className="row mb-3 ">
            <InfoQuestion
              icon={icons.involvedDepartments}
              label={t("involved department")}
              question={t("which department is involved?")}
            />

            <div className="col">
              <InputViewComponent
                value={caseDetails.originalInvolvedDepartment}
                label={t("involved department")}
              />
            </div>
            <div className="col">
              <ChipInput
                value={involvedDepartment.length ? involvedDepartment.split(",") : []}
                onAdd={(chip) => handleAddDepartmentClick(chip)}
                onDelete={(chip) => handleDepartmentDelete(chip)}
                className="w-100 chip-input-field"
                label={t("involved department")}
                variant="filled"
              />
              <LastUpdatedAt
                mainProcessor={caseDetails.involvedDepartmentUpdatedAdmin}
                updatedAt={caseDetails.involvedDepartmentUpdatedAt}
              />
            </div>
          </div>

          {/* involved company section */}

          <div className="row mb-3 ">
            <InfoQuestion
              icon={icons.company}
              label={t("involved company")}
              question={t("what are the involved companies?")}
            />

            <div className="col">
              <InputViewComponent
                value={caseDetails.originalInvolvedCompany}
                label={t("involved company")}
              />
            </div>
            <div className="col">
              <ChipInput
                value={involvedCompany.length ? involvedCompany.split(",") : []}
                onAdd={(chip) => handleAddCompanyClick(chip)}
                onDelete={(chip) => handleCompanyDelete(chip)}
                className="w-100 chip-input-field"
                label={t("involved company")}
                variant="filled"
                fullWidth
              />
              <LastUpdatedAt
                mainProcessor={caseDetails.involvedCompanyUpdatedAdmin}
                updatedAt={caseDetails.involvedCompanyUpdatedAt}
              />
            </div>
          </div>

          {/* duration section */}

          <div className="row mb-3 ">
            <InfoQuestion
              icon={icons.date}
              label={t("duration")}
              question={t("from when to when did the case occur?")}
            />

            <div className="col">
              <div className="w-100 mb-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={caseDetails.originalCaseOngoing}
                      name="caseOngoing"
                      color="primary"
                    />
                  }
                  label="Ongoing case"
                  disabled={true}
                />
                <InputViewComponent
                  value={formatDate(
                    caseDetails.originalStartDate,
                    "DD/MM/YYYY"
                  )}
                  label={t("start date")}
                  icon={icons.datePicker}
                />
              </div>
              {!caseDetails.originalCaseOngoing && (
                <div className="w-100">
                  <InputViewComponent
                    value={formatDate(
                      caseDetails.originalEndDate,
                      "DD/MM/YYYY"
                    )}
                    label={t("end date")}
                    icon={icons.datePicker}
                  />
                </div>
              )}
            </div>
            <div className="col">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={caseOngoing}
                    onChange={(e: any) => {
                      setCaseOngoing(e.target.checked)
                      setFormChanged(true)
                      checkFormDates(
                        selectedStartDate,
                        selectedEndDate,
                        e.target.checked
                      )
                      setSelectedEndDate(null)
                    }}
                    name="caseOngoing"
                    color="primary"
                  />
                }
                label="Ongoing case"
              />

              <MuiPickersUtilsProvider
                utils={MomentUtils}
                libInstance={moment}
                locale={locale}
              >
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  inputVariant="filled"
                  fullWidth
                  format="DD/MM/YYYY"
                  autoOk
                  value={selectedStartDate}
                  maxDate={selectedEndDate ? selectedEndDate : undefined}
                  maxDateMessage={
                    selectedEndDate
                      ? t("the start date should be less than the end date")
                      : ""
                  }
                  minDateMessage=""
                  invalidDateMessage={t("invalid date format")}
                  onChange={(date: any): any => onStartDateChangeHandler(date)}
                  label={t("start date")}
                  keyboardIcon={<EventOutlinedIcon />}
                  error={
                    (selectedStartDate && !isValidDate(selectedStartDate)) ||
                    (isValidDate(selectedEndDate) && datesInvalid)
                  }
                />
              </MuiPickersUtilsProvider>

              <div className={selectedStartDate !== null && !selectedStartDate.isValid() ? "" : "mt-2"}>
                {!caseOngoing && (
                  <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    libInstance={moment}
                    locale={locale}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      inputVariant="filled"
                      fullWidth
                      format="DD/MM/YYYY"
                      autoOk
                      value={selectedEndDate}
                      minDate={selectedStartDate ? selectedStartDate : undefined}
                      minDateMessage={selectedStartDate
                        ? t("the end date should be greater than the end date")
                        : ""}
                      maxDateMessage=""
                      invalidDateMessage={t("invalid date format")}
                      onChange={(date: any): any => onEndDateChangeHandler(date)}
                      label={t("end date")}
                      keyboardIcon={<EventOutlinedIcon />}
                      error={
                        (selectedEndDate && !isValidDate(selectedEndDate)) ||
                        (isValidDate(selectedStartDate) && datesInvalid)
                      }
                    />
                  </MuiPickersUtilsProvider>
                )}
              </div>
              <LastUpdatedAt
                mainProcessor={caseDetails.startAndEndDateUpdatedAdmin}
                updatedAt={caseDetails.startAndEndUpdatedAt}
              />
            </div>
          </div>

          {/* damage estimation section */}

          <div className="row mb-3 ">
            <InfoQuestion
              icon={icons.amountOfDamage}
              label={t("estimated amount of damage")}
              question={t("how much is the estimated amount of damage?")}
            />

            <div className="col">
              <div className="mb-2">
                <InputViewComponent
                  value={caseDetails.originalAmount}
                  label={t("damage amount")}
                  icon={icons.chevronDown}
                  type="select"
                />
              </div>
            </div>

            <div className="col">
              <FormControl className="select-input select-grey w-100">
                <InputLabel id="damage-amount">{t("damage amount")}</InputLabel>
                <Select
                  labelId="damage-amount"
                  id="damage-amount-select"
                  name="amountId"
                  value={amountId ? amountId : null}
                  onChange={onChangeHandler}
                  IconComponent={ExpandMoreIcon}
                >
                  {caseAmounts.map((item: any, index: any) => {
                    if (item.value === null) {
                      return (
                        <MenuItem value={item.id} key={index}>
                          0
                        </MenuItem>
                      )
                    }
                    return (
                      <MenuItem value={item.id} key={index}>
                        {item.value}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>

              <LastUpdatedAt
                mainProcessor={caseDetails.amountUpdatedAdmin}
                updatedAt={caseDetails.amountUpdatedAt}
              />
            </div>
          </div>

          <div className="row mb-1">
            <InfoQuestion
              icon={icons.relation}
              label={t("relation")}
              question={t("relation text")}
            />

            <div className="col">
              <div className="mb-2">
                <InputViewComponent
                  value={caseDetails.originalRelationship}
                  label={t("relation")}
                  icon={icons.chevronDown}
                  type="select"
                />
              </div>
            </div>
            <div className="col">
              <FormControl className="select-input select-grey w-100">
                <InputLabel id="relation">{t("relation")}</InputLabel>
                <Select
                  labelId="relation"
                  id="relation-select"
                  name="relationId"
                  value={relationId}
                  onChange={onChangeHandler}
                  IconComponent={ExpandMoreIcon}
                >
                  {caseRelations.map((item: any, index: any) => {
                    if (item.name === null) {
                      return (
                        <MenuItem value={item.id} key={index}>
                          ---------
                        </MenuItem>
                      )
                    }
                    return (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <LastUpdatedAt
                mainProcessor={caseDetails.relationshipUpdatedAdmin}
                updatedAt={caseDetails.relationshipUpdatedAt}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outlined"
          color="primary"
          className="btn-secondary-theme"
          onClick={handleClose.bind(null, false)}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="btn-primary-theme"
          onClick={handleUpdate}
          disabled={datesInvalid || !formChanged}
        >
          {saving ? (
            <CircularProgress color="inherit" />
          ) : (
            <>
              <CheckIcon /> {t("save")}{" "}
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditOptionalInfo
