export default (state: any, action: any) => {
  switch (action.type) {
    case "SET_LOGS":
      return {
        ...state,
        logs: action.payload.logs,
      };
    case "SET_PAGINATION":
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: action.payload.params.currentPage,
          items: action.payload.params.items,
          totalPages: action.payload.params.totalPages,
          total: action.payload.params.total,
        }
      };
    case "SORT_LOGS":
      return {
        ...state,
        options: {
          ...state.options,
          sortDirection: action.payload.params.sortDirection,
          sortColumn: action.payload.params.fieldName,
        }
      };
    default:
      return state;
  }
};