import React, { useState } from "react";
import "./description-tab.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { CaseContext, GlobalContext } from "../../store";
import { CardComponent } from "../card/card.component";
import { icons } from "../../statics/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import { EditDescDialog } from "../../dialogs/edit-desc/edit-desc";
import { sanitize, formatDate, showToastMsg } from "../../helpers";
import { EditBriefDialog } from "../../dialogs/edit-brief/edit-brief";
import { CardInfoComponent } from "../card-info/card-info.component";
import { caseNoticeInfo } from "../../statics/case-notice-info";
import { caseDetailsInfo } from "../../statics/case-details-info";
import EditOptionalInfo from "../../dialogs/edit-optInfo/edit-optInfo";
import EditNoticeInfo from "../../dialogs/edit-noticeInfo/edit-noticeInfo";
import { reporterDetails } from "../../statics/reporter-details";
import { Button } from "@material-ui/core";
import { CasesService } from "../../services/cases/cases.service";
import { AiDescriptionModal } from "../../dialogs/AI-description/AI-description";
import TelegramIcon from '@material-ui/icons/Telegram';

export const DescriptionTabComponent = (props: any) => {
  const AIEntityColor: any = {
    person: "#faa33b",
    role: "#faa33b",
    money: "#ee6a22",
    date: "#f3a79e",
    organization: "#046bb2",
    department: "#046bb2",
    location: "#aacd34"
  }
  const casesService = new CasesService();

  const { caseDetails, loading, completedStatusId } = React.useContext(
    CaseContext
  );

  const { user } = React.useContext(GlobalContext);
  const [showDescModal, setShowDescModal] = React.useState(false);
  const [showBriefModal, setShowBriefModal] = React.useState(false);
  const [showNoticeInfoModal, setShowNoticeInfoModal] = React.useState(false);
  const [btnActivation, setBtnActivation] = React.useState(false);
  const [showAiModel, setShowAiModel] = useState(false);
  const [AIModelData, setAIModelData] = useState([]);

  const [
    showOptionalDetailsModal,
    setShowOptionalDetailsModal,
  ] = React.useState(false);
  const [t] = useTranslation();

  const handleModalOpen = (type: string) => {
    switch (type) {
      case "desc":
        setShowDescModal(true);
        break;
      case "brief":
        setShowBriefModal(true);
        break;
      case "optional-info":
        setShowOptionalDetailsModal(true);
        break;
      case "notice-info":
        setShowNoticeInfoModal(true);
        break;
      default:
        break;
    }
  };

  const handleModalClose = (type: any, value: any) => {
    switch (type) {
      case "desc":
        setShowDescModal(false);
        break;
      case "brief":
        setShowBriefModal(false);
        break;
      case "optional-info":
        setShowOptionalDetailsModal(false);
        break;
      case "notice-info":
        setShowNoticeInfoModal(false);
        break;
      default:
        break;
    }
  };

  const handleAiDescriptionClose = (value: any) => {
    setTimeout(() => {
      setShowAiModel(false);
    }, 0);
  };

  // optionalFieldsUpdateAdmin
  const lastEditOptionalOrNoticeFields = (type: string) => {
    const updatedAt =
      type === "opt-info"
        ? caseDetails.optionalFieldsLastUpdateDate
        : caseDetails.noticeFieldsLastUpdateDate;
    const updatedBy =
      type === "opt-info"
        ? caseDetails.optionalFieldsUpdateAdmin
        : caseDetails.noticeFieldsUpdateAdmin;
    const DateFormat = formatDate(
      updatedAt ? updatedAt : caseDetails.createdAt,
      "dddd, DD.MM.YYYY"
    );
    const DateTime = formatDate(
      updatedAt ? updatedAt : caseDetails.createdAt,
      "HH:mm"
    );
    const mainProcessor = updatedBy ? updatedBy : t("reporter");
    return `${t("edit on")} ${DateFormat} ${t("at")} ${DateTime} ${t(
      "by"
    )} ${mainProcessor}`;
  };

  const lastEdit = (type: string) => {
    const updatedAt =
      type === "desc"
        ? caseDetails.descriptionUpdatedAt
        : caseDetails.briefUpdatedAt;
    const updatedBy =
      type === "desc"
        ? caseDetails.descriptionUpdateAdmin
        : caseDetails.briefUpdateAdmin;
    const DateFormat = formatDate(
      updatedAt ? updatedAt : caseDetails.createdAt,
      "dddd, DD.MM.YYYY"
    );
    const DateTime = formatDate(
      updatedAt ? updatedAt : caseDetails.createdAt,
      "HH:mm"
    );
    const mainProcessor = updatedBy ? updatedBy : t("reporter");
    return `${t("edit on")} ${DateFormat} ${t("at")} ${DateTime} ${t(
      "by"
    )} ${mainProcessor}`;
  };

  const snedActivation = async () => {
    setBtnActivation(true);
    const value = await casesService.resendActivation(caseDetails.id);
    if (value) {
      setBtnActivation(false);
      showToastMsg("success", t(value.message));
    }
  }

  const getFormatedDescription = () => {
    let AI_description = '';
    let characterPointer = 0;
    caseDetails.AI.entities.sort((a: any, b: any) => a.position[0] - b.position[0]);
    caseDetails.AI.entities.forEach((entity: any, index: any) => {

      AI_description += caseDetails.AI.description.substring(characterPointer, entity.position[0]);
      AI_description += `<span key=${index} id="${index}" style="background-color: ${AIEntityColor[entity.annotation]}; ${(caseDetails.ai_explination ? 'cursor: pointer' : 'cursor: inherit')}">`;
      AI_description += caseDetails.AI.description.substring(entity.position[0], entity.position[1]);
      AI_description += '</span>';
      characterPointer = entity.position[1];
    });
    // add the remaining text after markers
    AI_description += caseDetails.AI.description.substring(characterPointer);
    return AI_description;
  }


  const onClickDescription = (e: any) => {
    const index = e.target.id;
    if (index && caseDetails.ai_explination) {
      const entity = caseDetails.AI.entities[index];
      if (entity.ner_explanation_html && entity.ner_explanation_html.length) {
        setAIModelData(entity.ner_explanation_html);
        setShowAiModel(true);
      }
    }
  }

  return (
    <div className="theme-container description-tab">
      <div className="row">
        <div className="col-6 d-flex flex-column">
          <div className="brief-card mb-4">
            <CardComponent
              icon={icons.trackReport}
              title={t("brief description")}
              subTitle={lastEdit("brief")}
              isEditable={
                (user.keycloakUserId === caseDetails.mainProcessorId || user.type !== "auditor") &&
                caseDetails.statusId !== completedStatusId
              }
              editEmitter={() => handleModalOpen("brief")}
              loading={loading}
              minHeight="200px"
            >
              {loading ? (
                <Skeleton variant="text" height={100} />
              ) : (
                <div
                  className="brief-body"
                  dangerouslySetInnerHTML={sanitize(caseDetails.brief)}
                />
              )}
            </CardComponent>
            <EditBriefDialog
              show={showBriefModal}
              onClose={(value: any) => handleModalClose("brief", value)}
            />
          </div>
          <div className="desc-card">
            <CardComponent
              icon={icons.trackReport}
              title={t("note description")}
              subTitle={lastEdit("desc")}
              isEditable={
                (user.keycloakUserId === caseDetails.mainProcessorId || user.type !== "auditor") &&
                caseDetails.statusId !== completedStatusId
              }
              editEmitter={() => handleModalOpen("desc")}
              loading={loading}
            >
              {loading ? (
                <Skeleton variant="text" height={350} />
              ) : (
                <div
                  className="desc-body"
                  onClick={onClickDescription}
                  dangerouslySetInnerHTML={caseDetails.AI ? { __html: getFormatedDescription() } : (sanitize(caseDetails.description))}
                />
              )}
            </CardComponent>
            <EditDescDialog
              show={showDescModal}
              onClose={(value: any) => handleModalClose("desc", value)}
            />
          </div>
        </div>
        <div className="col-6 d-flex flex-column">
          <div className="notice-card mb-4">
            <CardComponent
              icon={icons.infoDark}
              title={t("notice information")}
              subTitle={lastEditOptionalOrNoticeFields("notice-info")}
              isEditable={
                (user.keycloakUserId === caseDetails.mainProcessorId ||
                  user.type !== "auditor") &&
                caseDetails.statusId !== completedStatusId
              }
              editEmitter={() => handleModalOpen("notice-info")}
              loading={loading}
              minHeight="200px"
            >
              <div className="notice-body">
                <div className="row">
                  {caseNoticeInfo.map((item: any, index: any) => {
                    return (
                      (loading || (!loading && caseDetails[item.value])) && (
                        <div className={item.classes} key={index}>
                          <CardInfoComponent
                            icon={item.icon}
                            title={item.title}
                            value={caseDetails[item.value]}
                            loading={loading}
                          />
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
            </CardComponent>
            <EditNoticeInfo
              show={showNoticeInfoModal}
              onClose={(value: any) => handleModalClose("notice-info", value)}
            />
          </div>
          <div className={`details-card ${caseDetails.isAnonymous ? "" : "mb-4"}`}>
            <CardComponent
              icon={icons.infoDark}
              title={t("details")}
              subTitle={lastEditOptionalOrNoticeFields("opt-info")}
              isEditable={(user.keycloakUserId === caseDetails.mainProcessorId ||
                user.type !== "auditor") && caseDetails.statusId !== completedStatusId}
              editEmitter={() => handleModalOpen("optional-info")}
              loading={loading}
              minHeight="200px">
              <div className="details-body">
                {(caseDetails.optionalFieldsEmpty && !caseDetails.AI && caseDetails.statusId !== completedStatusId && !loading) && (
                  <div className="no-result">
                    {t("please click the pen icon to edit the optional fields")}
                  </div>
                )}
                {(!caseDetails.optionalFieldsEmpty || caseDetails.AI) && (
                  <div className="row">
                    {caseDetailsInfo.map((item: any, index: any) => {
                      return (
                        (loading || (!loading && (caseDetails[item.value] || (caseDetails.AI && caseDetails.AI[item.AI])))) && (
                          <div className={item.classes} key={index}>
                            <CardInfoComponent
                              icon={item.icon}
                              title={item.title}
                              value={caseDetails[item.value]}
                              loading={loading}
                            />
                          </div>
                        )
                      );
                    })}
                  </div>
                )}
              </div>
            </CardComponent>
            <EditOptionalInfo
              show={showOptionalDetailsModal}
              onClose={(value: any) => handleModalClose("optional-info", value)}
            />
          </div>

          {caseDetails.isAnonymous === 0 && (
            <div className="details-card">
              <CardComponent
                icon={icons.infoDark}
                title={t("reporter details")}
                subTitle={`${t("edit on")} ${formatDate(
                  caseDetails.createdAt,
                  "dddd, DD.MM.YYYY"
                )} ${t("at")} ${formatDate(caseDetails.createdAt, "HH:mm")} ${t(
                  "by"
                )} ${t("reporter")}`}
                isEditable={false}
                loading={loading}
                minHeight="200px">
                <div className="details-body">
                  <div className="row">
                    {reporterDetails.map((item: any, index: any) => {
                      return (
                        (loading || (!loading && caseDetails[item.value])) && (
                          <div className={item.classes} key={index}>
                            <CardInfoComponent
                              icon={
                                (item.value === 'reporterEmail' && caseDetails && !caseDetails.reporterActivated) ?
                                  icons.notValidated :
                                  item.icon
                              }
                              title={item.title}
                              value={caseDetails[item.value]}
                              loading={loading}
                            />
                          </div>
                        )
                      );
                    })}
                    <div className="col-6" />
                    {
                      (!caseDetails.reporterActivated && !loading) && (
                        <div className="col-6">
                          <Button
                            onClick={snedActivation}
                            className="btn-primary-theme activation-btn"
                            variant="contained"
                            color="primary"
                            disabled={btnActivation}>
                            <TelegramIcon className="mr-2" />
                            {t("resend activation")}
                          </Button>
                        </div>
                      )
                    }
                  </div>
                </div>
              </CardComponent>
            </div>
          )}
        </div>
      </div>
      {showAiModel && <AiDescriptionModal
        onClose={handleAiDescriptionClose}
        show={showAiModel}
        data={AIModelData}
      />}
    </div>
  );
};
