import "./AI-description.scoped.scss";
import React from "react";
import Modal from "react-bootstrap/Modal";

export const AiDescriptionModal = (props: any) => {
    const handleClose = (value: any) => props.onClose(value);
    const { data } = props;

    return (
        <>
            <Modal show={props.show} onHide={handleClose.bind(null, false)} centered dialogClassName={props.dialogClassName ? props.dialogClassName : "modal-lg"}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {data.map((ele: any, i: number) => {
                        return (
                            <div key={i}>
                                <h4>{ele[0]}</h4>
                                <div style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: ele[1] }}></div>
                            </div>
                        )
                    })}
                </Modal.Body>
            </Modal>
        </>
    );
};
