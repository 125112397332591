import packageJson from '../../package.json';

export const stagingEnvironmentConfig = {
  domain: 'compliance2b.com',
  subDomain: 'staging',
  serverUrl: 'https://staging-app-api.compliance2b.com/',
  keyCloakUrl: 'https://paladin-identity.compliance2b.com/auth/realms/paladin/',
  predictUrl: 'https://case-classsifier-staging.compliance2b.com/api/tasks/predict',
  appVersion: packageJson.version,
  environment: 'staging'
}
