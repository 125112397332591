export class WorkspaceForm {
  public workspace: string = "";
  public configurationName: string = "";
  public clientName: string = "";
  public reporterUrl: string = "";
  public adminPictureUrl: string = "";
  public logoUrl: string = "";
  public adminEmail: string = "";
  public adminFirstName: string = "";
  public adminLastName: string = "";
  public countryCode: string = "+ 43";
}
