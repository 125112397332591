import React, { useContext } from "react";
import "./workspace-item.component.scoped.scss";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { LocalStorageService } from "../../services/local-storage/local-storage";
import { splitUserName } from "../../helpers";
import { AuthService } from "../../services/auth/auth.service";
import { history } from "react-router-guard";
import { GlobalContext } from "../../store";

const localStorageService: LocalStorageService = new LocalStorageService();
const authService: AuthService = new AuthService();

const WorkspaceItem = (props: any) => {
  const { id, iconSrc, usersNumber, name, encodeStatus } = props.tenantData;
  const { setUser, setEncodeStatus } = useContext(GlobalContext);

  const handleTenantClick = async () => {
    localStorageService.setData("tenantId", id);
    localStorageService.setData("tenantName", name);
    localStorageService.setData("tenantIcon", iconSrc);
    setEncodeStatus(encodeStatus);

    let user = await authService.account();
    const { firstName, lastName } = splitUserName(user.name);
    user = { ...user, firstName, lastName };
    localStorageService.setData("user", user);
    setUser(user);
    history.push("/dashboard");
  };

  return (
    <div className="workspace-item" onClick={handleTenantClick}>
      <div className="workspace-item-left">
        <img src={iconSrc} alt="" />
        <div className="wrapper">
          <div className="workspace-name ml-2">{name}</div>
          <div className="workspace-members ml-2">{`${usersNumber} Mitglieder`}</div>
        </div>
      </div>
      <div className="workspace-item-right">
        <p className="word-on-hover"></p>
        <ArrowForwardIcon />
      </div>
    </div>
  );
};

export default WorkspaceItem;
