import { TableColumn } from "../types/table-column";

export const casesColumns: TableColumn[] = [
  {
    type: "checkbox",
    value: "id",
    fieldName: "checkbox",
    width: 3,
  },
  {
    type: "user",
    value: "affected company",
    fieldName: "client",
    secondaryValue: "branch",
    width: 15,
  },
  {
    type: "string",
    value: "category",
    fieldName: "category",
    width: 15,
  },
  {
    type: "case",
    value: "process",
    fieldName: "internalId",
    width: 10,
  },
  {
    type: "comment",
    value: "comment",
    fieldName: "latestComment",
    width: 18,
  },
  {
    type: "string",
    value: "status",
    fieldName: "status",
    width: 8,
  },
  {
    type: "statusDate",
    value: "status change",
    fieldName: "statusUpdatedAt",
    width: 8,
  },
  {
    type: "dueDate",
    value: "dueDate",
    fieldName: "dueDate",
    width: 8,
  },
];
