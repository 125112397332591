import './add-new-user.scoped.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal'
import Button from '@material-ui/core/Button'
import CheckIcon from '@material-ui/icons/Check'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Checkbox from '@material-ui/core/Checkbox'
import { emailRegex } from '../../statics/validators'
import { UserService } from '../../services/user/user.service'
import { showToastMsg } from '../../helpers'
import { SettingsService } from '../../services/settings/settings.service'
// import DefaultInputView from '../../components/default-input-view/default-input-view.component'
import { LocalStorageService } from '../../services/local-storage/local-storage'

const AddNewUserModal = (props: any) => {
  const userService: UserService = new UserService()
  const settingsService: SettingsService = new SettingsService()
  const localStorageService: LocalStorageService = new LocalStorageService()

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [type, setType] = useState('');
  const [defaultPasswordChecked, setDefaultPasswordChecked] = useState(false);
  const [group, setGroup] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [focused, setFocused] = useState(false);
  const [t] = useTranslation();

  async function addUser() {
    const tenantId = localStorageService.getData('tenantId');
    const status = (defaultPasswordChecked && type !== 'superUser') ? 'verified' : 'invited';

    const bodyObject: any = {
      firstName: capitalize(firstName),
      lastName: capitalize(lastName),
      email: email,
      enabled: 'true',
      username: email,
    }

    // get default password if checked
    if (defaultPasswordChecked) {
      const defaultPassword = await settingsService.getDefaultPassword();
      bodyObject.credentials = [
        { type: 'password', value: defaultPassword.value, temporary: false },
      ]
    }

    // add new user to keyClock
    const res = await userService.addNewUserToKeyCloak({ bodyObject }, tenantId);

    // user object information to adding
    const userObj = {
      firstName: capitalize(firstName),
      lastName: capitalize(lastName),
      keycloakUserId: res.newUser ? res.newUser[0].id : null,
      group: group,
      status: status,
      email: email,
      type: type,
      link: `${window.location.origin}`,
      existingUser: false,
      defPassChecked: defaultPasswordChecked
    }

    // check if added a new user or existing user in keyClock
    if (res.message === 'success' && res.newUser) {
      if (!defaultPasswordChecked || userObj.type === 'superUser') {
        await userService.sendUserActivationEmail({
          firstName: firstName + ' ' + lastName,
          link: `${window.location.origin}/signup/${res.newUser[0].id}/${tenantId}`,
          email: email
        });
      }
      // add new user to common database
      await userService.addNewUserToCommonDb({ keycloakUserId: res.newUser[0].id }, tenantId);
      // add new user to workspace database
      await userService.addNewUser(userObj, tenantId);
      showToastMsg('success', t('User added successfully'));
    } else if (res.message === 'user exists already' && res.newUser) {
      userObj.existingUser = true;
      // add new user to workspace database
      const response = await userService.addNewUser(userObj, tenantId);
      showToastMsg('success', t(response.message));
    } else {
      showToastMsg('error', t('System error, please try again later'))
    }
  }

  const changeType = (value: any) => {
    setType(value);
    if (value === 'superUser') {
      setDefaultPasswordChecked(false);
      setGroup('internal');
    }
  }

  const handleClose = (value: any) => {
    props.onClose(value)
    // reset state
    setDefaultPasswordChecked(false);
    setGroup('');
    setEmailError(false);
    setEmail('');
    setLastName('');
    setFirstName('');
    setType('');
    setFocused(false);
  }

  const handleUpdate = async () => {
    if (saving) return
    setSaving(true)
    await addUser()
    setSaving(false)
    handleClose(true)
  }

  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }


  const handleFocus = (input: any) => {
    if (input && !focused) {
      setTimeout(() => {
        input.focus();
        setFocused(true);
      }, 1);
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={handleClose.bind(null, false)}
      centered
      dialogClassName="modal-new-user"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('invite new user')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col">
            <TextField
              fullWidth
              label={t('firstName')}
              onChange={(e: any) => setFirstName(e.target.value.trim())}
              value={firstName}
              name="first name"
              variant={'filled'}
              inputRef={handleFocus}
            />
          </div>
          <div className="col margin-second-field">
            <TextField
              fullWidth
              label={t('lastName')}
              onChange={(e: any) => setLastName(e.target.value.trim())}
              value={lastName}
              name="last name"
              variant={'filled'}
            />
          </div>
        </div>
        <div className="mt-3">
          <TextField
            fullWidth
            label="Email"
            onChange={(e: any) => {
              setEmail(e.target.value);
              e.target.value ? setEmailError(!emailRegex.test(e.target.value)) : setEmailError(false)
            }}
            value={email}
            name="email"
            variant={'filled'}
            type="email"
            error={emailError ? true : false}
          />
          {emailError && <div className="error-msg ">{t('invalid-email')}</div>}
        </div>
        <div className="row mt-3">
          <div className="col">
            <FormControl className="select-input select-grey w-100">
              <InputLabel id="role">
                {t('role')}
              </InputLabel>
              <Select
                labelId="role"
                id="damage-amount-select"
                name="role"
                value={type}
                onChange={(e: any) => { changeType(e.target.value) }}
                IconComponent={ExpandMoreIcon}>
                <MenuItem value={'superUser'}>{t('coordinator')}</MenuItem>
                <MenuItem value={'user'}>{t('editor')}</MenuItem>
                <MenuItem value={'auditor'}>{t('auditor')}</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col margin-second-field">
            <FormControl className={"select-input w-100 " + (type !== 'superUser' ? 'select-grey' : '')}>
              <InputLabel id="internal/external" className={type === 'superUser' ? 'disableLabel' : ''}>
                {t('internal/external')}
              </InputLabel>
              <Select
                labelId="internal/external"
                id="damage-amount-select"
                name="internal/external"
                value={group}
                onChange={(e: any) => setGroup(e.target.value)}
                IconComponent={ExpandMoreIcon}
                disabled={type === 'superUser'}>
                <MenuItem value={'internal'}>{t('internal')}</MenuItem>
                <MenuItem value={'external'}>{t('external')}</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <FormControlLabel className="checkbox-def-password mt-15px"
          control={
            <Checkbox
              checked={defaultPasswordChecked}
              onChange={() => setDefaultPasswordChecked(!defaultPasswordChecked)}
              name="jaChecked"
              size="small"
              disabled={type === 'superUser'} />
          }
          label={t('set default password')}
        />
      </Modal.Body>
      <Modal.Footer>
        <>
          <Button
            variant="outlined"
            color="primary"
            className="btn-secondary-theme"
            onClick={handleClose.bind(null, false)}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="btn-primary-theme"
            onClick={handleUpdate}
            disabled={
              emailError ||
              firstName.length === 0 ||
              lastName.length === 0 ||
              group.length === 0 ||
              email.length === 0 ||
              type.length === 0
            }>
            {saving ? (
              <CircularProgress color="inherit" />
            ) : (
              <>
                <CheckIcon /> {t('add user')}
              </>
            )}
          </Button>
        </>
      </Modal.Footer>
    </Modal>
  )
}

export default AddNewUserModal
