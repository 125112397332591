import React from "react";
import "./last-updated.component.scoped.scss";
import { formatDate } from "../../helpers";
import { useTranslation } from "react-i18next";

const LastUpdatedAt = (props: any) => {
  const [t] = useTranslation();
  return (
    <div>
      <p className="last-edit-text ">
        {props.mainProcessor && props.updatedAt
          ? t("last changed by") + ' ' +
          props.mainProcessor + ' ' + t("on") + ' ' +
          formatDate(props.updatedAt, "dddd, DD.MM.YYYY")
          : ""}
      </p>
    </div>
  );
};

export default LastUpdatedAt;
