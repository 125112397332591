import React, { useContext, useEffect, useState } from "react";
import { DataTableComponent } from "../table/data-table/data-table.component";
import { CasesContext, GlobalContext } from "../../store";
import { CasesService } from "../../services/cases/cases.service";
import { MapperService } from "../../services/mapper/mapper.service";
import "./cases-content.component.scoped.scss";
import CasesFilterComponent from "../cases-filter/cases-filter.component";
import { defaultItems } from "../../statics/pagination";
import { useTranslation } from "react-i18next";
import { jsPDF } from "jspdf";
import { saveAs } from 'file-saver';

export default function CasesContent() {
  const [t] = useTranslation();
  const casesService = new CasesService();
  const mapperService = new MapperService();
  const emptyArr: any = [];
  const {
    cases,
    columns,
    setCases,
    setPagination,
    filter,
    options,
    pagination,
    sort,
  } = useContext(CasesContext);
  const { helpData, setHelpContent } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [isFiltered, setIsFiltered] = useState(false);

  async function fetchData(filter?: any) {
    setCases(emptyArr);
    setLoading(true);
    const casesDetails = await casesService.list(filter);
    const { readyCases, paginationObj } = mapperService.mapCases(casesDetails);
    setCases(readyCases);
    setPagination(paginationObj);
    setLoading(false);
  }

  useEffect(() => {
    const paginationParams = { items: defaultItems, page: 1 };
    fetchData(paginationParams);
    setHelpContent(helpData.FilterCases);
    // eslint-disable-next-line
  }, [helpData]);

  const sortTable = (fieldName: string, sortDirection: string) => {
    const params = { fieldName, sortDirection };
    const filterObj = {
      ...filter,
      page: 1,
      items: pagination.items,
      sort: fieldName,
      order: sortDirection,
    };
    fetchData(filterObj);
    return sort(params);
  };

  const exportData = async () => {
    for (let row of cases) {
      if (row.checked) {
        await downloadPDF(row);
      }
    }
  }

  const downloadPDF = (caseDetails: any) => {
    return new Promise(async (resolve) => {
      const doc = new jsPDF('p');
      doc.setFontSize(15);
      let lineHeight = 10;

      const person = doc.splitTextToSize(`${t('involved people')}: ${caseDetails.involvedPersons.replace(/,/g, ', ')}`, 180);
      person.forEach((text: any) => {
        doc.text(text, 10, (lineHeight += 7));
      });

      lineHeight += 3;
      const department = doc.splitTextToSize(`${t('involved department')}: ${caseDetails.involvedDepartment.replace(/,/g, ', ')}`, 180);
      department.forEach((text: any) => {
        doc.text(text, 10, (lineHeight += 7));
      });

      doc.text(`${t('category')}: ${caseDetails.category}`, 10, (lineHeight += 10));
      doc.text(`${t('affected company')}: ${caseDetails.branch}`, 10, (lineHeight += 10));
      doc.text(`${t('country')}: ${caseDetails.land}`, 10, (lineHeight += 10));
      doc.text(`${t('amount')}: ${caseDetails.amount ? caseDetails.amount : ""}`, 10, (lineHeight += 10));
      doc.text(`${t('ongoing-case')}: ${caseDetails.caseOngoing ? t('yes') : t("No")}`, 10, (lineHeight += 10));

      lineHeight += 3;
      const company = doc.splitTextToSize(`${t('involved company')}: ${caseDetails.involvedCompany.replace(/,/g, ', ')}`, 180);
      company.forEach((text: any) => {
        doc.text(text, 10, (lineHeight += 7));
      });
      doc.text(`${t('relation')}: ${caseDetails.relationship ? caseDetails.relationship : ""}`, 10, (lineHeight += 10));
      doc.text(`${t('start date')}: ${caseDetails.startDate ? caseDetails.startDate : ""}`, 10, (lineHeight += 10));
      doc.text(`${t('end date')}: ${caseDetails.endDate ? caseDetails.endDate : ""}`, 10, (lineHeight += 10));
      // set line-height for description
      doc.setLineHeightFactor(1.5);
      const description = doc.splitTextToSize(`${t('description')}: ${caseDetails.description.replace(/<[^>]+>/g, '')}`, 180);
      // add new page with condition
      const pageHeight = doc.internal.pageSize.getHeight();
      description.forEach((text: any) => {
        if (lineHeight + 15 > pageHeight) {
          lineHeight = 10;
          doc.addPage();
        }
        doc.text(text, 10, (lineHeight += 10));
      });
      doc.setProperties({ title: `${t("case")}-${caseDetails.internalId}` });
      let blobPDF = doc.output('blob');
      const blob = new Blob([blobPDF], {
        type: "pdf/plain;charset=utf-8",
      });
      saveAs(blob, `${t("case")}-${caseDetails.internalId}.pdf`);
      setTimeout(() => {
        resolve('downloaded');
      }, 500);
    })
  }

  const paginateTable = (pagination: any) => {
    const { items, currentPage } = pagination;
    const params = {
      ...filter,
      items,
      page: currentPage,
      order: options.sortDirection,
      sort: options.sortColumn,
    };
    fetchData(params);
  };

  const filterChange = (params: any) => {
    setIsFiltered(true);
    sort({
      fieldName: "",
      sortDirection: "",
    });
    const filterObj = {
      ...params,
      items: pagination.items,
      page: 1,
    };
    fetchData(filterObj);
  };

  return (
    <div className="cases-content">
      <div className="cases-filters-container">
        <CasesFilterComponent submitFilter={filterChange} />
      </div>
      <div className="theme-container table-section">
        <DataTableComponent
          loading={loading}
          columns={columns}
          data={cases}
          options={options}
          sort={sortTable}
          pagination={pagination}
          exportData={true}
          onExport={exportData}
          paginate={paginateTable}
          title={
            isFiltered
              ? t("your ongoing cases")
              : t("all cases for which you are authorized")
          }
          subTitle={`${pagination.total} ${t("results")}`}
          clickableRow={["case", "id"]}
        />
      </div>
    </div>
  );
}
