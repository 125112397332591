import React, { useContext, useEffect, useState } from "react";
import { CardComponent } from "../card/card.component";
import "./settings-content.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { icons } from "../../statics/icons";
import { Button } from "@material-ui/core";
import { history } from "react-router-guard";
import { GlobalContext } from "../../store";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { SettingsService } from "../../services/settings/settings.service";
import { showToastMsg } from "../../helpers";

export default function SettingsContent() {
  const [t] = useTranslation();
  const settingsService: SettingsService = new SettingsService();
  const emptyObj: any = {};
  const [contactInfo, setContactInfo] = useState(emptyObj);
  const { user, helpData, setHelpContent } = useContext(GlobalContext);
  const [saving, setSaving] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const getContactInfo = async () => {
    const res = await settingsService.getContactInfo();
    setContactInfo(res);
  };

  useEffect(() => {
    getContactInfo();
    // eslint-disable-next-line
  }, []);

  const navigate = (path: string) => {
    history.push(path);
  };

  const changePassword = async () => {
    setSaving(true);
    const res = await settingsService.setDefaultPassword(password);
    setSaving(false);
    if (res && res.msg === "updated") {
      setPasswordChanged(false);
      showToastMsg("success", t("standard password updated successfully"));
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const getPassword = async () => {
    const pass = await settingsService.getDefaultPassword();
    setPassword(pass.value || "");
  };

  useEffect(() => {
    if (user.type && (user.type !== "user" && user.type !== 'auditor')) {
      getPassword();
    }
    setHelpContent(
      user.type && (user.type !== "user" && user.type !== 'auditor')
        ? helpData.settingsAdmin
        : helpData.settings
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, helpData]);

  return (
    <div className="theme-container settings-content">
      <section className="settings">
        <h3 className="section-title">{t("settings")}</h3>
        <div className="row">
          {(user.type !== "user" && user.type !== 'auditor') && (
            <div className="col-6 mb-3">
              <CardComponent
                icon={icons.category}
                title={t("roles management")}
                active={true}
                minHeight="195px"
              >
                <div className="card-content">
                  <div className="card-text mt-3 mb-4">
                    {t(
                      "assign a responsible person to each message category internal and external"
                    )}
                  </div>
                  <div className="card-btn-container d-flex justify-content-end">
                    <Button
                      variant="outlined"
                      color="primary"
                      className="btn-secondary-theme"
                      onClick={navigate.bind(null, "/settings/roles-management")}
                    >
                      <img src={icons.categoryOrange} alt="" />{" "}
                      {t("role management")}
                    </Button>
                  </div>
                </div>
              </CardComponent>
            </div>
          )}
          <div className="col-6 mb-3">
            <CardComponent
              icon={icons.profile}
              title={t("manage user")}
              active={true}
              minHeight="195px"
            >
              <div className="card-content">
                <div className="card-text mt-3 mb-4">
                  {t("change your personal master data here")}
                </div>
                <div className="card-btn-container d-flex justify-content-end">
                  <Button
                    variant="outlined"
                    color="primary"
                    className="btn-secondary-theme"
                    onClick={navigate.bind(null, "/settings/user-management")}
                  >
                    <img src={icons.profileOrange} alt="" />{" "}
                    {t("user administration")}
                  </Button>
                </div>
              </div>
            </CardComponent>
          </div>
          {(user.type !== "user" && user.type !== 'auditor') && (
            <div className="col-6 mb-3">
              <CardComponent
                icon={icons.password}
                title={t("standard password")}
                active={true}
                minHeight="240px"
              >
                <div className="card-content">
                  <div className="card-text mt-3 mb-3">
                    {t(
                      "set a standard password that new users initially receive"
                    )}
                  </div>
                  <div className="w-100 mb-2">
                    <TextField
                      className="w-100"
                      variant="filled"
                      type={passwordVisibility ? "text" : "password"}
                      label="Default password"
                      value={password}
                      autoComplete="off"
                      name="newPassword"
                      onChange={(e) => setPassword(e.target.value)}
                      onInput={setPasswordChanged.bind(null, true)}
                      autoFocus={false}
                      inputProps={{
                        minLength: 8,
                      }}
                      InputProps={{
                        autoComplete: "one-time-code",
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              className="toggle-password"
                              aria-label="toggle password visibility"
                              onClick={setPasswordVisibility.bind(
                                null,
                                !passwordVisibility
                              )}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {passwordVisibility ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div
                      className="error-msg pt-1"
                      style={{
                        visibility:
                          password && password.length < 8
                            ? "visible"
                            : "hidden",
                      }}
                    >
                      {t("minimum password length is 8 characters")}
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button
                      variant="contained"
                      color="primary"
                      className="btn-primary-theme btn-save"
                      onClick={changePassword}
                      disabled={
                        !passwordChanged || password.length < 8 || saving
                      }
                    >
                      {t("change password")}
                    </Button>
                  </div>
                </div>
              </CardComponent>
            </div>
          )}
        </div>
      </section>
      <section className="contacts-section mt-5">
        <h3 className="section-title">{t("Contact us")}</h3>
        <div className="row">
          <div className="col-6">
            <div className="contact-card">
              <CardComponent
                icon={(user.type === "user" || user.type === 'auditor') ? icons.user : icons.supportAdmin}
                title={
                  (user.type === "user" || user.type === 'auditor')
                    ? t("compliance admin")
                    : t("Your Compliance 2b supervisor")
                }
                active={true}
                minHeight="145px"
              >
                <div className="card-content">
                  <div className="row">
                    <div className="card-main-text col-4 mt-3 mb-4">
                      {(user.type === "user" || user.type === 'auditor')
                        ? contactInfo.internalAdminName
                        : contactInfo.externalAdminName}
                    </div>
                    <div className="col-6 mt-3 d-flex flex-column">
                      <div className="card-sec-text mb-1">
                        {(user.type === "user" || user.type === 'auditor')
                          ? contactInfo.internalAdminEmail
                          : contactInfo.externalAdminEmail}
                      </div>
                      <div className="card-sec-text">
                        {(user.type === "user" || user.type === 'auditor')
                          ? contactInfo.internalAdminNumber
                          : contactInfo.externalAdminNumber}
                      </div>
                    </div>
                  </div>
                </div>
              </CardComponent>
            </div>
          </div>
          <div className="col-6">
            <div className="contact-card">
              <CardComponent
                icon={icons.support}
                title={
                  (user.type !== "user" && user.type !== 'auditor')
                    ? t("Compliance 2b Support")
                    : t("Support Team")
                }
                active={true}
                minHeight="145px"
              >
                <div className="card-content">
                  <div className="row">
                    <div className="card-text col-5 mt-3 d-flex flex-column">
                      <div className="card-main-text mb-2">
                        {t("technical questions?")}
                      </div>
                      <div className="card-main-text">
                        {t("organisational questions?")}
                      </div>
                    </div>
                    <div className="col-6 mt-3 d-flex flex-column">
                      <div className="card-sec-text mb-2">
                        {(user.type === "user" || user.type === 'auditor')
                          ? contactInfo.techQuestionsEmail
                          : "technik@compliance2b.at"}
                      </div>
                      <div className="card-sec-text">
                        {(user.type === "user" || user.type === 'auditor')
                          ? contactInfo.organisationQuestionEmail
                          : "office@compliance2b.at"}
                      </div>
                    </div>
                  </div>
                </div>
              </CardComponent>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
