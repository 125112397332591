import "./edit-desc.scoped.scss";
import React, { useState, useContext, useRef } from "react";
import { useTranslation, Trans } from "react-i18next";
import RichTextEditor from "react-rte";
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
import { CasesService } from "../../services/cases/cases.service";
import { CaseContext, GlobalContext } from "../../store";
import CheckIcon from "@material-ui/icons/Check";
import { toolbarConfig } from "../../statics/rich-text-config";
import { sanitize, replaceTags } from "../../helpers";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AIService } from "../../services/ai/ai.services";

export const EditDescDialog = (props: any) => {
  const maxChar: number = 2500;
  const casesService = new CasesService();
  const aiService = new AIService();
  const { caseDetails, setCase, setCaseDetailsUpdated } = useContext(CaseContext);
  const { user } = useContext(GlobalContext);

  const [desc, setDesc] = useState(
    RichTextEditor.createValueFromString(caseDetails.description, "html")
  );
  const [charCount, setCharCount] = useState(
    replaceTags(desc.toString("html")).length
  );
  const [descError, setDescError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const DescRichTextEl = useRef(null);
  const [t] = useTranslation();

  const onDescChange = (value: any) => {
    setFormChanged(true);
    setDesc(value);
    const count = replaceTags(value.toString("html")).length;
    setCharCount(count);
    count > maxChar ? setDescError(true) : setDescError(false);
  };

  const handleClose = (value: any) => {
    props.onClose(value);
    setFormChanged(false);
    // reset initial state if not saved.
    if (!value) {
      const descriptionHtml = RichTextEditor.createValueFromString(
        caseDetails.description,
        "html"
      );
      setDesc(descriptionHtml);
      setCharCount(replaceTags(descriptionHtml.toString("html")).length);
      setDescError(false);
    }
  };

  const handleUpdate = async () => {
    setSaving(true);
    const description = desc.toString("html");
    await casesService.updateDescription(caseDetails.id, description);
    setSaving(false);
    await aiService.activateAI(false, caseDetails.id);
    setCaseDetailsUpdated(true);
    const descriptionUpdatedAt = new Date();
    const descriptionUpdateAdmin = user.name;
    setCase({
      ...caseDetails,
      description,
      descriptionUpdatedAt,
      descriptionUpdateAdmin,
    });
    handleClose(description);
  };

  const handleRichTextFocus = (event: any) => {
    const element: any = DescRichTextEl.current;
    element.classList.add("active");
  };
  const handleRichTextBlur = (event: any) => {
    const element: any = DescRichTextEl.current;
    element.classList.remove("active");
  };

  return (
    <Modal
      show={props.show}
      onHide={handleClose.bind(null, false)}
      centered
      dialogClassName="modal-xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans
            i18nKey="edit_desc_of_case"
            values={{ internalId: caseDetails.internalId }}
          ></Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-6">
            <div className="input-label">{t("original input")}</div>
            <div className="input-original">
              <div className="input-placeholder">
                {t("Original note description")}
              </div>
              <div
                className="input-body"
                dangerouslySetInnerHTML={sanitize(
                  caseDetails.originalDescription
                )}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="input-label">{t("current description")}</div>
            <div className="input-editable" ref={DescRichTextEl}>
              <div className="input-placeholder">
                {t("Revised note description")}
              </div>
              <div
                className="input-body"
                onFocus={handleRichTextFocus}
                onBlur={handleRichTextBlur}
              >
                <RichTextEditor
                  toolbarConfig={toolbarConfig}
                  value={desc}
                  onChange={(value) => onDescChange(value)}
                />
              </div>
            </div>
            <div className={"input-char-count " + (descError ? "invalid" : "")}>
              {charCount}/2.500 {t("character")}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outlined"
          color="primary"
          className="btn-secondary-theme"
          onClick={handleClose.bind(null, false)}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="btn-primary-theme"
          onClick={handleUpdate}
          disabled={descError || !formChanged}
        >
          {saving ? (
            <CircularProgress color="inherit" />
          ) : (
            <>
              <CheckIcon /> {t("save")}{" "}
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
