import "./table-header.component.scoped.scss";
import React, { useEffect, useState } from "react";
import { TableHeaderProps } from "../../../types/table-header-props";
import { useTranslation } from "react-i18next";
import { SorterComponent } from "../sorter/sorter.component";
import { Checkbox } from "@material-ui/core";

export const TableHeaderComponent = (props: TableHeaderProps) => {
  const [t] = useTranslation();
  const { columns, selectCases, loading } = props;
  const { sort } = props.tools;
  const { sortDirection, sortColumn } = props.options;
  const { withSort } = props.options;

  const [casesChecked, setCasesChecked] = useState(false);

  useEffect(() => {
    setCasesChecked(false);
  }, [loading])

  return (
    <div className="table-header">
      {columns.map((column, index) => {
        return (
          <div className={"one-column" + (column.type === "actions" ? " actions" : column.type === "checkbox" ? " checkbox-column" : "")}
            style={{ minWidth: `${column.width}%` }} key={index}>
            <div className="w-100">
              <div className="upper">
                <div className={`name ${column.value} ${column.component}`}>
                  {column.type !== 'checkbox' ? t(column.value) :
                    <div className="checkbox-global">
                      <Checkbox
                        checked={casesChecked}
                        onChange={() => { selectCases(!casesChecked); setCasesChecked(!casesChecked) }}
                        name="cases"
                        size="small"
                      />
                    </div>
                  }
                </div>
                {withSort && column.type !== 'checkbox' &&
                  column.type !== "actions" &&
                  column.type !== "categories" && (
                    <SorterComponent
                      isSorting={column.fieldName === sortColumn}
                      direction={sortDirection}
                      sortColumn={sortColumn}
                      fieldName={column.fieldName}
                      sort={sort}
                    />
                  )}
              </div>
            </div>
          </div>
        );
      })}
    </div >
  );
};
