import { SideNavItem } from "../types/sidenav-item";
import { icons } from "./icons";

export const sideNavItems: SideNavItem[] = [
  {
    name: "dashboard",
    title: "dashboard",
    path: "/dashboard",
    icon: icons.dashboardLight,
    iconHover: icons.dashboard,
    permissions: ["superAdmin", "user", "superUser", "auditor"],
  },
  {
    name: "cases",
    title: "cases",
    path: "/cases",
    icon: icons.reportsLight,
    iconHover: icons.reports,
    permissions: ["superAdmin", "user", "superUser", "auditor"],
  },
  // {
  //   name: "users",
  //   title: "users",
  //   path: "/users",
  //   icon: icons.usersLight,
  //   iconHover: icons.users,
  //   permissions: ["superAdmin", "admin", "superUser"],
  // },
  // {
  //   name: "todo",
  //   title: "todo",
  //   path: "/todo",
  //   icon: icons.todoLight,
  //   iconHover: icons.todo,
  //   permissions: ["superAdmin", "admin", "user", "superUser", "auditor"],
  // },
  // {
  //   name: "create-workspace",
  //   title: "create workspace",
  //   path: "/create-workspace",
  //   icon: icons.homeLight,
  //   iconHover: icons.home,
  //   permissions: ["superAdmin"],
  // },
];
