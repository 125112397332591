import React, { useEffect, useContext } from "react";
import { CommonService } from "../../services/common/common.service";
import { useTranslation } from "react-i18next";
import HeaderComponent from "../../components/header/header.component";
import { GlobalContext } from "../../store";
import CreateWorkspaceContent from "../../components/create-workspace-content/create-workspace-content.component";

const CreateWorkspacePage = () => {
  const commonService = new CommonService();
  const [t] = useTranslation();
  commonService.setTitle(t("create workspace"));
  const { setHelpContent } = useContext(GlobalContext);

  useEffect(() => {
    setHelpContent("");
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <HeaderComponent />
      <CreateWorkspaceContent />
    </>
  );
};

export default CreateWorkspacePage;
