import React, { useContext } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import "./onboarding-categories.component.scoped.scss";
import OnboardingCategory from "../onboarding-category/onboarding-category.component";
import { DefaultCategories } from "../../statics/default-categories";
import { GlobalContext } from "../../store";

const OnboardingCategories = ({ nextTab, prevTab }: any) => {
  const { OnboardingCategories, setOnboardingCategories } = useContext(GlobalContext);

  const addOrDeleteCategory = (key: any) => {
    const existingCategory = OnboardingCategories.find((cat: any) => cat.key === key);
    if (existingCategory !== undefined) {
      const categoriesCopy = [...OnboardingCategories];

      const newCategories = categoriesCopy.filter((cat: any) => cat.key !== key);
      setOnboardingCategories(newCategories);
    } else {
      const category = DefaultCategories.find((cat: any) => cat.key === key);
      const categoriesCopy = [...OnboardingCategories];
      categoriesCopy.push(category);
      setOnboardingCategories(categoriesCopy);
    }
  };

  return (
    <div>
      <h1 className="main-header px-3">
        Which category apply to the subject of your case?
      </h1>
      <div className="sec-header px-3">
        This information will help us to classify your case.
      </div>

      <div className="categories">
        {DefaultCategories.map((category: any, index: number) => {
          return (
            <OnboardingCategory
              key={index}
              name={category.name}
              src={category.src}
              activeSrc={category.activeSrc}
              categoryKey={category.key}
              active={OnboardingCategories.some((cat: any) => cat.key === category.key)}
              onclick={addOrDeleteCategory}
            />
          );
        })}
      </div>

      <div className="mb-5">
        <Grid container item xs={12} spacing={2}>
          <Grid container item justify="space-between">
            <Box>
              <Button
                variant="text"
                color="primary"
                fullWidth
                onClick={() => {
                  //    const { loading, ...rest } = formik.values;
                  //    dispatch(setStepTwo(rest));
                  prevTab();
                }}
              >
                previous
              </Button>
            </Box>
            <Box width="240px">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  //    const { loading, ...rest } = formik.values;
                  //    dispatch(setStepTwo(rest));
                  nextTab();
                }}
                disabled={OnboardingCategories.length === 0}
              >
                next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default OnboardingCategories;
