import "./files-add-comment.component.scoped.scss";
import { TextField, InputAdornment } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { CasesService } from "../../services/cases/cases.service";
import { CaseContext } from "../../store";


const FilesTableAddComment = (props: any) => {
    const [comment, setComment] = useState("");
    const [t] = useTranslation();
    const [saving, setSaving] = React.useState(false);
    const casesService = new CasesService();
    const { caseDetails } = useContext(CaseContext);

    const handleChange = (event: any) => {
      const value = event?.target?.value;
      setComment(value);
    };
    const maxChar = 500;
  
    const isValid = (comment: string = "") => {
      return comment.length > 0 && comment.length <= maxChar;
    };

    const addComment = async () => {
      if (isValid(comment) && !saving) {
        setSaving(true);
        const res = await casesService.addFilesComment(caseDetails.id, {content: comment}, props.fileId );
        setSaving(false);
        if (res && res.comment) {
          props.updateComments(res.comment);
          setComment("");
        }
      }
    }
  
    return (
      <div className=" w-100">
        <div className="workflow-text-field">
          <TextField
            id="filled-multiline-flexible"
            value={comment}
            onChange={handleChange}
            variant="filled"
            className="workflow-add-comment"
            placeholder={t("Add comment to data")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SendIcon
                    className={isValid(comment) ? "svg-active" : "svg-grey"}
                    onClick={addComment}
                  />
                </InputAdornment >
              ),
            }}
          />
         <div className={"input-char-count mt-1 " + (comment && !isValid(comment) ? "invalid" : "")}>{comment.length}/{maxChar} {t("character")}</div>
        </div>
      </div>
    );
};

export default FilesTableAddComment;
