import i18n from "../../i18n";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: any, action: any) => {
  const {
    lang,
    fullNav,
    user,
    helpData,
    helpContent,
    notifications,
    unseenNotifications,
    userTenants,
    activeOnboardingStep,
    workspaceForm,
    contactInfoForm,
    OnboardingLands,
    OnboardingBranches,
    OnboardingCategories,
    reporterImage,
    workspaceImage,
    branches,
    horizontalIcons,
    encodeStatus
  } = action.payload;
  switch (action.type) {
    case "SET_LANG":
      setTimeout(() => {
        i18n.changeLanguage(lang);
      }, 0);
      return {
        ...state,
        lang,
      };
    case "SET_FULL_NAV":
      return {
        ...state,
        fullNav,
      };
    case "SET_HELP_DATA":
      return {
        ...state,
        helpData,
      };
    case "SET_HELP_CONTENT":
      return {
        ...state,
        helpContent,
      };
    case "SET_USER":
      return {
        ...state,
        user,
      };
    case "SET_USER_TENANTS":
      return {
        ...state,
        userTenants,
      };
    case "SET_NOTIFICATIONS":
      return {
        ...state,
        notifications,
      };
    case "SET_ACTIVE_STEP":
      return {
        ...state,
        activeOnboardingStep,
      };
    case "SET_UNSEEN_NOTIFICATIONS":
      return {
        ...state,
        unseenNotifications,
      };
    case "SET_WORKSPACE_FORM":
      return {
        ...state,
        workspaceForm,
      };
    case "SET_CONTACTINFO_FORM":
      return {
        ...state,
        contactInfoForm,
      };
    case "SET_ONBOARDING_LANDS":
      return {
        ...state,
        OnboardingLands,
      };
    case "SET_ONBOARDING_BRANCHES":
      return {
        ...state,
        OnboardingBranches,
      };
    case "SET_ONBOARDING_CATEGORIES":
      return {
        ...state,
        OnboardingCategories,
      };
    case "SET_REPORTER_IMAGE":
      return {
        ...state,
        reporterImage,
      };
    case "SET_WORKSPACE_IMAGE":
      return {
        ...state,
        workspaceImage,
      };
    case "SET_BRANCHES":
      return {
        ...state,
        branches,
      };
    case "SET_HORIZONTAL_ICONS":
      return {
        ...state,
        horizontalIcons
      };
    case "SET_ENCODE_STATUS":
      return {
        ...state,
        encodeStatus
      };
    default:
      return state;
  }
};
