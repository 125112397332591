import React, { createContext, useReducer } from "react";
import AuthReducer from "../reducers/auth.reducer";

const initialState: any = {
  email: '',
  password: '',
  code: '',
  errors: {},
  loginError: null,
};

export const AuthContext = createContext(initialState);
export const AuthProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  function setLoginError(loginError: string) {
    dispatch({
      type: "SET_LOGIN_ERROR",
      payload: { loginError }
    });
  }

  function login() {
    dispatch({
      type: "LOGIN",
    });
  }

  function validate(input: string, value: string) {
    dispatch({
      type: "VALIDATE",
      payload: { input, value }
    });
  }

  function setInput(input: string, value: string) {
    dispatch({
      type: "SET_INPUT",
      payload: { input, value }
    });
  }


  return (
    <AuthContext.Provider
      value={{
        ...state,
        setInput,
        setLoginError,
        validate,
        login
      }}
    >
      { children }
    </AuthContext.Provider>
  );
};