import React, { useContext, useState } from "react";
import "./workflow-change-status.component.scoped.scss";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CaseContext, GlobalContext } from "../../store";
import { CasesService } from "../../services/cases/cases.service";
import { MapperService } from "../../services/mapper/mapper.service";
import { ConfirmDialog } from "../../dialogs/confirm-dialog/confirm-dialog";
import WorkflowResults from "../workfow-results/workflow-results.component";

const WorkflowChangeStatus = (props: any) => {
  const [t] = useTranslation();
  const emptyObj: any = {};
  const casesService = new CasesService();
  const mapperService = new MapperService();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const {
    caseDetails,
    caseStatuses,
    setCase,
    setCaseQuestions,
    setWorkflowLoading,
    setCaseStatuses,
    caseTotalQuestions,
    caseTotalAnsweredQuestions,
    completedStatusId,
  } = useContext(CaseContext);
  const { fullNav, user } = useContext(GlobalContext);
  const [newStatus, setNewStatus] = useState(emptyObj);
  const [reviewNotes, setReviewNotes] = useState(emptyObj);

  async function fetchCaseDetails(id: string) {
    const caseDetailsData = await casesService.details(id);
    const { mappedCase, mappedtimelineStatuses } = mapperService.mapCase(
      caseDetailsData,
      caseStatuses
    );
    setCase(mappedCase);
    setCaseStatuses(mappedtimelineStatuses);
  }

  async function fetchCaseQuestions(id: string) {
    setWorkflowLoading(true);
    const caseQuestions = await casesService.caseQuestions(id);
    setTimeout(() => {
      setCaseQuestions(caseQuestions);
      setWorkflowLoading(false);
    }, 100);
  }

  const updateCase = () => {
    fetchCaseDetails(caseDetails.id);
    setTimeout(() => {
      fetchCaseQuestions(caseDetails.id);
    }, 100);
  };

  const changeStatus = async (status: any) => {
    setNewStatus({ ...status });
    if (status.id === completedStatusId && caseTotalQuestions !== caseTotalAnsweredQuestions) {
      setShowWarningModal(true);
    } else if (status.id === completedStatusId) {
      setShowConfirmModal(true);
    } else {
      await casesService.updateWorkflowStatus(props.caseId, {
        statusId: status.id,
      });
      updateCase();
    }
  };

  const handleClose = async (value: any) => {
    setTimeout(() => {
      setShowConfirmModal(false);
    }, 0);
    const resolutionComment = reviewNotes.resolutionComment
      ? reviewNotes.resolutionComment
      : null;
    const payload: any = {
      ...reviewNotes,
      statusId: newStatus.id,
      resolutionComment,
    };
    if (value) {
      await casesService.updateWorkflowStatus(props.caseId, payload);
      updateCase();
    }
  };

  const handleCloseWarning = async (value: any) => {
    setTimeout(() => {
      setShowWarningModal(false);
    }, 0);
    if (value) {
      setShowConfirmModal(true);
    }
  }

  return (
    <div className="row">
      <div className={fullNav ? "col-4" : "col-5"}>
        {props.showResults ? (
          <WorkflowResults
            handleReviewNotes={(reviewNotes: any) =>
              setReviewNotes({ ...reviewNotes })
            }
          />
        ) : null}
      </div>
      <div className={fullNav ? "col-8" : "col-7"}>
        <div className="status-change">
          <div className="text mr-4">
            {t("change of status")}
          </div>
          {props.firstAvailableStatus && (
            <Button
              className="btn-primary-theme"
              variant="contained"
              color="primary"
              onClick={changeStatus.bind(null, props.firstAvailableStatus)}
              disabled={caseDetails.statusId === completedStatusId || user.type === 'auditor'}
            >
              <img
                src={props.firstAvailableStatus.icon}
                className="mr-2"
                alt=""
              />{" "}
              {props.firstAvailableStatus.name}
            </Button>
          )}
          {props.secondAvailableStatus && (
            <div className="text font-weight-normal mx-4">{t("or")}</div>
          )}
          {props.secondAvailableStatus && (
            <Button
              className="btn-secondary-theme"
              variant="outlined"
              color="primary"
              disabled={
                user.type === 'auditor' ||
                caseDetails.statusId === completedStatusId ||
                (props.secondAvailableStatus.id === completedStatusId &&
                  (!reviewNotes.resolutionId)) ||
                (reviewNotes.resolutionComment &&
                  reviewNotes.resolutionComment.length > 500)
              }
              onClick={changeStatus.bind(null, props.secondAvailableStatus)}
            >
              <img
                src={user.type === 'auditor' || (props.secondAvailableStatus.id === completedStatusId &&
                  (!reviewNotes.resolutionId || (reviewNotes.resolutionComment && reviewNotes.resolutionComment.length > 500)))
                  ? props.secondAvailableStatus.iconGrey
                  : props.secondAvailableStatus.iconOrange
                }
                className="mr-2"
                alt=""
              />{" "}
              {props.secondAvailableStatus.name}
            </Button>
          )}
          {showConfirmModal && (
            <ConfirmDialog
              show={showConfirmModal}
              showCheck={true}
              onClose={handleClose}
              dialogTitle={t("complete report")}
              dialogBody={t("complete workflow")}
              dialogExtraText={t("complete workflow note")}
              confirmBtnText={t("lock")}
              cancelBtn={true}
              dialogClassName="complete-workflow-modal"
            />
          )}
          {showWarningModal && (
            <ConfirmDialog
              show={showWarningModal}
              onClose={handleCloseWarning}
              dialogTitle={t("complete report")}
              dialogBody={t("confirm all questions")}
              confirmBtnText={t("yes")}
              cancelBtn={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkflowChangeStatus;
