import React, { useEffect, useState } from 'react';
import './reporter-links.components.scoped.scss';
import { Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SettingsService } from '../../../../services/settings/settings.service';
import { ReporterLinksValues } from '../../../../types/reporter-configuration';
import CheckIcon from "@material-ui/icons/Check";
import { urlRegex } from '../../../../statics/validators';
import { showToastMsg } from '../../../../helpers';

export default function ReporterLinks(props: any) {
    const [t] = useTranslation();
    const settingsService: SettingsService = new SettingsService();

    const [reporterLinks, setReporterLinks] = useState(new ReporterLinksValues());
    const [originalReporterLinks, setOriginalReporterLinks] = useState(new ReporterLinksValues());
    const [linksChanged, setLinksChanged] = useState(false);
    const [footerLink1Error, setFooterLink1Error] = useState(false);
    const [footerLink2Error, setFooterLink2Error] = useState(false);

    useEffect(() => {
        setReporterLinks(props.data);
        setOriginalReporterLinks(props.data);
    }, [props]);


    const submit = async () => {
        setLinksChanged(false);
        const res = await settingsService.updateReporterConfiguration(reporterLinks);
        if (res && res.message === "Success") {
            setOriginalReporterLinks(reporterLinks);
            showToastMsg("success", t("configuration updated successfully"));
        } else {
            showToastMsg("error", t("Something went wrong!!"));
        }
    }

    const reset = () => {
        setLinksChanged(false);
        setReporterLinks(originalReporterLinks);
        setFooterLink1Error(false);
        setFooterLink2Error(false);
    }

    return (
        <>
            <div className="design-customization">
                <div className="section-info">
                    <h3>{t("text button in footer")}</h3>
                    <p>{t("here you can enter the URL of the two text buttons in the footer!")}</p>
                </div>
                <div className="reporter-links">
                    <div className="form">
                        <div className="form-group">
                            <label>{t('text button 1')}</label>
                            <TextField
                                variant='filled'
                                name='btnLink1'
                                placeholder="https://example.com"
                                value={reporterLinks.footer_link1}
                                error={footerLink1Error}
                                onChange={(e) => {
                                    setLinksChanged(true);
                                    e.target.value ? setFooterLink1Error(!urlRegex.test(e.target.value)) : setFooterLink1Error(false);
                                    setReporterLinks({ ...reporterLinks, footer_link1: e.target.value });
                                }}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="form-group">
                            <label>{t('text button 2')}</label>
                            <TextField
                                variant='filled'
                                name='btnLink2'
                                placeholder="https://example.com"
                                value={reporterLinks.footer_link2}
                                error={footerLink2Error}
                                onChange={(e) => {
                                    setLinksChanged(true);
                                    e.target.value ? setFooterLink2Error(!urlRegex.test(e.target.value)) : setFooterLink2Error(false);
                                    setReporterLinks({ ...reporterLinks, footer_link2: e.target.value });
                                }}
                                style={{ width: '100%' }}
                            />
                        </div>
                    </div>
                    <div style={{ textAlign: 'end' }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            className="btn-cancel"
                            onClick={reset}
                            disabled={!linksChanged}>
                            {t("cancel")}
                        </Button>
                        <Button
                            className="btn-primary-theme btn-save"
                            variant="contained"
                            color="primary"
                            onClick={submit}
                            disabled={!linksChanged || footerLink1Error || footerLink2Error}>
                            <CheckIcon />
                            {t("save changes")}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}