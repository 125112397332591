export class ReporterConfiguration {
    public design: ReporterDesignValues = new ReporterDesignValues();
    public fields: ReporterFieldsValues = new ReporterFieldsValues();
    public links: ReporterLinksValues = new ReporterLinksValues();
}

export class ReporterDesignValues {
    public main_color: string = '';
    public reporter_cover_url: string = '';
}

export class ReporterFieldsValues {
    public country_visibility: boolean = false;
    public optional_fields_visibility: boolean = false;
    public voice_visibility: boolean = false;
}

export class ReporterLinksValues {
    public footer_link1: string = '';
    public footer_link2: string = '';
}


