import { Box, BoxProps, Step, StepLabel, Stepper } from "@material-ui/core";
import React from "react";
import { OnboardingSideMenuProps } from "../../types/onboarding";
import { CircleIconStatus } from "../../types/circle-icon";
import CircleIcon from "../CircleIcon/CircleIcon";
import "./OnboardingSideMenu.component.scoped.scss";

const OnboardingSideMenu: React.FC<OnboardingSideMenuProps> = ({
  menuTabs,
  selectedTab,
}: any) => {
  const MenuWrapper: BoxProps = {
    bgcolor: "rgb(90, 92, 79)",
    height: "100vh",
  };

  const getTabStatus = (value: number): CircleIconStatus => {
    if (selectedTab > value) {
      return CircleIconStatus.COMPLETED;
    } else if (selectedTab === value) {
      return CircleIconStatus.ACTIVE;
    } else {
      return CircleIconStatus.NONE;
    }
  };

  return (
    <Box {...MenuWrapper} p={5}>
      <div className="d-flex flex-wrap align-items-center ml-2 compliance">
        <img
          src="/icons/onboarding/compliance2b_logo_typo_white.svg"
          alt="compliance-logo"
        />
      </div>
      <Box bgcolor="rgb(90, 92, 79)">
        <Stepper activeStep={selectedTab} orientation="vertical">
          {menuTabs.map((tab: any, index: number) => {
            return (
              <Step key={tab.label}>
                <StepLabel
                  icon={
                    <CircleIcon
                      status={getTabStatus(index)}
                      value={index + 1}
                    />
                  }
                >
                  <div
                    className={
                      getTabStatus(index) === CircleIconStatus.ACTIVE
                        ? "active-label"
                        : "inactive-label"
                    }
                  >
                    {tab.label}
                  </div>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </Box>
  );
};

export default OnboardingSideMenu;
