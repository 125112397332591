import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Dropdown from "react-bootstrap/Dropdown";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import "./dashboard-graph-filter.component.scoped.scss";
import moment from "moment";

export default function DashboardGraphFilter(props: any) {
  const [t] = useTranslation();
  const [duration, setDuration] = useState("2");
  const filters = [
    {
      name: "1",
      value: {
        from: moment().subtract(1, "months").format("YYYY-MM-DD"),
        to: moment().add(1, "days").format("YYYY-MM-DD"),
      },
    },
    {
      name: "2",
      value: {
        from: moment().subtract(2, "months").format("YYYY-MM-DD"),
        to: moment().add(1, "days").format("YYYY-MM-DD"),
      },
    },
    {
      name: "6",
      value: {
        from: moment().subtract(6, "months").format("YYYY-MM-DD"),
        to: moment().add(1, "days").format("YYYY-MM-DD"),
      },
    },
    {
      name: "12",
      value: {
        from: moment().subtract(12, "months").format("YYYY-MM-DD"),
        to: moment().add(1, "days").format("YYYY-MM-DD"),
      },
    },
  ];

  const selectDuration = (selected: any) => {
    setDuration(selected.name);
    props.onDurationChange(selected.value);
  };

  return (
    <div className="dashboard-filter">
      <Dropdown>
        <Dropdown.Toggle
          variant="outline-secondary"
          className="dashboard-filter-btn"
        >
          {duration} {duration === "1" ? t("month") : t("months")}
          <ExpandMoreIcon className="expand-more" />
          <ExpandLessIcon className="expand-less" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {filters.map((item: any, index: number) => {
            return (
              <Dropdown.Item
                key={index}
                onClick={selectDuration.bind(null, item)}
              >
                {item.name} {item.name === "1" ? t("month") : t("months")}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
