import { icons } from "./icons";
export const CountCards = [
  {
    id: "1",
    title: "total notes",
    icon: icons.trackReportOrange,
  },
  {
    id: "2",
    title: "active notices",
    icon: icons.inProgressOrange,
  },
  {
    id: "3",
    title: "potential loss",
    icon: icons.amountOfDamageOrange,
  },
  {
    id: "4",
    title: "comments from reporter",
    icon: icons.inviteOrange,
  },
];
