import axios from "axios";
import { config } from "../../config";

export class NotificationsService {
  public getNotifications = async (
    page: number,
    items: number
  ): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(
        serverUrl + `notifications?items=${items}&page=${page}`
      );
      return response.data;
    } catch (err) {
      return { data: [] };
    }
  };

  public makeNotificationsSeen = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(serverUrl + "notifications/see");
      return response.data;
    } catch (err) {
      return [];
    }
  };

  public makeNotificationOpened = async (id: number): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "notifications/" + id + "/open"
      );
      return response.data;
    } catch (err) {
      return [];
    }
  };
}
