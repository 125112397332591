export class User {
  public image: string = "";
  public email: string = "";
  public email_verified: boolean = false;
  public lastName: string = "";
  public firstName: string = "";
  public group: string = "";
  public name: string = "";
  public preferred_username: string = "";
  public keycloakUserId: string = "";
  public type: "admin" | "user" | "superAdmin" | "superUser" | "auditor" | "" = "";
  public title: string = "";
  public telephone: string = "";
  public companyEmail: string = "";
  public companyName: string = "";
  public companyStreet: string = "";
  public companyRegisterNumber: string = "";
  public companyPostalCode: string = "";
  public companyCity: string = "";
  public isDeleted: number = 0;
  public isInvited: number = 0;
}
