export const statusesValues: any[] = [
{
    name: "Received",
    color: "#8FB2C7",
    translation: "EINGELANGT",
  },
  {
    name: "In Progress",
    color: "#EE6A22",
    translation: "IN BEARBEITUNG",
  },
  {
    name: "Review",
    color: "#F2AF1D",
    translation: "REVIEW",
  },
  {
    name: "Completed",
    color: "#008000",
    translation: "ABGESCHLOSSEN",
  },
];
