import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import LoadIcon from "@material-ui/icons/SystemUpdateAltTwoTone"
import './workflow-management-content.component.scoped.scss';
import { DefaultCategories } from "../../statics/default-categories";
import OnboardingCategory from "../onboarding-category/onboarding-category.component";

import "ace-builds";
import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-json5";
import { CasesService } from "../../services/cases/cases.service";
import { defaultQuestions } from "../../statics/questions";
import moment from "moment";
import { GlobalContext } from "../../store";
import { showToastMsg } from "../../helpers";
import { ConfirmDialog } from "../../dialogs/confirm-dialog/confirm-dialog";

export const WorkflowManagementContent = () => {
  const [t] = useTranslation();
  const casesService = new CasesService();
  const { user } = useContext(GlobalContext);

  const emptyObj: any = {};
  const [activeCategory, setActiveCategory] = useState(emptyObj);
  const [categories, setCategories] = useState([]);
  const [questions, setQuestions] = useState('');
  const [originalQuestions, setOriginalQuestions] = useState('');
  const [questionsChanged, setQuestionsChanged] = useState(false);
  const [loadDefault, setLoadDefault] = useState(false);
  const [invalidQuestions, setInvalidQuestions] = useState(false);
  const [mouseHover, setMouseHover] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);


  const onChange = (newValue: any) => {
    setQuestionsChanged(true);
    setLoadDefault(false)
    setQuestions(newValue);
    try {
      JSON.parse(newValue);
      setInvalidQuestions(false)
    } catch (e) {
      setInvalidQuestions(true)
    }
  }

  useEffect(() => {
    listCategories();
    // eslint-disable-next-line
  }, [])

  const listCategories = async () => {
    const categoriesRes = await casesService.listCategories();
    listQuestions(categoriesRes[0]);
    setCategories(categoriesRes);
  }

  const listQuestions = async (category: any) => {
    setErrorMessage('');
    const questionsRes = await casesService.listQuestions(category.id);
    setActiveCategory(questionsRes.category);
    setQuestions(JSON.stringify(questionsRes.questions, null, '\t'));
    setOriginalQuestions(JSON.stringify(questionsRes.questions, null, '\t'));
  }

  const loadDefaultQuestions = (value: any) => {
    setShowConfirmModal(false);
    if (value) {
      setQuestions(JSON.stringify(defaultQuestions, null, '\t'));
      setLoadDefault(true);
      setQuestionsChanged(true);
    }
  }

  const cancel = () => {
    setQuestions(originalQuestions);
    setQuestionsChanged(false);
    setLoadDefault(false);
  }

  const submit = async () => {
    setQuestionsChanged(false);
    setErrorMessage('');
    const data = {
      questions: JSON.parse(questions),
      loadDefault,
      categoryId: activeCategory.id,
      userId: user.id
    }
    const res = await casesService.importQuestions(data);
    if (res && res.message === "Imported") {
      showToastMsg("success", t("questions updated successfully"))
      listQuestions(activeCategory);
      setLoadDefault(false);
      setOriginalQuestions(questions);
    } else {
      setErrorMessage(res);
    }
  }

  return (
    <>
      <section className="workflow-management">
        <div className="section-info">
          <h3>{t("workflow specifications")}</h3>
          <p>{t("workflow specifications desc")}</p>
        </div>
        <div className="workflow-content">
          <div className="categories">
            {
              DefaultCategories.map((category: any, index: number) => {
                return (
                  categories.map((item: any, i: any) => {
                    return (
                      <>
                        {
                          (item.key === category.key) &&
                          <div onMouseEnter={() => setMouseHover(i)}
                            onMouseLeave={() => setMouseHover(null)} key={i}>
                            <OnboardingCategory
                              name={item.name}
                              src={category.src}
                              activeSrc={category.activeSrc}
                              active={activeCategory.id === item.id || mouseHover === i}
                              borderNum={1}
                              onclick={() => listQuestions(item)}
                            />
                          </div>
                        }
                      </>
                    )
                  })

                );
              })
            }
          </div>
          <div className="questions">
            <div className="editor">
              <AceEditor
                placeholder={t("workflow specifications")}
                mode="json"
                theme="tomorrow"
                name="testchjhjks"
                // onLoad={this.onLoad}
                onChange={onChange}
                fontSize={12}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                style={{ width: '100%', height: '480px' }}
                value={`${questions}`}
                setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: true,
                  enableSnippets: true,
                  showLineNumbers: true,
                  tabSize: 4,
                }} />
              {
                activeCategory.user_updated_name &&
                <p>{t("last changed")}:  {activeCategory.user_updated_name}, {moment(activeCategory.updated_at).format("DD.MM.YYYY/h:mm a")}</p>
              }
              {
                errorMessage &&
                <p className="error-msg">{errorMessage}</p>
              }
            </div>
            <div className="actions">
              <Button
                variant="outlined"
                color="primary"
                className="btn-secondary-theme"
                onClick={() => { setShowConfirmModal(true) }}>
                <LoadIcon className="mr-3" />
                {"Load Default"}
              </Button>
              <div className="right-action">
                <Button
                  variant="outlined"
                  color="primary"
                  className="btn-cancel"
                  onClick={cancel}
                  disabled={!questionsChanged}>
                  {t("cancel")}
                </Button>
                <Button
                  className="btn-primary-theme btn-save"
                  variant="contained"
                  color="primary"
                  onClick={submit}
                  disabled={!questionsChanged || !questions || invalidQuestions}>
                  <CheckIcon />
                  {t("save changes")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showConfirmModal && (
        <ConfirmDialog
          show={showConfirmModal}
          onClose={loadDefaultQuestions}
          dialogTitle={t("load default title")}
          dialogBody={t("load default desc")}
          confirmBtnText={t("finalize")}
          cancelBtn={true}
          showCheck={true}
          dialogClassName='modal-lg'
        />
      )}
    </>
  )
}
