import React, { useContext, useState } from "react";
import { Box, Button, Chip, Grid, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Branch } from "../../types/branch";
import { Land } from "../../types/land";
import AddIcon from "@material-ui/icons/Add";
import { icons } from "../../statics/icons";
import "./company-details.component.scoped.scss";
import { GlobalContext } from "../../store";

const OnboardingCompanyDetails = ({ nextTab, prevTab }: any) => {
  const {
    OnboardingLands,
    OnboardingBranches,
    setOnboardingBranches,
    setOnboardingLands,
    setWorkspaceForm,
    workspaceForm,
  } = useContext(GlobalContext);
  const [t] = useTranslation();
  const [land, setLand] = useState("");
  const [branch, setBranch] = useState("");
  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleChange = (event: any) => {
    const value = event.target.value;
    const name = event.target.name;
    const obj: any = {};
    obj[name] = value;
    setWorkspaceForm({ ...workspaceForm, ...obj });
  };

  const handleAddLandClick = () => {
    if (land.length > 0) {
      const newLand = { name: land };
      const landsCopy: Land[] = [...OnboardingLands];
      landsCopy.push(newLand);
      setOnboardingLands(landsCopy);
      setLand("");
    }
  };

  const handleLandDelete = (name: string) => {
    const newLands = OnboardingLands.filter((item: any) => item.name !== name);
    setOnboardingLands(newLands);
  };

  const handleAddBranchClick = () => {
    if (branch.length > 0) {
      const newBranch = {
        name: branch,
      };
      const branchesCopy: Branch[] = [...OnboardingBranches];
      branchesCopy.push(newBranch);
      setOnboardingBranches(branchesCopy);
      setBranch("");
    }
  };

  const handleBranchDelete = (name: string) => {
    const newBranches = OnboardingBranches.filter(
      (item: any) => item.name !== name
    );
    setOnboardingBranches(newBranches);
  };

  return (
    <div>
      <h1 className="main-header px-3">About your Company</h1>
      <div className="sec-header px-3">
        Let’s get started by creating your company at Compliance2b.
      </div>
      <div className="section-name px-3">Company name</div>
      <div className="d-flex flex-row justify-content-start align-items-center w-100 p-3 mb-4">
        <TextField
          style={{ width: "320px", marginRight: "70px" }}
          label={t("Company name")}
          variant="filled"
          name="clientName"
          value={workspaceForm.clientName}
          // error={!profileForm.firstName ? true : false}
          // onChange={(e: any) =>
          //   setWorkspaceName(e.target.value.replace(/ /g, "_"))
          // }
          onChange={handleChange}
        />
        <div className="notes-col">
          <div className="d-flex">
            <div className="info-icon">
              <img src={icons.info} alt="" />
            </div>
            <div className="info-note">
              {t(
                "This company name is used in all lists and company related titles."
              )}
            </div>
          </div>
        </div>
      </div>
      {/* ///////// land //////////// */}

      <div className="section-name px-3">Lands</div>

      <div className="d-flex flex-wrap justify-content-start align-items-center w-100 p-3 mb-4">
        <div className="">
          <TextField
            style={{ width: "320px", marginRight: "90px" }}
            label={t("Land")}
            variant="filled"
            name="workspace name"
            value={land}
            // error={!pofileForm.firstName ? true : false}
            onChange={(e: any) => setLand(capitalize(e.target.value))}
          />
        </div>
        <div onClick={handleAddLandClick} className="add-btn">
          <AddIcon style={{ marginRight: "7px" }} />
          Add land
        </div>
        <div className="w-100 d-flex flex-wrap">
          {OnboardingLands.map((item: any, index: number) => {
            return (
              <div key={index} className="mt-3 mx-2">
                <Chip
                  label={item.name}
                  onDelete={handleLandDelete.bind(null, item.name)}
                  variant="outlined"
                />
              </div>
            );
          })}
        </div>
      </div>
      {/* ////////////branches////////// */}

      <div className="section-name px-3">Branches</div>

      <div className="d-flex flex-wrap justify-content-start align-items-center p-3 mb-4">
        <div className="">
          <TextField
            style={{ width: "320px", marginRight: "90px" }}
            label={t("Branch")}
            variant="filled"
            name="branch"
            value={branch}
            onChange={(e: any) => setBranch(capitalize(e.target.value))}
          />
        </div>
        <div onClick={handleAddBranchClick} className="add-btn">
          <AddIcon style={{ marginRight: "7px" }} />
          Add branch
        </div>
        <div className="w-100 d-flex flex-wrap">
          {OnboardingBranches.map((item: any, index: number) => {
            return (
              <div key={index} className="mt-3 mx-2">
                <Chip
                  label={item.name}
                  onDelete={handleBranchDelete.bind(null, item.name)}
                  variant="outlined"
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="btns-wrapper mb-5">
        <Grid container item xs={12} spacing={2}>
          <Grid container item justify="space-between">
            <Box>
              <Button
                variant="text"
                color="primary"
                fullWidth
                onClick={() => {
                  //    const { loading, ...rest } = formik.values;
                  //    dispatch(setStepTwo(rest));
                  prevTab?.();
                }}
              >
                previous
              </Button>
            </Box>
            <Box width="240px">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  //    const { loading, ...rest } = formik.values;
                  //    dispatch(setStepTwo(rest));
                  nextTab();
                }}
                disabled={
                  OnboardingLands.length === 0 ||
                  OnboardingBranches.length === 0 ||
                  workspaceForm.clientName.length === 0
                }
              >
                next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default OnboardingCompanyDetails;
