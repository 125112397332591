import { GuardsService } from "./../services/guards/guards.service";
import DashboardLayout from "../layouts/dashboard/dashboard.layout";
import SettingsLayout from "../layouts/settings/settings.layout";
// import HomePage from "../pages/home/home.page";
import LoginPage from "../pages/login/login.page";
import UsersPage from "../pages/users/users.page";
import CasesPage from "../pages/cases/cases.page";
import DashboardPage from "../pages/dashboard/dashboard.page";
import CasePage from "../pages/case/case.page";
import ProfilePage from "../pages/profile/profile.page";
import RolesManagementPage from "../pages/roles-managment/roles-management.page";
import TodoPage from "../pages/todo/todo.page";
import SignUpPage from "../pages/sign-up/sign-up.page";
import FallbackPage from "../pages/fallback-page/fallback-page";
import WorkspacesPage from "../pages/workspaces/workspaces.page";
import ForgotPasswordPage from "../pages/forgot-password/forgot-password.page";
import ResetPasswordPage from "../pages/reset-password/reset-password.page";
import CreateWorkspacePage from "../pages/create-workspace/create-workspace.page";
import Onboarding from "../pages/on-boarding/on-boarding.page";
import SettingsPage from "../pages/settings/settings.page";
import Workspace from "../pages/workspace/workspace.page";
import WorkflowManagementPage from "../pages/workflow-management/workflow-management.page";
import TranslationManagementPage from "../pages/translation-management/translation-management.page";
import BranchManagementPage from "../pages/branch-management/branch-management.page";
import ReporterConfigurationPage from "../pages/reporter-configuration/reporter-configuration.page";
import AIManagementPage from "../pages/ai-management/ai-management.page";
import LogfilePage from "../pages/logfile/logfile.page";
const guardsService: GuardsService = new GuardsService();

const routes = [
  {
    path: "/login",
    title: "Login",
    component: LoginPage,
    canActivate: [guardsService.isNotAuthorized],
  },
  {
    path: "/signup/:id/:tenantId",
    title: "signup",
    component: SignUpPage,
  },
  {
    path: "/forgot-password",
    title: "forgot password",
    component: ForgotPasswordPage,
    canActivate: [guardsService.isNotAuthorized],
  },
  {
    path: "/reset-password/:id",
    title: "signup",
    component: ResetPasswordPage,
    canActivate: [guardsService.isNotAuthorized],
  },
  {
    path: "/workspaces",
    title: "workspaces",
    component: WorkspacesPage,
    canActivate: [guardsService.isAuthorized],
  },
  {
    path: "/On-Boarding",
    title: "Onboarding",
    component: Onboarding,
    canActivate: [guardsService.isSuperAdmin],
  },
  {
    path: "/",
    canActivate: [guardsService.isAuthorized],
    component: DashboardLayout,
    routes: [
      {
        path: "/create-workspace",
        title: "create workspace",
        component: CreateWorkspacePage,
        canActivate: [guardsService.isSystemAdmin],
      },
      {
        path: "/dashboard",
        title: "dashboard",
        component: DashboardPage,
        canActivate: [guardsService.isCaseAdmin],
      },
      {
        path: "/cases",
        title: "cases",
        component: CasesPage,
        canActivate: [guardsService.isCaseAdmin],
      },

      {
        path: "/case/:id",
        title: "case",
        component: CasePage,
        canActivate: [guardsService.isCaseAdmin],
      },
      {
        path: "/users",
        title: "users",
        component: UsersPage,
        canActivate: [guardsService.isSystemAdmin],
      },
      {
        path: "/todo",
        title: "todo",
        component: TodoPage,
        canActivate: [guardsService.isCaseAdmin],
      },
      {
        path: "/settings",
        component: SettingsLayout,
        routes: [
          {
            path: "/settings/general",
            title: "Settings",
            component: SettingsPage
          },
          {
            path: "/settings/workspace-management",
            title: "Workspace Management",
            component: Workspace,
            canActivate: [guardsService.isSuperUser]
          },
          {
            path: "/settings/user-management",
            title: "User Management",
            component: ProfilePage
          },
          {
            path: "/settings/roles-management",
            title: "Roles Management",
            component: RolesManagementPage,
            canActivate: [guardsService.isSystemAdmin],
          },
          {
            path: "/settings/workflow-management",
            title: "Workflow Management",
            component: WorkflowManagementPage,
            canActivate: [guardsService.isSystemAdmin],
          },
          {
            path: '/settings/translation-management',
            title: "Translation Management",
            component: TranslationManagementPage,
            canActivate: [guardsService.isSystemAdmin]
          },
          {
            path: '/settings/branch-management',
            title: "Branch Management",
            component: BranchManagementPage,
            canActivate: [guardsService.isSystemAdmin]
          },
          {
            path: '/settings/reporter-configuration',
            title: "Reporter Configuration",
            component: ReporterConfigurationPage,
            canActivate: [guardsService.isSystemAdmin]
          },
          {
            path: '/settings/AI-management',
            title: "AI Data Management",
            component: AIManagementPage,
            canActivate: [guardsService.isSystemAdmin]
          },
          {
            path: '/settings/logfile',
            title: "Logfile",
            component: LogfilePage,
            canActivate: [guardsService.isSystemAdmin]
          },
          {
            path: "/settings",
            redirect: "/settings/user-management",
          },
          {
            path: "*",
            component: FallbackPage,
          },
        ]
      },
      {
        path: "/",
        redirect: "/dashboard",
      },
      {
        path: "/temp",
      },
      {
        path: "/",
        redirect: "/dashboard",
      },
      {
        path: "/home",
        redirect: "/dashboard",
      },
      {
        path: "*",
        // redirect: "/dashboard",
        component: FallbackPage,
      },
    ],
  },
  {
    path: "*",
    redirect: "/dashboard",
  },
];

export default routes;
