import React, { useContext, useEffect, useState } from "react"
import "./categories-content.component.scoped.scss"
import { useTranslation } from "react-i18next"
import { icons } from "../../statics/icons"
import { Button } from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import { showToastMsg } from "../../helpers"
import { SettingsService } from "../../services/settings/settings.service"
import { UserSearchComponent } from "../user-search/user-search.component"
import { Category } from "../../types/category"
import { GlobalContext } from "../../store"

export default function CategoriesContent(props: any) {
  const [t] = useTranslation()
  const settingsService: SettingsService = new SettingsService()
  const emptyArr: any[] = []
  const [saving, setSaving] = useState(false)
  const [categoriesChange, setCategoriesChange] = useState(false)
  const [categories, setCategories] = useState(emptyArr)
  const [categoriesOriginal, setCategoriesOriginal] = useState(emptyArr)
  const [categoriesError, setCategoriesError] = useState(emptyArr)
  const { helpData, setHelpContent } = useContext(GlobalContext)

  const mapCategories = (categories: Category[] = []) => {
    return categories.map((category: Category) => {
      return {
        id: category.id,
        userId: category.mainProcessorId ? category.mainProcessorId : null,
      }
    })
  }

  const submit = () => {
    if (categoriesError.includes(true) || !categoriesChange) {
      return
    }
    updateCategories()
  }

  async function updateCategories() {
    setSaving(true)
    const res = await settingsService.assignCategories(categories)
    setSaving(false)
    if (res && res.message === "Updated") {
      getCategories();
      showToastMsg("success", t("categories updated successfully"))
      setCategoriesChange(false)
    }
  }

  async function getCategories() {
    const res = await settingsService.getMainProcessors()
    setCategoriesOriginal(res)
    const mappedCategories = mapCategories(res)
    setCategories(mappedCategories)
    setCategoriesError(Array(res.length).fill(false))
  }

  useEffect(() => {
    getCategories()
    setHelpContent(helpData.AssignmentOfCategories)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [helpData])

  const handleUserChange = (cat: Category, index: number) => {
    const selected = [...categories];
    const { id, mainProcessorId } = cat
    selected[index] = {
      id,
      userId: mainProcessorId,
      changed: true
    }
    setCategories(selected)
    setCategoriesChange(true)
  }

  const handleError = (err: boolean, index: number) => {
    const errors = [...categoriesError]
    errors[index] = err
    setCategoriesError(errors)
  }

  return (
    <div className="categories-content">
      <div className="section-info">
        <h3>{t("category assignment internal")}</h3>
        <p>{t('change your personal master data here')}</p>
      </div>
      <section className="categories-section mb-3">
        <div className="row">
          <div className="col-12">
            <div className="categories-card">
              <div className="card-content">
                <div className="mb-4">
                  <div className="row no-gutters flex-wrap justify-content-between">
                    {categoriesOriginal.map(
                      (category: Category, index: number) => {
                        return (
                          <div className="w-47 mb-2" key={index}>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="category-col">
                                <div className="category-name trim-2-lines">
                                  {category.name}
                                </div>
                              </div>
                              <div className="user-col">
                                <UserSearchComponent
                                  key={index}
                                  category={category}
                                  onUserChange={(value: any) =>
                                    handleUserChange(value, index)
                                  }
                                  onError={(err: boolean) =>
                                    handleError(err, index)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <div className="info-icon">
            <img src={icons.info} alt="" />
          </div>
          <div className="attention-text">
            <b>{t("attention")}</b> {t("categories attention message")}
          </div>
        </div>

        <div className="footer mt-3">
          <Button
            className="btn-primary-theme btn-save"
            variant="contained"
            color="primary"
            onClick={submit}
            disableRipple={!categoriesChange}
            disabled={!categoriesChange || saving || categoriesError.includes(true)}
          >
            <CheckIcon /> {t("save changes")}
          </Button>
        </div>
      </div>
    </div>
  )
}
