import React from "react";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import "./default-input-view.component.scoped.scss";

export default function DefaultInputView(props: any) {
  const { label, value } = props;

  return (
    <div className="default-input-view">
      {value && <div className="default-input-label">{label}</div>}
      {value !== "password-hint" && (
        <div className="default-input-value">{value ? value : label}</div>
      )}
      {value === "password-hint" && (
        <div className="default-input-value dimmed">
          {Array(12).fill(0).map((item: any, index: number) => (
            <FiberManualRecordIcon key={index}/>
          ))}
        </div>
      )}
    </div>
  );
}
