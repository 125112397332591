import axios from "axios";
import { config } from "../../config";

export class AIService {
    public activateAI = async (ai_status: boolean, caseId: number, description?: string): Promise<any> => {
        try {
            const { serverUrl } = config;
            const status = ai_status ? 1 : 0;
            const response = await axios.put(serverUrl + `AI`, { description },
                {
                    params: {
                        status,
                        caseId
                    }
                }
            );
            return response.data;
        } catch (error: any) {
            return error;
        }
    };

    public activateWorkflowAI = async (caseId: number): Promise<any> => {
        try {
            const { serverUrl } = config;
            const response = await axios.put(serverUrl + `AI/activate-workflow-ai?caseId=${caseId}`);
            return response;
        } catch (error) {
            return {};
        }
    };
}