import React, { useEffect, useRef, useState } from 'react';
import './reporter-design.component.scoped.scss';

import { useTranslation } from "react-i18next";
import { Button, TextField } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { SettingsService } from "../../../../services/settings/settings.service";
import "ace-builds";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-json5";

import { showToastMsg, turncate } from "../../../../helpers";
import { FileSizes } from "../../../../statics/file-sizes";
import { fileTypes } from "../../../../statics/file-types";
import { alertToastConfig } from "../../../../statics/alert-config";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { colorHexRegex } from "../../../../statics/validators";
import { ReporterDesignValues } from '../../../../types/reporter-configuration';

export default function ReporterDesign(props: any) {
    const [t] = useTranslation();
    const settingsService: SettingsService = new SettingsService();
    const inputFileRef = useRef(null);
    const emptObj: any = {};

    const [reporterDesign, setReporterDesign] = useState(new ReporterDesignValues());
    const [originalReporterDesign, setOriginalReporterDesign] = useState(new ReporterDesignValues());
    const [designChanged, setDesignChanged] = useState(false);
    const [colorError, setColorError] = useState(false);
    const [reporterCover, setReporterCover] = useState(emptObj);


    useEffect(() => {
        setReporterDesign(props.data);
        setOriginalReporterDesign(props.data);
    }, [props])
    /**
     * functions related to  reporter desgin configuration
     */
    const clickUpload = () => {
        const element: any = inputFileRef.current;
        element.click();
    };

    const onFileChange = async (e: any) => {
        await validateFiles(e.target.files);
        const element: any = inputFileRef.current;
        element.value = '';
    };

    const validateFiles = async (files: any[]) => {
        const errorsMsg: any[] = [];
        for (const file of files) {
            const { fileType, maxSize } = getFileInfo(file.type);
            const fileName = file.name.split('.' + fileType)[0];
            if (file.size > maxSize) {
                errorsMsg.push(
                    `<div class='alert-file-name'>${turncate(fileName, 20)}</div> 
                    is bigger than max ${fileType.toUpperCase()} file size (${maxSize / 1024 / 1000} MB)`
                );
            } else {
                if (fileType.includes('jpg') || fileType.includes('png')) {
                    setDesignChanged(true);
                    file.src = await getImagePreview(file);
                    setReporterCover(file);
                } else {
                    errorsMsg.push('Only jpg/jpeg/png file types are supported');
                }
            }
        }
        showAlertMsg(errorsMsg);
    };

    async function getImagePreview(file: File) {
        return new Promise<any>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                const src = reader.result;
                resolve(src);
            };
            reader.readAsDataURL(file);
        });
    }

    const getFileInfo = (type: string): any => {
        switch (type) {
            case 'image/jpeg':
                return { fileType: 'jpg', maxSize: FileSizes.jpg.maxSize };
            case 'image/png':
                return { fileType: 'png', maxSize: FileSizes.png.maxSize };
            case 'video/mp4':
                return { fileType: 'mp4', maxSize: FileSizes.png.maxSize };
            case 'application/pdf':
                return { fileType: 'pdf', maxSize: FileSizes.pdf.maxSize };
            default:
                break;
        }
    };

    async function showAlertMsg(queues: any[]) {
        const MySwal = withReactContent(Swal);
        for (const message of queues) {
            await MySwal.fire({
                icon: 'error',
                title: message,
                ...alertToastConfig,
            });
        }
    }

    const updateDesignConfiguration = async () => {
        setDesignChanged(false);
        let configurationData: any = {};
        configurationData.main_color = reporterDesign.main_color;

        if (reporterCover.src) {
            const reporterCoverForm = new FormData();
            reporterCoverForm.append('image', reporterCover, reporterCover.name);
            const reporterCoverRes = await settingsService.uploadReporterCover(reporterCoverForm);
            if (reporterCoverRes.url && reporterCoverRes.path) {
                configurationData.reporter_cover_url = reporterCoverRes.path;
                setReporterDesign({ ...reporterDesign, reporter_cover_url: reporterCoverRes.url })
                setReporterCover({});
            } else {
                showToastMsg('error', 'Something went wrong');
                return;
            }
        } else {
            configurationData.reporter_cover_url = reporterDesign.reporter_cover_url;
        }

        const res = await settingsService.updateReporterConfiguration(configurationData);
        if (res && res.message === "Success") {
            setOriginalReporterDesign(configurationData);
            showToastMsg("success", t("configuration updated successfully"));
        } else {
            showToastMsg("error", t("Something went wrong!!"));
        }
    }

    const resetDesign = () => {
        setDesignChanged(false);
        setReporterDesign(originalReporterDesign);
        setColorError(false);
        setReporterCover({});
    }

    return (
        <>
            <div className="design-customization">
                <div className="section-info">
                    <h3>{t("reporter page design")}</h3>
                    <p>{t("you can change the title image and the main color of the reporter page here")}</p>
                </div>
                <div className="reporter-design">
                    <div className="info">
                        <div className='logo'>
                            <label>{t('cover')}</label>
                            <div className="logo-update">
                                <img
                                    src={reporterCover.src ? reporterCover.src : reporterDesign.reporter_cover_url ? reporterDesign.reporter_cover_url : '/icons/onboarding/cameraIcon.svg'}
                                    alt='camera'
                                    className={reporterDesign.reporter_cover_url ? 'image-full' : 'camera-icon'}
                                    onClick={clickUpload} />
                                <input
                                    type='file'
                                    ref={inputFileRef}
                                    onChange={onFileChange}
                                    style={{ display: 'none' }}
                                    multiple
                                    accept={fileTypes}
                                />

                            </div>
                        </div>
                        <div className="name">
                            <label>{t('primary color')}</label>
                            <TextField
                                variant='filled'
                                name='mainColor'
                                placeholder="#000000"
                                value={reporterDesign.main_color}
                                error={colorError ? true : false}
                                onChange={(e) => {
                                    setDesignChanged(true);
                                    e.target.value ? setColorError(!colorHexRegex.test(e.target.value)) : setColorError(true);
                                    setReporterDesign({ ...reporterDesign, main_color: e.target.value });
                                }}
                                style={{ width: '100%' }}
                            />
                        </div>
                    </div>
                    <div style={{ textAlign: 'end' }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            className="btn-cancel"
                            onClick={resetDesign}
                            disabled={!designChanged}>
                            {t("cancel")}
                        </Button>
                        <Button
                            className="btn-primary-theme btn-save"
                            variant="contained"
                            color="primary"
                            onClick={() => updateDesignConfiguration()}
                            disabled={!designChanged || colorError}>
                            <CheckIcon />
                            {t("save changes")}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}