import React, { useContext, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { CaseContext, GlobalContext } from "../../store";
import { useTranslation } from "react-i18next";
import { LocalStorageService } from "../../services/local-storage/local-storage";
import { history } from "react-router-guard";

function a11yProps(index: any) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tab-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    left: "-10px",
  },
}));

export default function CaseTabsComponent() {
  const classes = useStyles();
  const [t] = useTranslation();
  const { activeTab, setActiveTab, caseDetails } = useContext(CaseContext);
  const { helpData, setHelpContent } = useContext(GlobalContext);
  const localStorageService = new LocalStorageService();
  const user = localStorageService.getData("user");

  const setHelpContentForTabs = (value: number) => {
    value === 0 && setHelpContent(helpData.Description);
    value === 1 && setHelpContent(helpData.Communication);
    value === 2 && setHelpContent(helpData.Files);
    value === 3 && setHelpContent(helpData.Workflow);
    value === 4 && setHelpContent(helpData.Notes);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setHelpContentForTabs(newValue);
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (history.location.state) {
      setActiveTab(history.location.state.tab);
    }
    history.replace();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setHelpContentForTabs(activeTab);
    // eslint-disable-next-line
  }, [helpData]);

  return (
    <div className={classes.root}>
      <Tabs value={activeTab} onChange={handleChange} aria-label="case tabs">
        <Tab label={t("description")} {...a11yProps(0)} className="tab-btn" />
        <Tab
          label={t("reporter")}
          {...a11yProps(1)}
          className="tab-btn"
          style={{
            position: "absolute",
            right: "-10px",
            top: "0",
            display: user.group === "external" ? "none" : ""
          }}
        />
        <Tab
          label={t("files")}
          {...a11yProps(2)}
          className="tab-btn"
          style={{
            display:
              user.group === "internal" ||
                user.keycloakUserId === caseDetails.mainProcessorId ||
                user.type === "superAdmin" || user.role === 'coordinator'
                ? ""
                : "none",
          }}
        />
        <Tab label={t("workflow")} {...a11yProps(3)} className="tab-btn" />
        <Tab label={t("communication")} {...a11yProps(4)} className="tab-btn" />
      </Tabs>
    </div>
  );
}
