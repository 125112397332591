import React from "react";
import { useTranslation } from "react-i18next";
import SettingsHead from "../../components/settings-head/settings-head.component";
import WorkspaceManagement from "../../components/workspace-management/workspace-management.component";
import { CommonService } from "../../services/common/common.service";

export default function Workspace() {
    const [t] = useTranslation();
    const commonService: CommonService = new CommonService();
    commonService.setTitle(t("workspace management"));

    return (
        <>
            <div className="role-contain">
                <div className="content">
                    <SettingsHead title={t('workspace management')} subTitle={t('organize the information of your workspace here')} />
                    <WorkspaceManagement />
                </div>
            </div>
        </>
    )
}