import React from "react";
import "./workflow-tab.component.scoped.scss";
import { WorkflowTimelineComponent } from "../workflow-timeline/workflow-timeline.component";

export const WorkflowTabComponent = (props: any) => {
  return (
    <div className="theme-container workflow-tab">
      <WorkflowTimelineComponent />
    </div>
  );
};
