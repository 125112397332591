import React from "react";
import { useTranslation } from "react-i18next";
import SettingsHead from "../../components/settings-head/settings-head.component";
import { TranslationManagementContent } from "../../components/translation-management-content/translation-management-content.component";
import { CommonService } from "../../services/common/common.service";

export default function TranslationManagementPage() {
    const [t] = useTranslation();
    const commonService: CommonService = new CommonService();
    commonService.setTitle(t("database value management"));

    return (
        <>
            <div className="role-contain">
                <div className="content">
                    <SettingsHead title={t('database value management')} subTitle={t('translate database entries')} />
                    <TranslationManagementContent />
                </div>
            </div>
        </>
    );
}